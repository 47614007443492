import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { fetchAffiliateUserStatsRequest } from "../../../store/reducers/affiliateSlice";
import Chart from "./chart";
import OverviewTab from "./overviewTabs";

function AffiliateOverview({ loading, setLoading, userId }) {
  const [commingSoon, setComingSoon] = useState(0);
  const navigate = useNavigate();
  const handleNavigateToCreateMarket = () => {
    navigate("/affiliate-list/create-coupon");
  };
  const metrics = useSelector((state) => state.dashboard.metrics);
  const totalPages = 5;
  //   const dispatch = useDispatch();
  //   const affiliateList = useSelector(
  //     (state) => state.affiliateSlice.userStats
  //   );
  //

  const idToken = useAuth();
  const params = useParams();
  //
  // const userId = params.id
  //
  //
  const dispatch = useDispatch();
  const affiliateList = useSelector(
    (state) => state.affiliateSlice.AffiliateUserList
  );

  useEffect(() => {
    if (userId) {
      dispatch(fetchAffiliateUserStatsRequest({ idToken, userId }));
    }
  }, [userId]);

  // useEffect(() => {
  //   dispatch(fetchAffiliateUserStatsRequest({idToken,userId}));
  // }, [dispatch]);
  return (
    <>
      <OverviewTab userId={userId} />
      <Grid
        container
        justifyContent={"space-between"}
        marginBottom={"20px"}
        style={{ position: "relative", flexDirection: "column" }}
      >
        <div className="affiliate-smooth-chart" item lg={6.5}>
          <Chart />
        </div>
      </Grid>
      {/* <div className="leaderboard_table_aff"> 
        <div className=" table_wrapper"> 
       <div className="table_header">
            <div className="tab-panel-content">
              <div className="tab-panel-content">
                <div className="search-calender">
                  <input
                    class="nosubmit"
                    type="search"
                    placeholder="Search..."
                    style={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#111142",
                      borderRadius: "63px",
                      position: "relative",
                      top: "0px",
                    }}
                  />

                  <div className="calender">
                    <img
                      className="calender-img"
                      src={vector8}
                      alt="calender img"
                    />
                  </div>
                </div>
                {/* <div className="affiliate-header" style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={addCircle}
                    style={{ height: "20px" }}
                    onClick={handleNavigateToCreateMarket}
                    alt="add circle"
                  />{" "}
                  <div className="createAffiliateCode1">
                    Create Affiliate Code
                  </div>
                </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div>  */}
      {/* <AffiliateList affiliateList={affiliateList} /> */}
      {/* <div className="pagination">
            <div className="pagination-direction">
              <img src={caretNext} alt="Caret Next" />
              <div>Prev</div>
            </div>
            {[...Array(totalPages)].map((_, index) => (
              <div key={index}>{index + 1}</div>
            ))}
            <div className="pagination-direction">
              <div>Next</div>
              <img src={caretPrev} alt="Caret Next" />
            </div>
          </div> */}
      {/* </div> */}
      {/* </div>  */}
    </>
  );
}

export default AffiliateOverview;
