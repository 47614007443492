import React, { useEffect } from "react";
import { Box } from "@mui/material";
import SupportTable from "./supportTable";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import SideMenuItems from "../../constants/SideMenuitem";

const Support = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  // useEffect(() => {
  //   window.location.href = "https://discord.com/invite/3bugxZwETn";
  // }, []);

  const handleItemClick = () => {
    window.location.href = SideMenuItems[7].link;
  };
  return (
    <Box>
      <div onClick={handleItemClick}>
        <SupportTable />
      </div>
    </Box>
  );
};

export default Support;
