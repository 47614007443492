import { all, fork } from 'redux-saga/effects';
// import { watchAddCompetition, watchFetchCompetitions, watchPrizeAward } from './competitionSaga';
// import traderMetricsSaga from './traderChartSaga';
import  {affiliateSaga, affiliateUserStatsSaga, fetchAffiliateCodeList } from './AffiliateSaga';

export default function* rootSaga() {
  yield all([
    // fork(watchFetchCompetitions),
    // fork(watchAddCompetition),
    // fork(traderMetricsSaga),
    // fork(watchPrizeAward),
    fork(affiliateSaga),
    fork(affiliateUserStatsSaga),
    fork(fetchAffiliateCodeList),
    // fork(userSaga)
  ]);
}
