const moment = require('moment');

function formatISOtoDDMMYY(isoDateString) {
  // Parse the ISO date string
  const parsedDate = moment(isoDateString);

  // Format the parsed date as "DDMMYY"
  const formattedDate = parsedDate.format('DD-MM-YY');

  return formattedDate;
}

export default formatISOtoDDMMYY;