import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";

import languages from "../../constants/language";
import timezones from "../../constants/timezone";
import {
  getAccountSettings,
  saveAccountSettings,
} from "../../store/reducers/settings";
import { useTranslation } from "react-i18next";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    top: "auto",
    left: "22%",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      right: "30%",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#9292AB",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 11,
    height: 11,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#636363",
    boxSizing: "border-box",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AccountSettings = () => {
  const [language, setLanguage] = useState("");
  const [timezone, setTimeZone] = useState("");
  const { t } = useTranslation();

  const account_settings = useSelector(
    (state) => state.settings.account_settings
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setLanguage(account_settings.language);
    setTimeZone(account_settings.time_zone);
  }, [account_settings]);

  // useEffect(() => {
  //   dispatch(getAccountSettings());
  // }, [dispatch]);

  // const handlePasswordClick = () => {
  //     setShowUsername(!showUsername);
  // };

  const handleLangChange = (e) => {
    const language = e.target.value;
    // i18n.changeLanguage(e.target.value);
    dispatch(
      saveAccountSettings({
        language,
        time_zone: timezone,
      })
    );
  };

  const handleTZChange = (e) => {
    setTimeZone(e.target.value);
    const time_zone = e.target.value;
    dispatch(
      saveAccountSettings({
        language: account_settings.language,
        time_zone,
      })
    );
  };

  return (
    <Grid container id="account-settings" spacing={2} sx={{ width: "100%" }}>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item lg={8} sx={{ display: "flex", alignItems: "center" }}>
          <Typography component={"h3"} className="profile-heading">
            {t("account-settings")}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          textAlign={"end"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={1}
        >
          <Typography className="public-name">
            {t("publically-acc-data")}
          </Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        {/* <Grid item xs={12} lg={4} className="username-input">
                <label>Username</label>
                <TextField
                    fullWidth
                    name="username"
                    className="form-input-hide"
                    type={"text"}
                    placeholder="Username"
                    disabled
                    // value={newPassword}
                    // onChange={handleNewPassword}
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        {showUsername === false ? (
                            <HidePassword
                            onClick={handlePasswordClick}
                            edge="end"
                            />
                        ) : (
                            <ShowPassword
                            onClick={handlePasswordClick}
                            edge="end"
                            />
                        )}
                        </InputAdornment>
                    ),
                    }}
                />
            </Grid> */}
        <Grid item xs={12} lg={4} className="acct-select">
          <label>{t("lng")}</label>
          <Select
            displayEmpty
            value={language}
            onChange={handleLangChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "langauage" }}
          >
            {languages.map((language) => (
              <MenuItem key={language.code} value={language.code}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} lg={4} className="acct-select">
          <label>{t("time-zone")}</label>
          <Select
            displayEmpty
            value={timezone}
            onChange={handleTZChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "timezone" }}
          >
            {timezones.map((timezone, index) => (
              <MenuItem key={index} value={timezone.offset}>
                {timezone.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountSettings;
