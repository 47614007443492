import React, { useState } from "react";
import TextInput from "../../../components/Form/TextInput";
import "./style.scss";

import DefaultPage from "../../../components/Reusable/DefaultPage";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../store/reducers/auth";
import { useTranslation } from "react-i18next";
import LoginBackIMG from "../../../assets/images/mac/LoginBack.png";
import LoginLIMG from "../../../assets/images/mac/fp.png";
import LogoIMG from "../../../assets/images/mac/Logo.png";
import bgIMG from "../../../assets/images/mac/bg.png";
import ReactGA from "react-ga";
import {
  TextField,
  InputAdornment,
  createSvgIcon,
  Button,
} from "@mui/material";

import { useEffect } from "react";
const ShowPassword = createSvgIcon(
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 7C6.25 4.92893 7.92893 3.25 10 3.25C12.0711 3.25 13.75 4.92893 13.75 7C13.75 9.07107 12.0711 10.75 10 10.75C7.92893 10.75 6.25 9.07107 6.25 7ZM10 4.75C8.75736 4.75 7.75 5.75736 7.75 7C7.75 8.24264 8.75736 9.25 10 9.25C11.2426 9.25 12.25 8.24264 12.25 7C12.25 5.75736 11.2426 4.75 10 4.75Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.32343 5.64635C1.90431 6.25029 1.75 6.7227 1.75 7C1.75 7.2773 1.90431 7.74971 2.32343 8.35365C2.72857 8.93744 3.33078 9.57028 4.09267 10.155C5.61978 11.3271 7.71345 12.25 10 12.25C12.2865 12.25 14.3802 11.3271 15.9073 10.155C16.6692 9.57028 17.2714 8.93744 17.6766 8.35365C18.0957 7.74971 18.25 7.2773 18.25 7C18.25 6.7227 18.0957 6.25029 17.6766 5.64635C17.2714 5.06256 16.6692 4.42972 15.9073 3.84497C14.3802 2.67292 12.2865 1.75 10 1.75C7.71345 1.75 5.61978 2.67292 4.09267 3.84497C3.33078 4.42972 2.72857 5.06256 2.32343 5.64635ZM3.17941 2.65503C4.90965 1.32708 7.31598 0.25 10 0.25C12.684 0.25 15.0903 1.32708 16.8206 2.65503C17.6874 3.32028 18.4032 4.06244 18.9089 4.79115C19.4006 5.49971 19.75 6.2773 19.75 7C19.75 7.7227 19.4006 8.50029 18.9089 9.20885C18.4032 9.93756 17.6874 10.6797 16.8206 11.345C15.0903 12.6729 12.684 13.75 10 13.75C7.31598 13.75 4.90965 12.6729 3.17941 11.345C2.31262 10.6797 1.59681 9.93756 1.0911 9.20885C0.599371 8.50029 0.25 7.7227 0.25 7C0.25 6.2773 0.599371 5.49971 1.0911 4.79115C1.59681 4.06244 2.31262 3.32028 3.17941 2.65503Z"
      fill="white"
    />
  </svg>,
  "Home"
);

const HidePassword = createSvgIcon(
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5303 1.53033C18.8232 1.23744 18.8232 0.762563 18.5303 0.46967C18.2374 0.176777 17.7626 0.176777 17.4697 0.46967L1.46967 16.4697C1.17678 16.7626 1.17678 17.2374 1.46967 17.5303C1.76256 17.8232 2.23744 17.8232 2.53033 17.5303L5.37723 14.6834C6.74353 15.3266 8.31719 15.75 10 15.75C12.684 15.75 15.0903 14.6729 16.8206 13.345C17.6874 12.6797 18.4032 11.9376 18.9089 11.2089C19.4006 10.5003 19.75 9.7227 19.75 9C19.75 8.2773 19.4006 7.49971 18.9089 6.79115C18.4032 6.06244 17.6874 5.32028 16.8206 4.65503C16.5585 4.45385 16.2808 4.25842 15.989 4.07163L18.5303 1.53033ZM14.8995 5.16113L13.1287 6.93196C13.5213 7.52478 13.75 8.23569 13.75 9C13.75 11.0711 12.0711 12.75 10 12.75C9.23569 12.75 8.52478 12.5213 7.93196 12.1287L6.51524 13.5454C7.58077 13.9795 8.76212 14.25 10 14.25C12.2865 14.25 14.3802 13.3271 15.9073 12.155C16.6692 11.5703 17.2714 10.9374 17.6766 10.3536C18.0957 9.74971 18.25 9.2773 18.25 9C18.25 8.7227 18.0957 8.25029 17.6766 7.64635C17.2714 7.06256 16.6692 6.42972 15.9073 5.84497C15.5941 5.60461 15.2571 5.37472 14.8995 5.16113ZM9.02994 11.0307C9.32366 11.1713 9.65264 11.25 10 11.25C11.2426 11.25 12.25 10.2426 12.25 9C12.25 8.65264 12.1713 8.32366 12.0307 8.02994L9.02994 11.0307Z"
      fill="white"
    />
    <path
      d="M10 2.25C11.0323 2.25 12.0236 2.40934 12.9511 2.68101C13.1296 2.73328 13.1827 2.95662 13.0513 3.0881L12.2267 3.91265C12.1648 3.97451 12.0752 3.99928 11.99 3.97967C11.3506 3.83257 10.6839 3.75 10 3.75C7.71345 3.75 5.61978 4.67292 4.09267 5.84497C3.33078 6.42972 2.72857 7.06256 2.32343 7.64635C1.90431 8.25029 1.75 8.7227 1.75 9C1.75 9.2773 1.90431 9.74971 2.32343 10.3536C2.67725 10.8635 3.18138 11.4107 3.81091 11.9307C3.92677 12.0264 3.93781 12.2015 3.83156 12.3078L3.12265 13.0167C3.03234 13.107 2.88823 13.1149 2.79037 13.0329C2.09739 12.4517 1.51902 11.8255 1.0911 11.2089C0.599371 10.5003 0.25 9.7227 0.25 9C0.25 8.2773 0.599371 7.49971 1.0911 6.79115C1.59681 6.06244 2.31262 5.32028 3.17941 4.65503C4.90965 3.32708 7.31598 2.25 10 2.25Z"
      fill="white"
    />
    <path
      d="M10 5.25C10.1185 5.25 10.2357 5.25549 10.3513 5.26624C10.5482 5.28453 10.6194 5.51991 10.4796 5.6597L9.26739 6.87196C8.61408 7.09683 8.09683 7.61408 7.87196 8.26739L6.6597 9.47965C6.51991 9.61945 6.28453 9.54818 6.26624 9.35132C6.25549 9.23566 6.25 9.11847 6.25 9C6.25 6.92893 7.92893 5.25 10 5.25Z"
      fill="white"
    />
  </svg>,
  "Home"
);

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(resetPassword(email));
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordEye, setPasswordEye] = useState(false);
  const [passwordEye1, setPasswordEye1] = useState(false);

  const handlePasswordClick = (e) => {
    setPasswordEye(!passwordEye);
  };
  const handlePasswordClick1 = (e) => {
    setPasswordEye1(!passwordEye1);
  };

  return (
    <div className="mainLogin">
      <img src={bgIMG} alt="" className="bgIMG" />

      <div className="mainLogin-Con">
        <div className="mainLogin-M">
          <img className="logoImg" src={LogoIMG} alt="" />
          <img className="loginLI fp" src={LoginLIMG} alt="" />
        </div>
        <div className="mainLogin-Overlay">
          <img className="LoginBackIMG" src={LoginBackIMG} alt="" />
          <div className="loginFormTop">
            <h1>Reset Password ?</h1>
          </div>
          <form id="forgot-pswd-form" style={{ paddingTop: "20px" }}>
            <div className="mt20" id="pswd-form-ctl">
              <label>{t("New Password")}</label>
              <TextField
                fullWidth
                id="password"
                name="password"
                className="form-input-password"
                type={passwordEye1 === false ? "password" : "text"}
                placeholder="Enter Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {passwordEye1 === false ? (
                        <HidePassword
                          onClick={handlePasswordClick1}
                          edge="end"
                        />
                      ) : (
                        <ShowPassword
                          onClick={handlePasswordClick1}
                          edge="end"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mt20" id="pswd-form-ctl">
              <label>{t("Confirm New Password")}</label>
              <TextField
                fullWidth
                sx={{ margin: 0 }}
                id="password"
                name="password"
                className="form-input-password"
                type={passwordEye === false ? "password" : "text"}
                placeholder="Enter Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {passwordEye === false ? (
                        <HidePassword
                          onClick={handlePasswordClick}
                          edge="end"
                        />
                      ) : (
                        <ShowPassword
                          onClick={handlePasswordClick}
                          edge="end"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div onClick={handleSubmit} type="submit" className="loginBtn">
              {t("submit")}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
