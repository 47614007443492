import React, { useEffect, useRef, useState } from "react";
import "../style.scss";
import { Grid } from "@mui/material";
import upOrangeArr from "../../../assets/images/up-arrow-orange.svg";
import upGreenArr from "../../../assets/images/up-arrow-green.svg";
import downRedArr from "../../../assets/images/red-arrow-down.svg";
import winnerIcon from "../../../assets/images/winner-cup.svg";
import totalIcon from "../../../assets/images/contestants.svg";
import infoIcon from "../../../assets/images/info-icon.svg";
import DropdownIcon from "../../../assets/images/dropdownIcon.svg";
import ReactApexChart from "react-apexcharts";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { seeCompStats } from "../../../store/reducers/competition";
import { useNavigate } from "react-router-dom";
import { returnErrors } from "../../../store/reducers/error";
import { use } from "i18next";
import ReactGA from "react-ga";
const Competitions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stats, setStats] = useState([]);

  const { competitionStats } = useSelector((state) => state.competition);
  const { competitionLeaderboard } = useSelector((state) => state.competition);

  const handleSeeStats = (data) => {
    dispatch(seeCompStats(data))
      .then(
        (res) => navigate("/competitions-details"),
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Use smooth scrolling
        })
      )
      .catch((err) => {
        dispatch(returnErrors(err?.message, 400, "Error"));
      });
  };

  useEffect(() => {
    setStats(competitionStats?.user);
  }, [competitionStats]);

  return (
    <Grid container lg={12} items justifyContent={"space-between"} gap={"20px"}>
      <div className="cubes-container">
        <div className="small-box">
          <p>Equity</p>
          <h3>${Number(stats?.balance_equity?.equity)?.toFixed(2)}</h3>
          <h4>
            <span>
              {/* <img src={upOrangeArr} alt="upOrangeArr" /> */}
              {(Number(stats?.balance_equity?.equity) -
                Number(stats?.account_size)) /
                Number(stats?.account_size) <
              0 ? (
                <img src={downRedArr} alt="downRedArr" />
              ) : (
                <img src={upGreenArr} alt="upGreenArr" />
              )}
            </span>
            {/* +2.53% */}
            {(
              (Number(stats?.balance_equity?.equity) -
                Number(stats?.account_size)) /
              Number(stats?.account_size)
            ).toFixed(4)}
            %
          </h4>
        </div>
        <div className="small-box">
          <p>Balance</p>
          <h3>${Number(stats?.balance_equity?.balance)?.toFixed(2)}</h3>
          <h4>
            <span>
              {(Number(stats?.balance_equity?.balance) -
                Number(stats?.account_size)) /
                Number(stats?.account_size) <
              0 ? (
                <img src={downRedArr} alt="downRedArr" />
              ) : (
                <img src={upGreenArr} alt="upGreenArr" />
              )}
            </span>
            {/* +2.53% */}
            {(
              (Number(stats?.balance_equity?.balance) -
                Number(stats?.account_size)) /
              Number(stats?.account_size)
            ).toFixed(4)}
            %
          </h4>
        </div>
        <div className="small-box">
          <p>P & L</p>
          <h3>{stats?.cumulative_pnl?.toFixed(2)}</h3>
          <h4>
            <span>
              {/* <img src={downRedArr} alt="RedArrow" /> */}
              {(Number(stats?.max_loss) / Number(stats?.account_size)) * 100 <
              0 ? (
                <img src={downRedArr} alt="downRedArr" />
              ) : (
                <img src={upGreenArr} alt="upGreenArr" />
              )}
            </span>
            {/* +2.53% */}
            {(
              (Number(stats?.max_loss) / Number(stats?.account_size)) *
              100
            ).toFixed(4)}
            %
          </h4>
        </div>
        <div className="small-box">
          <p>Win Rate</p>
          <h3>{stats?.win_rate?.toFixed(2)}%</h3>
          {/* <h4>
            <span>
              <img src={upGreenArr} alt="upGreenArr" />
            </span>
            $25,322
          </h4> */}
        </div>
        <div className="small-box">
          <p>Current Winner</p>
          {/* <h3>Jump250</h3> */}
          <h3 style={{ fontSize: "22px", color: "#54d788" }}>
            {competitionLeaderboard?.leaderboard[0]?.name}
          </h3>
          {/* <h4>
            <span>
              <img src={winnerIcon} alt="winnerIcon" />
            </span>
            Price: $78,098
          </h4> */}
        </div>
        <div className="small-box">
          <p>Total Contestants</p>
          <h3>{stats?.total_contestants}</h3>
          {/* <h4>
            <span>
              <img src={totalIcon} alt="totalIcon" />
            </span>
            23.53%
          </h4> */}
        </div>
      </div>
      <Grid lg={7.7} md={7.5} xs={12}>
        <div className="dashboard_chart_wrapper">
          <p>DASHBOARD</p>
          <div className="chart_wrapper">
            <LineChart data={stats?.balance_equity_history} />
          </div>
        </div>
      </Grid>
      <Grid lg={4} md={4} xs={12}>
        <div className="statistic_wrapper">
          <p>STATISTIC</p>
          <div className="stat-data">
            <div className="data-line">
              <div className="stat-name">
                <span>Current Equity</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                {/* <h2>$198,949.71</h2> */}
                <h2>-</h2>
                <p>05:18:35</p>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>Current Balance</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                {/* <h2>$198,949.71</h2> */}
                <h2>-</h2>
                <p>05:18:35</p>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>Starting Balance</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                {/* <h2>$100,000.00</h2> */}
                <h2>-</h2>
                <p>05:18:35</p>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>Cumulative PnL</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>{Number(stats?.cumulative_pnl)?.toFixed(2)}</h2>
                <p>05:18:35</p>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>No. of trades</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>48</h2>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>Lots</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>7.09</h2>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>% Return</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>{stats?.return_percentage?.toFixed(2)}%</h2>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span className="yellow">Highest Rank</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>2nd</h2>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>Largest winning trade</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>{stats?.largest_winning_trade}</h2>
                <p>05:18:35</p>
              </div>
            </div>
            <div className="data-line">
              <div className="stat-name">
                <span>Most traded product</span>
                <img src={infoIcon} alt="info icon" />
              </div>
              <div className="stat-value">
                <h2>{stats.most_traded_product}</h2>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <div className="leaderboard_table">
        <div className=" table_wrapper">
          <div className="table_header">
            <p>Competition Leaderboard</p>
            {/* <div className="select_wrapper">
              <img
                className="select_icon"
                src={CalenderIcon}
                alt="calender icon"
              />
              <Select
                options={options}
                styles={customStyles}
                value={options[0]}
                components={{IndicatorSeparator: null}}
              />
            </div> */}
          </div>
          <Table onSeeStats={handleSeeStats} />
        </div>
      </div>
    </Grid>
  );
};

export default Competitions;

const Table = ({ onSeeStats }) => {
  const { competitionLeaderboard } = useSelector((state) => state.competition);
  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const data = React.useMemo(() => competitionLeaderboard?.leaderboard, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="column_one_wrapper">{row.index + 1}</div>
        ),
      },

      {
        Header: (
          <>
            <div className="column_header">
              Name <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "name",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Date <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "date",
        sortType: "datetime",
      },
      { Header: "Account Size", accessor: "account_size" },
      {
        Header: (
          <>
            <div
              className="column_header"
              onClick={(e) => {
                e.stopPropagation();
                setChallengeDropdown(!isChallengeDropdown);
              }}
            >
              Challenge <img src={DropdownIcon} alt="arrow-down" />
            </div>

            {isChallengeDropdown && (
              <div className="challenge_dropdown" ref={dropdownRef}>
                <ul>
                  <li>All</li>
                  <li>25K Challenge</li>
                  <li>50K Challenge</li>
                  <li>100K Challenge</li>
                  <li>200K Challenge</li>
                </ul>
              </div>
            )}
          </>
        ),
        accessor: "challenge",
      },
      { Header: "Prize Won", accessor: "prizeWon" },
      {
        Header: "Status",
        // accessor: "status",
        Cell: ({ row }) => (
          <button
            className="view_details_btn"
            onClick={() => onSeeStats(row.original.id)}
          >
            View Details
          </button>
        ),
      },
    ],
    [isChallengeDropdown]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} className="leaderboard-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="row-slide">
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const LineChart = ({ data }) => {
  const options = {
    series: [
      {
        name: "Equity",
        // data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        data: data
          ? data.map((item) => ({
              x: new Date(item.time),
              y: parseInt(item.equity, 10),
            }))
          : [],
      },
      {
        name: "Balance",
        // data: [45, 55, 60, 58, 70, 75, 80, 95, 110],
        data: data
          ? data.map((item) => ({
              x: new Date(item.time),
              y: parseInt(item.balance, 10),
            }))
          : [],
      },
    ],
    chart: {
      id: "line-chart",
      type: "line",
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint1 = series[0][dataPointIndex];
        const dataPoint2 = series[1][dataPointIndex];
        const lineName1 = w.config.series[0].name;
        const lineName2 = w.config.series[1].name;

        return `
          <div class="custom-tooltip">
            <div>${lineName1}: $ ${dataPoint1}</div>
            <div>${lineName2}: $ ${dataPoint2}</div>
          </div>
        `;
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      labels: {
        colors: "#FFFFFF",
      },
    },
    stroke: { curve: "straight" },
    // xaxis: {
    //   categories: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //   ],
    // },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#FFFFFF",
        },
        formatter: function (value, timestamp) {
          const date = new Date(timestamp);
          const day = date.getDate();
          const month = date.toLocaleString("default", { month: "short" });
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="line"
      height={380}
    />
  );
};
