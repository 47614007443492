import * as React from "react";

import FormControl from "@mui/material/FormControl";
import "./style.scss";
import {FormHelperText} from "@mui/material";

const TextInput = (props) => {
  const {
    label,
    value,
    placeholder,
    onChange,
    error,
    type,
    disabled,
    name,
    helperText,
    ...restProps
  } = props;



  return (
    <FormControl variant="standard" className="op-txt-ctn">
      <label>{label}</label>
      <input
        disabled={disabled}
        type={type ? type : "text"}
        error={error ? "true" : "false"}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        {...restProps}
      />
      {helperText || error ? (
        <FormHelperText>{helperText || error}</FormHelperText>
      ) : (
        false
      )}
    </FormControl>
  );
};

export default TextInput;
