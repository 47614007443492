export const STRIPE_SUCCESS_URL =
  "https://dashboard.macandgray.com/payments/status/success";
export const STRIPE_CANCEL_URL =
  "https://dashboard.macandgray.com/payments/status/cancelled";
// export const STRIPE_SUCCESS_URL = 'http://localhost:3000/payments/status/success'
// export const STRIPE_CANCEL_URL = 'http://localhost:3000/payments/status/cancelled'
export const NEW_USER_STRIPE_SUCCESS_URL =
  "https://dashboard.macandgray.com/payment/status/success";
export const NEW_USER_STRIPE_CANCEL_URL =
  "https://dashboard.macandgray.com/payment/status/cancel";
