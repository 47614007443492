import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import laptop from "../../assets/images/laptop.png";
import { ReactComponent as DropdownIcon } from "../../assets/images/dropdownIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/downloadIcon.svg";
import { ReactComponent as BackIcon } from "../../assets/images/backIcon.svg";
import { ReactComponent as TradingAccShield } from "../../assets/images/tradingAccShield.svg";
import { ReactComponent as PlanSelectorIcon } from "../../assets/images/planSelectorIcon.svg";
import { useTable } from "react-table";
import { TabPanel } from "../../components/Reusable/TabPanel";

const Payout = () => {
  const [value, setValue] = React.useState(0);
  const [payoutNextPg, setPayoutNextPg] = useState(false);
  //   const {t} = useTranslation();
  const payoutTabs = ["Trading Account", "Affiliate"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container id="payout-section" className="mainBGcolor" spacing={2}>
      {payoutNextPg ? (
        <>
          <Grid
            item
            container
            lg={12}
            justifyContent={"space-between"}
            sx={{ gap: "16px" }}
          >
            <Grid item lg={7} className="request-container req-pay-inside">
              <Typography
                component={"span"}
                className="back-btn"
                onClick={() => setPayoutNextPg(false)}
              >
                <BackIcon /> Back
              </Typography>
              <Typography component={"h3"}>
                Ready to request Your Payout
              </Typography>
              <Typography className="request-para">
                Please click on the request payout button and proceed to fill
                out the needed information; our staff will contact you for
                further developments.
              </Typography>
              <Grid>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="tabination"
                >
                  {payoutTabs &&
                    payoutTabs.map((item, index) => {
                      return <Tab label={item} key={item} />;
                    })}
                </Tabs>

                {payoutTabs &&
                  payoutTabs.map((item, idx) => {
                    if (value === idx)
                      return (
                        <>
                          <TabPanel value={item} index={item} component="div">
                            <div
                              id="payout-toggle-section"
                              // sx={{flexGrow: 1, display: "flex", m: 3}}
                            >
                              <TabPanel value={value} index={0}>
                                {/* <Grid
                                  container
                                  justifyContent={"space-between"}
                                >
                                  <Grid
                                    item
                                    lg={5.9}
                                    className="tab-cards"
                                    sx={{ padding: "0px !important" }}
                                  >
                                    <div className="card-upper">
                                      <div className="up-head">
                                        <div
                                          className="plan-logo"
                                          style={{ backgroundColor: "#0CCE6B" }}
                                        >
                                          <TradingAccShield />
                                        </div>
                                        <span>Standard</span>
                                      </div>
                                      <div className="plan-selector">
                                        <PlanSelectorIcon />
                                      </div>
                                    </div>
                                    <div className="card-divider"></div>
                                    <div className="card-lower">
                                      Rorem ipsum dolor sit amet, consectetur
                                      adipiscing elit. Nunc vulputate libero et
                                      velit.
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={5.9}
                                    className="tab-cards"
                                    sx={{ padding: "0px !important" }}
                                  >
                                    <div className="card-upper">
                                      <div className="up-head">
                                        <div
                                          className="plan-logo"
                                          style={{ backgroundColor: "#F26122" }}
                                        >
                                          <TradingAccShield />
                                        </div>
                                        <span>Standard</span>
                                      </div>
                                      <div className="plan-selector">
                                        <PlanSelectorIcon />
                                      </div>
                                    </div>
                                    <div className="card-divider"></div>
                                    <div className="card-lower">
                                      Rorem ipsum dolor sit amet, consectetur
                                      adipiscing elit. Nunc vulputate libero et
                                      velit.
                                    </div>
                                  </Grid>
                                </Grid> */}
                                <Grid className="choose-login">
                                  <Typography>
                                    Choose Account Login ID*
                                  </Typography>
                                  <div className="dropdown-selector">
                                    Account <DropdownIcon />
                                  </div>
                                </Grid>
                              </TabPanel>
                              <TabPanel value={value} index={1}></TabPanel>
                            </div>
                          </TabPanel>
                        </>
                      );
                    return false;
                  })}
              </Grid>
            </Grid>
            {/* <Grid item container lg={5.5} className="competition-banner">
              <div style={{}}>
                <Typography component={"h3"}>
                  February 2022 Trading Competition
                </Typography>
                <Button>Enter Competiton</Button>
              </div>
            </Grid> */}
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            container
            lg={12}
            sx={{ paddingTop: "24px !important" }}
            justifyContent={"space-between"}
            gap={2}
          >
            <Grid item lg={6.7} className="request-container">
              <Typography component={"h3"}>
                Ready to request Your Payout
              </Typography>
              <Typography className="request-para">
                For payout & withdrawal reach out to our Support:{" "}
                <a href="mailto:support@macandgray.com">
                  support@macandgray.com
                </a>{" "}
                or raise a ticket in our{" "}
                <a href="https://discord.com/invite/3bugxZwETn">Discord</a>{" "}
              </Typography>
              {/* <Typography className="request-note">
                Note: Please wait for at least 15mins after you close your
                latest trade to request a payout.
              </Typography> */}
              <Grid display={"flex"} justifyContent={"center"}>
                <a
                  href="mailto:support@macandgray.com"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  <Button
                    className="request-paybtn"
                    // onClick={() => setPayoutNextPg(true)}
                  >
                    Request Payout
                  </Button>
                </a>
              </Grid>
            </Grid>
            {/* <Grid item container lg={5} className="competition-banner">
              <div style={{}}>
                <Typography component={"h3"}>
                  February 2022 Trading Competition
                </Typography>
                <Button>Enter Competiton</Button>
              </div>
            </Grid> */}
          </Grid>
          <Grid
            container
            item
            lg={12}
            className="heading"
            sx={{ marginTop: "30px" }}
          >
            {/* <div className="leaderboard_table">
              <div className=" table_wrapper">
                <div className="table_header">
                  <Typography component={"h3"}>CASES</Typography>
                </div>
                <Table />
              </div>
            </div> */}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Payout;

const Table = () => {
  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const data = React.useMemo(() => [], []);

  const columns = React.useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
        Cell: ({ value }) => (
          <>
            <div className="column_one_wrapper">
              <div className="side-line"></div>
              <p>{value}</p>
            </div>
          </>
        ),
      },
      {
        Header: (
          <>
            <div className="column_header">
              Payout Type <DropdownIcon />
            </div>
          </>
        ),
        accessor: "payoutType",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Requested on <DropdownIcon />
            </div>
          </>
        ),
        accessor: "requestedOn",
        //   sortType: "datetime",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Method <DropdownIcon />
            </div>
          </>
        ),
        accessor: "method",
      },
      //   {
      //     Header: (
      //       <>
      //         <div
      //           className="column_header"
      //             onClick={(e) => {
      //               e.stopPropagation();
      //               setChallengeDropdown(!isChallengeDropdown);
      //             }}
      //         >
      //           Method <DropdownIcon />
      //         </div>

      //         {isChallengeDropdown && (
      //           <div className="challenge_dropdown" ref={dropdownRef}>
      //             <ul>
      //               <li>
      //                 All
      //               </li>
      //               <li>25K Challenge</li>
      //               <li>50K Challenge</li>
      //               <li>100K Challenge</li>
      //               <li>200K Challenge</li>
      //             </ul>
      //           </div>
      //         )}
      //       </>
      //     ),
      //     accessor: "challenge",
      //   },
      {
        Header: (
          <>
            <div className="column_header">
              Amount <DropdownIcon />
            </div>
          </>
        ),
        accessor: "amount",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Status <DropdownIcon />
            </div>
          </>
        ),
        accessor: "status",
        Cell: ({ value }) => (
          <>
            {value === 1 ? (
              <div
                className="status-container"
                style={{ background: "#0CCE6B" }}
              >
                Done
              </div>
            ) : value === 2 ? (
              <div
                className="status-container"
                style={{ background: "#F26122" }}
              >
                In Progress
              </div>
            ) : (
              value === 3 && (
                <div
                  className="status-container"
                  style={{ background: "#D72638" }}
                >
                  Pending
                </div>
              )
            )}
          </>
        ),
      },
      {
        Header: "Invoices",
        accessor: "invoice",
        Cell: ({ value }) => <>{value && <DownloadIcon />}</>,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table
      {...getTableProps()}
      id="payout-cases-table"
      className="leaderboard-table"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="row-slide">
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
