import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as UploadIcon } from "../../assets/images/uploadIcon.svg";
import { ReactComponent as CheckTickIcon } from "../../assets/images/checkTickIcon.svg";
import upPassportIcon from "../../assets/images/upPassportIcon.svg";
import upIdentityIcon from "../../assets/images/upIdentityIcon.svg";
import upDrivingIcon from "../../assets/images/upDrivingIcon.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseUrl } from "../../utilities/apis/apis";

const UploadDocuments = () => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [document, setDocument] = useState();

  const handleFileChange = (e) => {
    setDocument(e.target.files[0]);
  };
  let formData = new FormData();
  formData.append("document", document);
  const idToken = useSelector((state) => state.auth.idToken);

  const handleSubmit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    axios
      .post(`${baseUrl}payment/kyc-upload/`, formData, config)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  return (
    <Grid container id="upload-docs" spacing={2}>
      <Grid container item lg={12} className="heading">
        <Typography component={"h3"}>{t("upload-documents")}</Typography>
        <Typography>Upload Any 1 Document</Typography>
      </Grid>
      <Grid item container lg={12} justifyContent={"space-between"}>
        <Grid item lg={3.8} className="upload-card-container">
          <div
            className="upload-cards"
            onClick={() => fileInputRef.current.click()}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(e)}
            />
            <div className="upload-icons">
              <img src={upPassportIcon} alt="upPassportIcon" />
            </div>
            <div className="card-text">
              <Typography component={"span"} className="uploadsvg">
                <UploadIcon />
              </Typography>
              Upload{" "}
              <Typography component={"span"} className="span-text">
                {" "}
                Document
              </Typography>
            </div>
          </div>
          <div className="upload-checklist">
            <div className="check-line">
              <Typography component={"span"} className="check-tick">
                <CheckTickIcon />
              </Typography>
              Image is complete
            </div>
            <div className="check-line">
              <Typography component={"span"} className="check-tick">
                <CheckTickIcon />
              </Typography>
              Clearly Visible
            </div>
          </div>
        </Grid>
        {/* <Grid item lg={3.8}>
          <div
            className="upload-cards"
            onClick={() => fileInputRef.current.click()}
          >
            <input type="file" ref={fileInputRef} style={{display: "none"}} />
            <div className="upload-icons">
              <img src={upIdentityIcon} alt="upIdentityIcon" />
            </div>
            <div className="card-text">
              <Typography component={"span"} className="uploadsvg">
                <UploadIcon />
              </Typography>
              Upload{" "}
              <Typography component={"span"} className="span-text">
                {" "}
                Identity Card
              </Typography>
            </div>
          </div>
          <div className="upload-checklist">
            <div className="check-line">
              <Typography component={"span"} className="check-tick">
                <CheckTickIcon />
              </Typography>
              Image is complete
            </div>
            <div className="check-line">
              <Typography component={"span"} className="check-tick">
                <CheckTickIcon />
              </Typography>
              Clearly Visible
            </div>
          </div>
        </Grid>
        <Grid item lg={3.8}>
          <div
            className="upload-cards"
            onClick={() => fileInputRef.current.click()}
          >
            <input type="file" ref={fileInputRef} style={{display: "none"}} />
            <div className="upload-icons">
              <img src={upDrivingIcon} alt="upDrivingIcon" />
            </div>
            <div className="card-text">
              <Typography component={"span"} className="uploadsvg">
                <UploadIcon />
              </Typography>
              Upload{" "}
              <Typography component={"span"} className="span-text">
                {" "}
                Driving License
              </Typography>
            </div>
          </div>
          <div className="upload-checklist">
            <div className="check-line">
              <Typography component={"span"} className="check-tick">
                <CheckTickIcon />
              </Typography>
              Image is complete
            </div>
            <div className="check-line">
              <Typography component={"span"} className="check-tick">
                <CheckTickIcon />
              </Typography>
              Clearly Visible
            </div>
          </div>
        </Grid> */}
      </Grid>
      <Grid item>
        <Button className="save-btn" onClick={handleSubmit}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default UploadDocuments;
