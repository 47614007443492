import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ReactGA from "react-ga"
const Payments = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  let location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    if(location.pathname==='/payments'){
      navigate('/payments/funding-evaluation')
    }
  },[location, navigate])
  return (
    <div>
      <Outlet/>
    </div>
  )
}

export default Payments
