import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import AddCircle from "../../../../assets/images/Add Circle.svg";
import MinusCircle from "../../../../assets/images/Minus Circle.svg";
import { createAffiliateCode } from "../../../../utilities/apis/apis";
import "./style.scss";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import MessageAlert from "../../../../components/Alerts/Message";
import { returnMessages } from "../../../../store/reducers/message";
import { returnErrors } from "../../../../store/reducers/error";
import { fetchAffiliateCodeList } from "../../../../store/saga/AffiliateSaga";

function CreateCoupon() {
  const navigate = useNavigate();
  // const {email} = useParams()
  const options = [
    { value: "Black Friday", label: "Black Friday" },
    { value: "WelcomeOFF", label: "WelcomeOFF" },
  ];
  const [copy, setCopy] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [value, setValue] = useState(5);
  const idToken = useSelector((state) => state.auth.idToken);

  const formData = {
    percentage: value,
    code: couponCode,
  };
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    if (formData.code === "") {
      alert("Please enter a code");
      return;
    } else {
      try {
        const response = await createAffiliateCode(idToken, formData);

        if (response.created) {
          dispatch(returnMessages("Coupon Created", "success", "Success"));
          setCouponCode("");
          navigate(-1);
          fetchAffiliateCodeList(idToken);
        }
      } catch (error) {
        console.error("Error in creating affiliate code", error);
        dispatch(returnErrors("Error creating coupon", "error", "Error"));
      }
    }
  };

  const copyalert = () => {
    if (couponCode.trim()) {
      setCopy(true);
    }
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,

      background: "#0f0f2c",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      color: "#fff",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? "#F26122" : "#0f0f2c",
      color: state.isFocused ? "white" : "#5E6670",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #eaf0f8;",
      background: "#0f0f2c",
    }),
    singleValue: (provided, state) => ({
      color: "white", // Change the color of the selected value here
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#FFF",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "white",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  return (
    <div className="create_affiliate">
      <div className="create_affiliate_top">
        <div className="coupon_wrapper">
          <div
            className="back_button_wrapper"
            onClick={handleBack}
            style={{ cursor: "pointer" }}
          >
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.53522 0.714697C6.19351 0.372988 5.63949 0.372988 5.29779 0.714697L0.631118 5.38136C0.28941 5.72307 0.28941 6.27709 0.631118 6.6188L5.29778 11.2855C5.63949 11.6272 6.19351 11.6272 6.53522 11.2855C6.87693 10.9438 6.87693 10.3897 6.53522 10.048L2.48727 6.00008L6.53522 1.95213C6.87693 1.61042 6.87693 1.05641 6.53522 0.714697Z"
                fill="#A4A8AE"
              />
            </svg>
            <button> Back</button>
          </div>
          <h2>Create Affiliate Code</h2>
          <div className="coupon_input_wrapper">
            {/* <label for="coupon_name" className="label_black">
              Coupon Name
            </label>
            <Select
              className="coupon_name"
              options={options}
              styles={customStyles}
              placeholder={"Black Friday"}
            />
            <br /> */}
            <label for="coupon_code" className="label_black">
              Coupon Code
            </label>
            <div className="coupon_code_wrapper">
              <input
                className="coupon_code"
                type="text"
                name="coupon_code"
                value={couponCode}
                placeholder=" Enter coupon Code"
                style={{ textTransform: "uppercase" }}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setCouponCode(value);
                }}
              />
              <CopyToClipboard text={couponCode}>
                <div
                  className="copy_button"
                  style={{ cursor: "pointer" }}
                  onClick={copyalert}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.6 5.27832C15.2365 5.27832 15.847 5.53118 16.2971 5.98126C16.7471 6.43135 17 7.0418 17 7.67832L17 14.6783C17 15.3148 16.7471 15.9253 16.2971 16.3754C15.847 16.8255 15.2365 17.0783 14.6 17.0783L7.4 17.0783C6.76348 17.0783 6.15303 16.8255 5.70295 16.3754C5.25286 15.9253 5 15.3148 5 14.6783L5 7.67832C5 7.0418 5.25286 6.43135 5.70294 5.98127C6.15303 5.53118 6.76348 5.27832 7.4 5.27832L14.6 5.27832ZM15.8 7.67832C15.8 7.36006 15.6736 7.05484 15.4485 6.82979C15.2235 6.60475 14.9183 6.47832 14.6 6.47832L7.4 6.47832C7.08174 6.47832 6.77652 6.60475 6.55147 6.82979C6.32643 7.05484 6.2 7.36006 6.2 7.67832L6.2 14.6783C6.2 14.9966 6.32643 15.3018 6.55147 15.5269C6.77652 15.7519 7.08174 15.8783 7.4 15.8783L14.6 15.8783C14.9183 15.8783 15.2235 15.7519 15.4485 15.5268C15.6736 15.3018 15.8 14.9966 15.8 14.6783L15.8 7.67832Z"
                      fill="white"
                    />
                    <path
                      d="M19.3984 7.0784C19.3984 6.65712 19.2875 6.24327 19.0769 5.87843C18.8662 5.5136 18.5633 5.21064 18.1984 5L18.1984 15.2784C18.1984 16.0741 17.8824 16.8371 17.3198 17.3997C16.7572 17.9623 15.9941 18.2784 15.1984 18.2784L7.72004 18.2784C7.93068 18.6432 8.23364 18.9462 8.59847 19.1568C8.96331 19.3675 9.37716 19.4784 9.79844 19.4784L15.1984 19.4784C16.3123 19.4784 17.3806 19.0359 18.1683 18.2483C18.9559 17.4606 19.3984 16.3923 19.3984 15.2784L19.3984 7.0784Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </CopyToClipboard>
              <p style={{ color: "#93d58a", height: "10px" }}>
                {copy ? "Copied coupon code " : null}
              </p>
            </div>

            <br />
            {/* <div className="note_wrapper">
              <p>
                Lorem ipsum dolor sit amet consectetur. Purus risus lacus
                vulputate at. Fringilla arcu quisque pharetra risus vitae eu
                egestas dolor. Id viverra duis ipsum porttitor quis ut. Nunc
                proin ornare fringilla diam.
              </p>
              <h3>NOTE: Fixed Commission Income rates for comapny is 15%ary</h3>
              <br />
            </div> */}
          </div>
        </div>
      </div>
      <div className="create_affiliate_bottom">
        <div className="coupon_opts_wrapper">
          {/* <div className="upper">
            <label for="user_count" className="label_black">
              Users
            </label>
            <input
              type="text"
              className="user_count_input"
              name="user_count"
              value="5,00,000"
            />
          </div>
          <br /> */}
          <div className="middle">
            <div className="middle-upper-input-fields">
              {/* <div className="wrapper">
              <label for="coupon_value" className="label_black">
                 commission
              </label>

              <div className="coupon_value">
                <div className="input_wrapper">
                  <img src={AddCircle} alt="AddCircle" />
                  <input
                    type="text"
                    className="percent_input"
                    value={couponValue}
                  />
                  <img src={MinusCircle} alt="MinusCircleCircle" />
                </div>
              </div>
            </div> */}
              <div className="wrapper">
                <label for="coupon_value" className="label_black">
                  Discount %
                </label>

                {/* <div className="coupon_value">
                  <div className="input_wrapper">
                    <img
                      onClick={() =>
                        couponValue >= 1 ? setCouponValue(couponValue - 1) : ""
                      }
                      style={{ cursor: "pointer" }}
                      src={MinusCircle}
                      alt="AddCircle"
                    />
                    <input
                      type="text"
                      className="percent_input"
                      value={couponValue}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value);
                        if (e.target.value === "") {
                          setCouponValue(0);
                        } else if (
                          !isNaN(newValue) &&
                          newValue >= 1 &&
                          newValue <= 100
                        ) {
                          setCouponValue(newValue);
                        } else {
                          setCouponValue(100);
                        }
                      }}
                      maxLength={"3"}
                    />
                    <img
                      onClick={() =>
                        couponValue < 100 ? setCouponValue(couponValue + 1) : ""
                      }
                      style={{ cursor: "pointer" }}
                      src={AddCircle}
                      alt="MinusCircleCircle"
                    />
                  </div>
                </div> */}
                <div>
                  <Box sx={{ width: 300 }}>
                    <Typography id="non-linear-slider" gutterBottom>
                      Discount: {value + "%"}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <p>0%</p>
                      <Slider
                        value={value}
                        min={0}
                        step={1}
                        max={10}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                      />
                      <p>10%</p>
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
            {/* <div className="wrapper">
              <label for="coupon_value" className="label_black">
                Repeat Discount
              </label>

              <div className="coupon_value">
                <div className="input_wrapper">
                  <img src={AddCircle} alt="AddCircle" />
                  <input
                    type="text"
                    className="percent_input"
                    value={couponValue}
                  />
                  <img src={MinusCircle} alt="MinusCircleCircle" />
                </div>
              </div>
            </div> */}
          </div>
          <br />
        </div>
      </div>
      <div className="create_coupon" style={{ display: "block" }}>
        <button onClick={handleSubmit}>Save</button>
      </div>
    </div>
  );
}

export default CreateCoupon;
