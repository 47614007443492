import { Avatar, Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import { TabPanel } from "../../components/Reusable/TabPanel";
// import {Button, Grid, Tab, Tabs, Typography} from "@mui/material";
// import {Box} from "@mui/system";
import "./affiliateStyle.scss";
import "./affiliateTable.scss";
// import "./global.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import vectorIcon from "../../assets/images/Caret up.svg";
import vector9 from "../../assets/images/Vector (9).svg";
import addCircle from "../../assets/images/Add Circle.svg";
import AffiliateCode from "./affiliateCode";

import AffiliateOverview from "./AffiliateOverview";

const Index = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const totalPages = 5;
  const dummyData = [
    {
      id: 1,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "In Progress",
    },
    {
      id: 2,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "error",
    },
    {
      id: 3,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "successful",
    },
    {
      id: 4,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "done",
    },
    {
      id: 5,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "error",
    },
    {
      id: 6,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "successful",
    },
    {
      id: 7,
      fundingEvaluation: "Harry Potter",
      accountBalance: "20230801",
      transactionID: "17730536",
      date: "2023-08-22",
      amount: "$350 USD",
      invoices: "27k",
      status: "In Progress",
    },
  ];
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const payoutTabs = ["Trading Account", "Affiliate"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNavigateToCreateAffiliateCoupan = () => {
    navigate("/affiliate-list/create-coupon");
  };
  //
  // const plans = ["Overview", "Affiliate Codes", "Commision Payment"];
  // const plans = ["Overview", "Affiliate Codes",];
  const plans = ["Affiliate Codes"];
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="tabination"
      >
        {plans &&
          plans.map((item, index) => {
            return <Tab label={item} key={item} />;
          })}
      </Tabs>
      {plans &&
        plans.map((item, idx) => {
          if (value === idx)
            return (
              <>
                <TabPanel
                  value={item}
                  index={item}
                  component="div"
                  className="tab-panel"
                >
                  <div
                    id="settings-section"
                    className="mainBGcolor"
                    // sx={{flexGrow: 1, display: "flex", m: 3}}
                  >
                    <TabPanel value={value} index={0}>
                      {/* <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                      > */}
                      {/* <Grid item lg={2.7} className="nopadding"></Grid> */}
                      {/* <Grid item lg={9} className="nopadding">
                          <AffiliateOverview />
                        </Grid> */}
                      <AffiliateOverview />
                      {/* </Grid> */}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="leaderboard_table_overview">
                        <div className=" table_wrapper">
                          <div className="table_header">
                            <div className="tab-panel-content">
                              {/* <input class="nosubmit" type="search" placeholder="Search..." style={{width:'30%',height:'40px',backgroundColor:'#111142',borderRadius:'63px',position:'relative',top:'10px'}}/> */}
                              <div className="tab-panel-content">
                                <div className="search-calender">
                                  <input
                                    class="nosubmit"
                                    type="search"
                                    placeholder="Search..."
                                    style={{
                                      width: "25%",
                                      height: "40px",
                                      backgroundColor: "#111142",
                                      borderRadius: "63px",
                                      position: "relative",
                                      top: "0px",
                                      margin: "10px 0px",
                                    }}
                                    id="search-input-aff-code"
                                  />

                                  <div
                                    className="calender"
                                    style={{ textWrap: "noWrap" }}
                                  >
                                    {/* <img
                                      className="calender-img"
                                      src={vector8}
                                    ></img> */}
                                  </div>
                                  <div
                                    className="affiliate-header"
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                      position: "static",
                                      alignItems: "center",
                                      textWrap: "nowrap",
                                      left: "65vh",
                                      top: "10px",
                                      gap: "15px",
                                    }}
                                    onClick={
                                      handleNavigateToCreateAffiliateCoupan
                                    }
                                  >
                                    <img
                                      src={addCircle}
                                      style={{ height: "20px" }}
                                      alt="add circle"
                                    />{" "}
                                    <div className="createAffiliateCode1">
                                      Create Affiliate
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <p>Competition Leaderboard</p> */}
                            {/* <div className="component70">
  <img className="hicon1" alt="" src={Hicon} />
  <div className="search">Search....</div>
  </div> */}

                            {/* <div className="select_wrapper">
            <img
              className="select_icon"
              src={CalenderIcon}
              alt="calender icon"
            />
            <Select
              options={options}
              styles={customStyles}
              value={options[0]}
              components={{IndicatorSeparator: null}}
            />
          </div> */}
                          </div>
                          <AffiliateCode tableData={dummyData} />
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </TabPanel>
              </>
            );
          return false;
        })}
    </>
  );
};

export default Index;

const Table = ({ tableData }) => {
  const is_loading = useSelector((state) => state.loader.is_loading);

  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // const data = React.useMemo(
  //   () => [
  //     {
  //       id: 1,
  //       name: "Harry Potter",
  //       date: "2023-07-01",
  //       account_size: 5000,
  //       challenge: "25K Challenge",
  //       prizeWon: "Apple MacBook",
  //       status: "Won",
  //     },
  //     {
  //       id: 2,
  //       name: "Hermione Granger",
  //       date: "2023-07-02",
  //       account_size: 6000,
  //       challenge: "50K Challenge",
  //       prizeWon: "Apple iPhone",
  //       status: "Lost",
  //     },
  //     {
  //       id: 3,
  //       name: "Ron Weasley",
  //       date: "2023-07-03",
  //       account_size: 7000,
  //       challenge: "100K Challenge",
  //       prizeWon: "Apple Watch",
  //       status: "Won",
  //     },
  //     {
  //       id: 4,
  //       name: "Albus Dumbledore",
  //       date: "2023-07-04",
  //       account_size: 5500,
  //       challenge: "25K Challenge",
  //       prizeWon: "Apple AirPods",
  //       status: "Lost",
  //     },
  //     {
  //       id: 5,
  //       name: "Severus Snape",
  //       date: "2023-07-05",
  //       account_size: 4800,
  //       challenge: "50K Challenge",
  //       prizeWon: "Apple iPad",
  //       status: "Won",
  //     },
  //     {
  //       id: 6,
  //       name: "Draco Malfoy",
  //       date: "2023-07-06",
  //       account_size: 6200,
  //       challenge: "100K Challenge",
  //       prizeWon: "Apple HomePod",
  //       status: "Lost",
  //     },
  //     {
  //       id: 7,
  //       name: "Luna Lovegood",
  //       date: "2023-07-07",
  //       account_size: 5300,
  //       challenge: "25K Challenge",
  //       prizeWon: "Apple AirTags",
  //       status: "Won",
  //     },
  //     {
  //       id: 8,
  //       name: "Neville Longbottom",
  //       date: "2023-07-08",
  //       account_size: 5700,
  //       challenge: "50K Challenge",
  //       prizeWon: "Apple TV",
  //       status: "Lost",
  //     },
  //     {
  //       id: 9,
  //       name: "Ginny Weasley",
  //       date: "2023-07-09",
  //       account_size: 4900,
  //       challenge: "100K Challenge",
  //       prizeWon: "Apple HomePod Mini",
  //       status: "Won",
  //     },
  //     {
  //       id: 10,
  //       name: "Fred Weasley",
  //       date: "2023-07-10",
  //       account_size: 6000,
  //       challenge: "25K Challenge",
  //       prizeWon: "Apple AirPods Pro",
  //       status: "Lost",
  //     },
  //   ],
  //   []
  // );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleSeeStats = (data) => {
  //   dispatch(setLoading(true));
  //   dispatch(seeCompStats(data))
  //     .then((res) => {
  //       dispatch(setLoading(false));
  //       navigate("/competitions-details");
  //     })
  //     .catch((err) => {
  //       dispatch(setLoading(false));
  //       dispatch(returnErrors(err?.message, 400, "Error"));
  //     });
  // };

  const data = React.useMemo(() => tableData, []);
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "avatar",
        Cell: ({ row }) => (
          <div className="column_one_wrapper">
            <div className="side-line"></div>
            <Avatar sx={{ bgcolor: "#616184", color: "white" }}>
              {row.original.fundingEvaluation.charAt(0)}
            </Avatar>
          </div>
        ),
      },
      {
        Header: (
          <>
            <div
              className="column_header"
              style={{ position: "relative", top: "3px" }}
            >
              Funding Evaluation
            </div>
          </>
        ),
        accessor: "fundingEvaluation",
      },
      {
        Header: (
          <>
            {/* <div className="column_header"> */}
            Account Balance{" "}
            <img
              src={vectorIcon}
              alt="arrow-down"
              className="vector-icon"
            />{" "}
            {/* </div> */}
          </>
        ),
        accessor: "accountBalance",
      },
      {
        Header: (
          <>
            {/* <div className="column_header"> */}
            Transaction ID{" "}
            <img
              src={vectorIcon}
              alt="arrow-down"
              className="vector-icon"
            />{" "}
            {/* </div> */}
          </>
        ),
        accessor: "transactionID",
      },
      {
        Header: (
          <>
            {/* <div className="column_header"> */}
            Date{" "}
            <img
              src={vectorIcon}
              alt="arrow-down"
              className="vector-icon"
            />{" "}
            {/* </div> */}
          </>
        ),
        accessor: "date",
      },
      {
        Header: (
          <>
            {/* <div className="column_header"> */}
            Amount{" "}
            <img
              src={vectorIcon}
              alt="arrow-down"
              className="vector-icon"
            />{" "}
            {/* </div> */}
          </>
        ),
        accessor: "amount",
      },
      {
        Header: "Invoices",
        // accessor: "status",
        Cell: ({ row }) => (
          <>
            <img alt="" src={vector9} />
          </>
        ),
      },

      {
        Header: "Status",
        Cell: ({ row }) => (
          <div>
            {row.original.status === "successful" && (
              <div className="successful">
                <div className="passed">successful</div>
              </div>
            )}
            {row.original.status === "error" && (
              <div className="error">
                <div className="passed">error</div>
              </div>
            )}
            {row.original.status === "In Progress" && (
              <div className="in-progress">
                <div className="passed">In Progress</div>
              </div>
            )}
            {row.original.status === "done" && (
              <div className="done">
                <div className="passed">Done</div>
              </div>
            )}
          </div>
        ),
      },
      // {
      //   Header: (
      //     <>
      //       <div className="column_header">
      //         Account Balance <img src={vectorIcon} alt="arrow-down" />{" "}
      //       </div>
      //     </>
      //   ),
      //   accessor: "accountNumber",
      //   sortType: "accountNumber",
      // },
      // { Header: "Transaction ID", accessor: "percentageOfGoodTrades" },
      // { Header: "Date", accessor: "percentageOfBadTrades" },
      // {
      //   Header: (
      //     <>
      //       <div
      //         className=""
      //         style={{ color: "#b6b6c6" }}
      //         onClick={(e) => {
      //           e.stopPropagation();
      //           setChallengeDropdown(!isChallengeDropdown);
      //         }}
      //       >
      //         {" "}
      //         Amount
      //         {/* <img src={vectorIcon} alt="arrow-down" />{" "} */}
      //       </div>

      //       {isChallengeDropdown && (
      //         <div className="challenge_dropdown" ref={dropdownRef}>
      //           <ul>
      //             <li>All</li>
      //             <li>25K Challenge</li>
      //             <li>50K Challenge</li>
      //             <li>100K Challenge</li>
      //             <li>200K Challenge</li>
      //           </ul>
      //         </div>
      //       )}
      //     </>
      //   ),
      //   accessor: "startDate",
      // },
      // { Header: "Invoices", accessor: "endDate" },
      // {
      //   Header: "Trader Type",
      //   // accessor: "status",
      //   Cell: ({ row }) => (
      //     <button
      //       className="view_details_btn"
      //       // onClick={() => handleSeeStats(row.original.id)}
      //     >
      //       Evaluation
      //     </button>
      //   ),
      // },
      // {
      //   Header: "User Action",
      //   // accessor: "status",
      //   Cell: ({ row }) => (
      //     <div
      //       className="groupIcon"

      //       // onClick={() => handleSeeStats(row.original.id)}
      //     >
      //       <img className={styles.groupIcon} alt="" src={vector6} />
      //       <img className={styles.groupIcon} alt="" src={vector7} />
      //     </div>
      //   ),
      // },
    ],
    [isChallengeDropdown]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} className="leaderboard-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="row-slide ">
              {row.cells.map((cell, index) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
