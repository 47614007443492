import React from "react";
import ReactApexChart from "react-apexcharts";
import { dollarUS } from "../../utilities/formatter";

const BalanceChart = ({ data }) => {
  console.log("data", data);
  const balance = data?.map((item) => item?.balance) || [];
  const equity = data?.map((item) => item?.equity) || [];
  const time = data?.map((item) => item?.time) || [];
  const series = [
    {
      name: "Balance",
      data: balance,
    },
    {
      name: "Equity",
      data: equity,
    },
  ];

  const options = {
    chart: {
      width: "100%",
      height: 1000,
      type: "line",
      fontFamily: "Urbanist",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    colors: ["#F26122", "#3DD889"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
      },
      borderColor: "rgba(255, 255, 255, 0.02)",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      color: "#FFFFFF",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: "12px",
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // x: {
      //   show: true,
      //   formatter: (val) => {
      //     const date = new Date(val);
      //     return `${date.toDateString()} ${date.toLocaleTimeString()}`;
      //   },
      // },
      // y: {
      //   formatter: (val) => dollarUS(val),
      //   title: {
      //     formatter: (seriesName) => seriesName,
      //   },
      // },
      z: {
        formatter: undefined,
        title: "Size: ",
      },
      marker: {
        show: false,
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },

    xaxis: {
      categories: time,
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: "#8F8F8F",
          fontSize: "13px",
          fontWeight: 500,
          fontFamily: "Urbanist",
          cssClass: "balancechart-xaxis-label",
        },
        formatter: function (val) {
          const date = new Date(val);
          const utcDate = new Date(
            date.getTime() + date.getTimezoneOffset() * 60000
          ); // Convert to UTC
          return `${utcDate.getDate()} ${utcDate.toLocaleString("default", {
            month: "short",
          })} ${utcDate.getUTCFullYear()}`;
        },
        // offsetX: 0,
        // offsetY: 0,
        // format: undefined,
        // formatter: undefined,
        // datetimeUTC: true,
        // datetimeFormatter: {
        //     year: 'yyyy',
        //     month: "MMM 'yy",
        //     day: 'dd MMM',
        //     hour: 'HH:mm',
        // },
      },
      axisBorder: {
        show: false,
        color: "#78909C",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#8F8F8F",
          fontSize: "13px",
          fontWeight: 500,
          fontFamily: "Urbanist",
          cssClass: "balancechart-yaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        tickAmount: 10,
      },
    },
  };

  return (
    <div id="balance-chart">
      <ReactApexChart
        style={{ width: "100%" }}
        type="line"
        options={options}
        series={series}
      />
    </div>
  );
};
export default BalanceChart;
