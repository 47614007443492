import { Box, Button, Modal, Typography } from "@mui/material";
import CloseCircleIcon from "../../assets/images/closeCircle.svg";
import React, { useState } from "react";
import TextInput from "../../components/Form/TextInput";
import { useDispatch } from "react-redux";
import { returnMessages } from "../../store/reducers/message";
import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import { returnErrors } from "../../store/reducers/error";
import { baseUrl } from "../../utilities/apis/apis";
const AddAddress = ({ open, onClose }) => {
  const {
    state: { data },
  } = useLocation();
  const [formData, setFormData] = useState(
    data !== "addaddress"
      ? {
          city: data.city,
          apartment_no: data.apartment_no,
          street_address: data.street_address,
          state: data.state,
          zip_code: data.zip_code,
          country: data.country,
        }
      : {
          city: "",
          apartment_no: "",
          street_address: "",
          state: "",
          zip_code: "",
          country: "",
        }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const idToken = useAuth();

  const dispatch = useDispatch();
  const saveInfoClick = (e) => {
    e.preventDefault();

    const msg =
      data !== "addaddress"
        ? "Address Successfully Updated"
        : "Address Successfully Added";
    const status = 200;
    const Estatus = 400;
    const msgs = "Error: 400";
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    data !== "addaddress"
      ? axios
          .patch(
            `${baseUrl}userprofile/update-address/?address_id=${data.id}`,
            formData,
            config
          )
          .then((res) => dispatch(returnMessages(msg, status)), onClose())
          .catch((err) => dispatch(returnErrors(msgs, Estatus)))
      : axios
          .post(`${baseUrl}userprofile/address/`, formData, config)
          .then((res) => dispatch(returnMessages(msg, status)), onClose())
          .catch((err) => dispatch(returnErrors(msgs, Estatus)));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="address-modal">
        <Box className="address-head">
          <Typography component={"h3"}>
            {data !== "addaddress" ? "Update Address" : "Add Address"}
          </Typography>
          <img
            src={CloseCircleIcon}
            onClick={onClose}
            alt="CloseCircleIcon"
            style={{ cursor: "pointer" }}
          />
        </Box>

        <TextInput
          name="apartment_no"
          label="Apartment No"
          placeholder="1623 Hunningon Place"
          value={formData.apartment_no}
          onChange={handleChange}
        />
        <TextInput
          name="street_address"
          label="Street Address"
          placeholder="1129 New Castle Road"
          value={formData.street_address}
          onChange={handleChange}
        />
        <TextInput
          name="city"
          label="City"
          placeholder="Daniel Radcliff"
          value={formData.city}
          onChange={handleChange}
        />
        <TextInput
          name="state"
          label="State"
          placeholder="Ontorio"
          value={formData.state}
          onChange={handleChange}
        />

        <TextInput
          name="country"
          label="Country"
          placeholder="Canada"
          value={formData.country}
          onChange={handleChange}
        />
        <TextInput
          name="zip_code"
          label="Zip Code"
          placeholder="42124"
          value={formData.zip_code}
          onChange={handleChange}
        />
        <Button
          onClick={saveInfoClick}
          variant="contained"
          fullWidth
          type="submit"
        >
          Save Info
        </Button>
      </Box>
    </Modal>
  );
};

export default AddAddress;
