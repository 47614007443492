import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { TabPanel } from "../../../components/Reusable/TabPanel";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { getChallengePricing } from "../../../store/reducers/payment";
import { useDispatch, useSelector } from "react-redux";
import { dollarUS } from "../../../utilities/formatter";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { ReactComponent as Tick } from "../../../assets/SVG/tick.svg";
import { ReactComponent as Cross } from "../../../assets/SVG/cross.svg";

const Cards = ({ data, setFooterValue, names }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleMouseOver = (data) => {
    setFooterValue(data);
  };

  console.log("first", data);

  const Items = () => (
    <Grid
      container
      spacing={2}
      // component={"ul"}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        gap: "10px",
      }}
    >
      {/* {data.leverage && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Leverage")}
          </Typography>
          <Typography
            sx={{ fontWeight: 700, display: "flex", fontSize: "16px" }}
          >
            {data && "1:" + data.leverage}
          </Typography>
        </div>
      </>
      {/* )} */}
      {/* {data.account_balance && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Max drawdown")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.max_loss || "10"}%`}
          </Typography>
        </div>
      </>
      {/* )} */}
      {/* {data.profit_target && ( */}
      {names === "Sensei (Instant Funding)" && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max daily loss")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_daily_loss || "10%"}`}
            </Typography>
          </div>
        </>
      )}
      {/* )} */}
      {/* {data.profit_target && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Max daily drawdown")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.max_daily_loss || "10%"}`}
          </Typography>
        </div>
      </>
      {/* )} */}
      {/* {data.profit_target_stage_1 && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Min trading days")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.minimum_trading_days}`}
          </Typography>
        </div>
      </>
      {/* )} */}
      {/* {data.profit_target_stage_2 && ( */}
      <>
        {" "}
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Profit split")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.profit_split || "50/50"}`}
          </Typography>
        </div>
      </>
      {/* )} */}
      {/* {data.max_loss && ( */}

      <div className="card_item">
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {t("Profit Target")}
        </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {data && ` ${data.profit_target || "5"}%`}
        </Typography>
      </div>

      {/* )} */}
      {/* {data.max_loss_stage_1 && ( */}
      <div className="card_item">
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {"Max increase"}
        </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {data && ` ${data.max_increase || "$2 million"}`}
        </Typography>
      </div>
      {/* )} */}
      {/* {data.max_loss_stage_2 && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Bi weekly withdrawal")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {(data && data?.bi_weekly_withdrawal) || true ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.max_daily_loss && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Hold over weekends")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && data?.hold_over_the_weekend ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>
      {/* )} */}
    </Grid>
  );
  const displayText = data.name.split(" ");

  return (
    <CardContent
      className="pricing-card"
      onMouseOver={() => handleMouseOver(data)}
    >
      {/* <Typography className="challenge_name">{data.name}</Typography> */}
      <Typography className="challenge_name">{"Instant Funding"}</Typography>
      <Typography
        fontSize="36px"
        fontWeight={900}
        style={{ marginBottom: "40px" }}
      >
        ${displayText[0] && displayText[0]}
      </Typography>
      {/* <Typography mb={1}>{displayText.slice(1).join(" ")}</Typography> */}
      {/* <Divider sx={{ borderColor: "#636363", marginBottom: "24px" }}></Divider> */}
      <Items />
      <CardActions className="fevalBtn">
        <Button
          onClick={() =>
            navigate(`/payments/funding-evaluation/checkout/${data.id}`)
          }
        >
          {t("pay")} {dollarUS(data.price)}
        </Button>
      </CardActions>
    </CardContent>
  );
};

const CardsTwo = ({ data, setFooterValue, names }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleMouseOver = (data) => {
    setFooterValue(data);
  };

  console.log("first", data);

  const Items = () => (
    <Grid
      container
      spacing={2}
      // component={"ul"}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        gap: "10px",
      }}
    >
      {/* {data.leverage && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Leverage")}
          </Typography>
          <Typography
            sx={{ fontWeight: 700, display: "flex", fontSize: "16px" }}
          >
            {data && "1:" + data.leverage}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.account_balance && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Max drawdown")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.max_loss || "7"}%`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.profit_target && ( */}

      {/* )} */}

      {/* {data.profit_target && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Max daily drawdown")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.max_daily_loss || "4"}%`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.profit_target_stage_1 && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Min trading days")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.minimum_trading_days}`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.profit_target_stage_2 && ( */}
      <>
        {" "}
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Profit split")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.profit_split || "85"}/15`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.max_loss && ( */}

      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Profit Target")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.profit_target || 10}%`}
          </Typography>
        </div>
      </>

      {/* )} */}

      {/* {data.max_loss_stage_1 && ( */}

      <div className="card_item">
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {"EAs Allowed"}
        </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {(data && data?.eas) || true ? (
            <Tick style={{ width: "18px", height: "18px" }} />
          ) : (
            <Cross style={{ width: "18px", height: "18px" }} />
          )}
        </Typography>
      </div>

      {/* )} */}
      {/* {data.max_loss_stage_2 && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Bi weekly withdrawal")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {(data && data?.bi_weekly_withdrawal) || true ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>
      {/* )} */}

      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("News Trading allowed")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {(data && data?.trade_through_news) || true ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>

      {/* {data.max_daily_loss && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {"Trade Copier allowed"}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {(data && data?.trade_through_news) || true ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>
      {/* )} */}
    </Grid>
  );
  const displayText = data.name.split(" ");

  return (
    <CardContent
      className="pricing-card"
      onMouseOver={() => handleMouseOver(data)}
    >
      {/* <Typography className="challenge_name">{data.name}</Typography> */}
      <Typography className="challenge_name">{"1-Step evaluation"}</Typography>
      <Typography
        fontSize="36px"
        fontWeight={900}
        style={{ marginBottom: "40px" }}
      >
        ${displayText[0] && displayText[0]}
      </Typography>
      {/* <Typography mb={1}>{displayText.slice(1).join(" ")}</Typography> */}
      {/* <Divider sx={{ borderColor: "#636363", marginBottom: "24px" }}></Divider> */}
      <Items />
      <CardActions className="fevalBtn">
        <Button
          onClick={() =>
            navigate(`/payments/funding-evaluation/checkout/${data.id}`)
          }
        >
          {t("pay")} {dollarUS(data.price)}
        </Button>
      </CardActions>
    </CardContent>
  );
};

const CardsThree = ({ data, setFooterValue, names }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleMouseOver = (data) => {
    setFooterValue(data);
  };

  console.log("first", data);

  const Items = () => (
    <Grid
      container
      spacing={2}
      // component={"ul"}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        gap: "10px",
      }}
    >
      {/* {data.leverage && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Leverage")}
          </Typography>
          <Typography
            sx={{ fontWeight: 700, display: "flex", fontSize: "16px" }}
          >
            {data && "1:" + data.leverage}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.account_balance && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Max drawdown")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.max_loss || "10"}%`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.profit_target && ( */}

      {names === "Sensei (Instant Funding)" && (
        <>
          <div className="card_item">
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("Max daily loss")}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {data && ` ${data.max_daily_loss || "10%"}`}
            </Typography>
          </div>
        </>
      )}

      {/* )} */}

      {/* {data.profit_target && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Max daily drawdown")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.max_daily_loss || "5"}%`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.profit_target_stage_1 && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Min trading days")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.minimum_trading_days}`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.profit_target_stage_2 && ( */}
      <>
        {" "}
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Profit split")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.profit_split || "80"}/20`}
          </Typography>
        </div>
      </>
      {/* )} */}

      {/* {data.max_loss && ( */}

      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Phase 1 : Profit Target")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.profit_target_stage_1 || "8"}%`}
          </Typography>
        </div>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Phase 2 : Profit Target")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && ` ${data.profit_target_stage_2 || "5"}%`}
          </Typography>
        </div>
      </>

      {/* )} */}

      {/* {data.max_loss_stage_1 && ( */}

      <div className="card_item">
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {"EAs Allowed"}
        </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
          {(data && data?.eas) || true ? (
            <Tick style={{ width: "18px", height: "18px" }} />
          ) : (
            <Cross style={{ width: "18px", height: "18px" }} />
          )}
        </Typography>
      </div>

      {/* )} */}
      {/* {data.max_loss_stage_2 && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("Bi weekly withdrawal")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {(data && data?.bi_weekly_withdrawal) || true ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>
      {/* )} */}

      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {t("News Trading allowed")}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {(data && data?.bi_weekly_withdrawal) || true ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
            {/* {data && ` ${data.profit_target_stage_1 || "5%"}`} */}
          </Typography>
        </div>
      </>

      {/* {data.max_daily_loss && ( */}
      <>
        <div className="card_item">
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {names === "Ninja (1-Step)"
              ? "Trade Copier allowed"
              : names === "Samurai (2-Step)"
              ? "Trade Copier allowed"
              : "Hold over weekends"}
            {/* {t("Hold over weekends")} */}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
            {data && data?.hold_over_the_weekend ? (
              <Tick style={{ width: "18px", height: "18px" }} />
            ) : (
              <Cross style={{ width: "18px", height: "18px" }} />
            )}
          </Typography>
        </div>
      </>
      {/* )} */}
    </Grid>
  );
  const displayText = data.name.split(" ");

  return (
    <CardContent
      className="pricing-card"
      onMouseOver={() => handleMouseOver(data)}
    >
      {/* <Typography className="challenge_name">{data.name}</Typography> */}
      <Typography className="challenge_name">{"2-Step evaluation"}</Typography>
      <Typography
        fontSize="36px"
        fontWeight={900}
        style={{ marginBottom: "40px" }}
      >
        ${displayText[0] && displayText[0]}
      </Typography>
      {/* <Typography mb={1}>{displayText.slice(1).join(" ")}</Typography> */}
      {/* <Divider sx={{ borderColor: "#636363", marginBottom: "24px" }}></Divider> */}
      <Items />
      <CardActions className="fevalBtn">
        <Button
          onClick={() =>
            navigate(`/payments/funding-evaluation/checkout/${data.id}`)
          }
        >
          {t("pay")} {dollarUS(data.price)}
        </Button>
      </CardActions>
    </CardContent>
  );
};

const FundingEvaluation = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { plans, challenges } = useSelector((state) => state.payment.pricing);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getChallengePricing());
  }, [dispatch]);
  const [flooterValue, setFooterValue] = useState([]);

  useEffect(() => {
    console.log("first");
  }, []);

  return (
    <Box component="div">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} component="div">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ borderBottom: "3px solid #131348" }}
        >
          {challenges &&
            challenges.map((item, index) => {
              if (true) {
                return <Tab label={item.name} key={item} />;
              }
            })}
        </Tabs>
      </Box>

      {challenges &&
        challenges.map((challenge, idx) => {
          return (
            <>
              {challenge?.challenges?.length >= 1 && (
                <>
                  <TabPanel
                    value={value}
                    index={idx}
                    component="div"
                    className="opt-tabpanel"
                    sx={{ width: "1300px" }}
                  >
                    <Splide
                      aria-label="My Favorite Images"
                      options={{
                        // type: "Loop",
                        autoplay: true,
                        perPage: 3,
                        perMove: 1,
                        width: "100%",
                        drag: "free",
                        gap: "10px",
                        pauseOnHover: false,
                        breakpoints: {
                          1550: { perPage: 3, gap: "1rem" },
                          1350: { perPage: 2, gap: "1rem" },
                          970: { perPage: 1, gap: "1rem" },
                        },
                      }}
                    >
                      {challenge?.challenges.map((item, index) => (
                        <SplideSlide>
                          {challenge.name === "Sensei (Instant Funding)" && (
                            <Cards
                              data={item}
                              names={challenge?.name}
                              setFooterValue={setFooterValue}
                            />
                          )}
                          {challenge.name === "Ninja (1-Step)" && (
                            <CardsTwo
                              data={item}
                              names={challenge?.name}
                              setFooterValue={setFooterValue}
                            />
                          )}
                          {challenge.name === "Samurai (2-Step)" && (
                            <CardsThree
                              data={item}
                              names={challenge?.name}
                              setFooterValue={setFooterValue}
                            />
                          )}
                        </SplideSlide>
                      ))}
                    </Splide>
                  </TabPanel>
                </>
              )}
            </>
          );
        })}
      <div className="footer_tier">
        <div className="footer_first">
          {/* <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.trading_requirements || "NA %"}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("trading-req")}
            </Typography>
          </div> */}
          {/* <div style={{ width: "calc(100% / 3)" }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                wordBreak: "break-word",
              }}
            >
              {flooterValue.tradable_asset}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("trade-assets")}
            </Typography>
          </div> */}
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.trade_through_news ? t("yes") : t("No")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("trading-news")}
            </Typography>
          </div>
        </div>
        <div className="footer_second">
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {t("yes")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {/* Trading on Weekend */}
              {t("hold-over-wkend")}
            </Typography>
          </div>
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.eas ? t("yes") : t("no")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              EAs
            </Typography>
          </div>
          <div style={{ width: "calc(100% / 3)" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {flooterValue.Hedging_and_Stacking ? t("yes") : t("no")}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#636363",
              }}
            >
              {t("hedging-stacking")}
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FundingEvaluation;
