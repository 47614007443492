import React from 'react'
import upVector from '../../../assets/images/Vector 138.svg'
import groupVector from '../../../assets/images/Group 2.svg'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAffiliateUserStatsRequest } from '../../../store/reducers/affiliateSlice'
import { useAuth } from '../../../hooks/useAuth'
import { useParams } from 'react-router'
import { useSelect } from '@mui/base'
import { useState } from 'react'
function OverviewTab({userId}) {
  const [pushed_leads, setPush_leads] = useState()
  const [conversions, setConversions] = useState()
  const [commission_amount, setCommission_amount] = useState()
  const [click_stats, setClick_state] = useState([])
  const dispatch = useDispatch()
  const idToken = useAuth()
  const params = useParams()
 
  // const userId = params.id
 

  const statsData = useSelector((state) => state.affiliateSlice.userStats)
 


  useEffect(() => {
    setPush_leads(statsData.pushed_leads)
    setConversions(statsData.conversions)
    setCommission_amount(statsData.commission_amount)
    setClick_state(statsData.click_stats)
  }, [statsData])

 
 
 
  return (
    <>
      <div className="container">
        <div className="box">
          <div className="box-wrapper">
            <div className="upper-text">Conversion</div>
            <div className="upper-number">{conversions}</div>
            {/* <div className="percent-icon">
              <div className="groupChild">
                <img className="groupItem" src={upVector} />
              </div>

              <div className="div1">+2.53%</div>
            </div> */}
          </div>
        </div>
        <div className="box">
          <div className="box-wrapper">
            <div className="upper-text">Push leads</div>
            <div className="upper-number">{pushed_leads}</div>
            {/* <div className="percent-icon">
              <div className="groupChild-grey">
                <img className="groupItem-group" src={groupVector} />
              </div>

              <div className="div1">+2.53%</div>
            </div> */}
          </div>
        </div>
        <div className="box">
          <div className="box-wrapper">
            <div className="upper-text">Commission Amount</div>
            <div className="upper-number">{commission_amount}</div>
            {/* <div className="percent-icon">
              <div className="groupChild-yellow">
                <img className="groupItem" src={upVector} />
              </div>

              <div className="div1">+2.53%</div>
            </div> */}
          </div>
        </div>
        {/* <div className="box" style={{ filter: 'blur(3px)' }}>
          <div className="box-wrapper">
            <div className="upper-text">Clicks</div>
            <div className="upper-number">123455</div>
        
          </div>
        </div>
        <div className="box" style={{ filter: 'blur(3px)' }}>
          <div className="box-wrapper">
            <div className="upper-text">New Users</div>
            <div className="upper-number-user">+15.41%</div>
          </div>
        </div>
        <div className="box" style={{ filter: 'blur(3px)' }}>
          <div className="box-wrapper">
            <div className="upper-text">New Users</div>
            <div className="upper-number-user-red">56,345</div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default OverviewTab
