import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material'

import BpCheckbox from '../../../components/Form/CheckBox'
import {
  applyCouponCode,
  getChallengeOptions,
  paymentGateway,
} from '../../../store/reducers/payment'
import { dollarUS } from '../../../utilities/formatter'
import { returnErrors } from '../../../store/reducers/error'
import { useLoader } from '../../../hooks/useLoader'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'

import useMobile from '../../../hooks/useMobile'
import {
  NEW_USER_STRIPE_CANCEL_URL,
  NEW_USER_STRIPE_SUCCESS_URL,
} from '../../../constants/payments'

const summaryStyle = {
  background: 'rgba(66, 66, 109, 0.3)',
  borderRadius: '24px',
  padding: '1.5rem',
  '& label, h5, div': {
    flexBasis: '48%',
  },
}

const summaryItemCtn = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const titleStyle = {
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '140%',
  letterSpacing: '0.004em',
  color: '#FFFFFF',
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  // height: '90vh',
  display: 'flex',
  flexDirection: 'column',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const TnCContent = () => {
  const isMobile = useMobile()
  const language = useSelector(
    (state) => state.settings.account_settings.language,
  )

  return language === 'en' ? (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: isMobile ? '129.4118%' : '75vh',
        paddingBottom: 0,
        boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
        overflow: 'hidden',
        borderRadius: '8px',
        willChange: 'transform',
      }}
    >
      <iframe
        loading="lazy"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 'none',
          padding: 0,
          margin: 0,
        }}
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFmWTA4z_c&#x2F;view?embed"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
      ></iframe>
    </div>
  ) : (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: isMobile ? '129.4118%' : '75vh',
        paddingBottom: 0,
        boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
        marginTop: '1.6em',
        marginBottom: '0.9em',
        overflow: 'hidden',
        borderRadius: '8px',
        willChange: 'transform',
      }}
    >
      <iframe
        title="tnc"
        loading="lazy"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 'none',
          padding: 0,
          margin: 0,
        }}
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFmWTA4z_c&#x2F;view?embed"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
      ></iframe>
    </div>
  )
}

const PaymentForm = ({ newEmail, paymentMethod }) => {
  const [accBalance, setAccBalance] = useState('')
  const [planType, setPlanType] = useState('')
  const [price, setPrice] = useState(0)
  const [tncCheck, setTncCheck] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(false)
  const disptach = useDispatch()
  const setLoader = useLoader()
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [disablePayBtn, setDisablePayBtn] = useState(false)

  // Coupon states
  const [coupon, setCoupon] = useState('')
  const [couponError, setCouponError] = useState('')
  const [discount, setDiscount] = useState(0)
  const [disableCouponInput, setDisableCouponInput] = useState(false)

  // TnC Modal states
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { challenge_options } = useSelector((state) => state.payment)

  useEffect(() => {
    if (id && challenge_options) {
      if (challenge_options.length) {
        for (let i = 0; i < challenge_options.length; i++) {
          const challenge = challenge_options[i]
          if (String(challenge.id) === String(id)) {
            setAccBalance(challenge.name)
            setSelectedPlan(challenge.id)
            setPlanType(challenge.plan_type)
            break
          }
        }
      }
    }
  }, [id, challenge_options])

  // useEffect(() => {
  //   disptach(getChallengeOptions())
  // }, [disptach])

  const totalOrderAmt = useMemo(() => {
    return price - discount
  }, [price, discount])

  useEffect(() => {
    if (selectedPlan) {
      validateCouponCode(coupon)
      challenge_options.forEach((item) => {
        if (item.id === selectedPlan) {
          setPrice(item.price)
        }
      })
    }
  }, [selectedPlan, challenge_options])

  const accBalanceOptions = useMemo(() => {
    const plans = []
    if (challenge_options.length)
      for (let i = 0; i < challenge_options.length; i++) {
        const element = challenge_options[i]
        if (planType === element.plan_type) plans.push(element)
      }
    return plans
  }, [planType, challenge_options])

  const fundingEvaluation = useMemo(() => {
    const options = []
    if (challenge_options.length)
      for (let i = 0; i < challenge_options.length; i++) {
        const element = challenge_options[i].plan_type
        if (!options.filter((item) => item === element).length)
          options.push(element)
      }
    return options
  }, [challenge_options])

  const handleAccBalanceChange = (e) => {
    if (challenge_options.length) {
      const plan = challenge_options.filter(
        (item) => item.id === e.target.value,
      )
      if (plan.length) {
        setAccBalance(plan[0].name)
        setSelectedPlan(e.target.value)
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!selectedPlan) {
      disptach(returnErrors('pls-slt-plan-acc-bal', 'error'))
      return
    }
    if (!tncCheck) {
      disptach(returnErrors('accept-tnc', 'error'))
      return
    }
    if (newEmail === '') {
      return
    }
    setLoader(true)
    const payload = { challenge_id: selectedPlan }
    if (coupon) payload['coupon_code'] = coupon
    payload['success_url'] = NEW_USER_STRIPE_SUCCESS_URL
    payload['cancel_url'] = NEW_USER_STRIPE_CANCEL_URL
    payload['email'] = newEmail
    payload['payment_method'] = paymentMethod
    payload['user_path'] = 'new_userpay'
    if (paymentMethod === 'coinbase') {
      disptach(paymentGateway(payload, paymentMethod))
        .then((res) => {
          setLoader(false)
          if (res.url) {
            localStorage.setItem('payMethod', paymentMethod)
            window.location.replace(res.url)
          } else {
            navigate('/overview')
          }
        })
        .catch((error) => setLoader(false))
    }
    if (paymentMethod === 'paystack') {
      disptach(paymentGateway(payload, paymentMethod))
        .then((res) => {
          setLoader(false)
          if (res.url) {
            localStorage.setItem('payMethod', paymentMethod)
            window.location.replace(res.url)
          } else {
            navigate('/overview')
          }
        })
        .catch((error) => setLoader(false))
    } else if (paymentMethod === 'stripe') {
      disptach(paymentGateway(payload))
        .then((res) => {
          setLoader(false)

          if (res.url) {
            window.location.replace(res.url)
          } else {
            navigate('/overview')
          }
        })
        .catch((error) => setLoader(false))
    }
  }

  const validateCouponCode = (coupon = null) => {
    setDisablePayBtn(true)
    const payload = { challenge: selectedPlan }
    if (coupon) payload['coupon_code'] = coupon
    disptach(applyCouponCode(payload))
      .then((res) => {
        setCouponError('')
        setDisablePayBtn(false)
        setDiscount(res.data.discount)
        if (res.data.disable_input) setDisableCouponInput(true)
        if (res.data.coupon) setCoupon(res.data.coupon)
      })
      .catch((error) => {
        setDisablePayBtn(false)
        setCouponError(error?.message)
      })
  }

  return (
    <Grid container item lg={4}>
      <Grid item xs={12} sx={summaryStyle}>
        <Typography sx={titleStyle} mb={3} component="h3">
          {t('summary')}
        </Typography>
        <FormGroup>
          <FormControl sx={summaryItemCtn}>
            <Typography component={'label'} width="fit-content">
              {t('funding-eval')}
            </Typography>
            <Typography
              mb={3}
              component="p"
              sx={{
                width: '48%',
                color: '#fff',
                marginBottom: '0.5rem',
                marginTop: '0.5rem !important',
              }}
            >
              {planType}
            </Typography>
            {/* <Select
              displayEmpty
              value={planType}
              onChange={(e) => {
                setPlanType(e.target.value)
                setAccBalance('')
                setSelectedPlan('')
              }}
              input={<Input variant="standard" />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return false
                }
                return selected
              }}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                color: '#fff',
                marginBottom: '0.5rem',
                marginTop: '0.5rem !important',
              }}
            >
              {fundingEvaluation.map((option) => (
                <MenuItem key={option} value={option} width="fit-content">
                  {option}
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>
          <FormControl sx={summaryItemCtn}>
            <Typography component={'label'} width="fit-content">
              {t('account-balance-sm')}
            </Typography>
            <Typography
              mb={3}
              component="p"
              sx={{
                width: '48%',
                color: '#fff',
                marginBottom: '0.5rem',
                marginTop: '0.5rem !important',
              }}
            >
              {accBalance}
            </Typography>
            {/* <Select
              displayEmpty
              value={accBalance}
              onChange={handleAccBalanceChange}
              input={<Input variant="standard" />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return false
                }
                return selected
              }}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                color: '#fff',
                marginBottom: '0.5rem',
                marginTop: '0.5rem !important',
              }}
            >
              {accBalanceOptions.map((option) => (
                <MenuItem key={option.id} value={option.id} width="fit-content">
                  {option.name}
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>
          <FormControl sx={summaryItemCtn}>
            <Typography component={'label'}>{t('provider')}</Typography>
            <Typography component={'h5'} textAlign="left">
              Mac&Gray
            </Typography>
          </FormControl>
          <FormControl sx={summaryItemCtn}>
            <Typography component={'label'}>{t('coupon-code')}</Typography>
            <Box component={'div'} display="flex" flexDirection={'column'}>
              <Input
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                sx={{ color: 'white', marginTop: 0 }}
                onBlur={(e) => {
                  if (e.target.value) {
                    validateCouponCode(coupon)
                  }
                }}
                disabled={disableCouponInput}
                error={couponError ? couponError : false}
              />
              <FormHelperText error={true}>
                {couponError ? couponError : false}
              </FormHelperText>
            </Box>
          </FormControl>
          <FormControl sx={summaryItemCtn}>
            <Typography component={'label'}>{t('platform')}</Typography>
            <Typography component={'h5'} textAlign="left">
              Mac&Gray
            </Typography>
          </FormControl>
          <FormControl sx={{ ...summaryItemCtn, margin: '0.5rem 0' }}>
            <Typography component={'label'}>{t('price')}</Typography>
            <Typography component={'h5'} textAlign="left">
              {dollarUS(price)}
            </Typography>
          </FormControl>
          <FormControl sx={{ ...summaryItemCtn, margin: '0.5rem 0' }}>
            <Typography component={'label'}>{t('discount')}</Typography>
            <Typography component={'h5'}>{dollarUS(discount)}</Typography>
          </FormControl>
          <FormControl sx={{ ...summaryItemCtn, margin: '0.5rem 0' }}>
            <Typography component={'label'}>{t('order-total')}</Typography>
            <Typography component={'h5'}>{dollarUS(totalOrderAmt)}</Typography>
          </FormControl>
          <Grid
            container
            sx={{
              background: 'rgba(255, 255, 255, 0.02)',
              borderRadius: '12px',
              padding: '15px 12px',
              '& .to-be-paid b': {
                color: '#9292AB',
              },
              '& .to-be-paid-label': {
                borderRight: '1px solid',
              },
            }}
          >
            <Grid
              item
              xs={12}
              className="to-be-paid-label"
              sx={{
                borderRight: '1px solid #636363',
                marginRight: '10px',
                flexBasis: '48%',
              }}
            >
              <b>{t('to-be-paid')}</b>
            </Grid>
            <Grid item xs={12} className="to-be-paid">
              <b>{dollarUS(totalOrderAmt)}</b>
            </Grid>
          </Grid>
          <Grid container mt={1.25}>
            <Grid item xs={1.5}>
              <BpCheckbox
                checked={tncCheck}
                onChange={() => setTncCheck(!tncCheck)}
                inputProps={{ 'aria-label': 'accept terms and conditions' }}
              />
            </Grid>
            <Grid onClick={handleOpen} item xs={10.5} className="tnc-ctn">
              <Typography
                className="tnc-link"
                component={'label'}
                sx={{ flexBasis: '100%' }}
              >
                {t('tnc')}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Button
              onClick={handleSubmit}
              disabled={disablePayBtn}
              className="pay-btn"
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
            >
              {t('pay')} {dollarUS(totalOrderAmt)}
            </Button>
          </Grid>
        </FormGroup>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('tnc')}
          </Typography>
          <Typography id="tnc_modal" sx={{ mt: 2 }} component={'p'}>
            <TnCContent />
          </Typography>
          <Button
            sx={{ maxWidth: '200px', alignSelf: 'center' }}
            onClick={handleClose}
            variant="outlined"
            color={'secondary'}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Grid>
  )
}

export default PaymentForm
