import { axiosInstance } from "../../Axios";
import { tokenConfig } from "./auth";
import { returnErrors } from "./error";
import { returnMessages } from "./message";

const initialState = {
  challenge_options: [],
  billing_info: null,
  stripe_id: "",
  coinbase_id: "",
  history: [],
  pricing: {
    challenges: [],
  },
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAYMENT_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    case "SET_CHALLENGE_OPTIONS":
      return {
        ...state,
        challenge_options: action.payload,
      };
    case "SET_BILLING_INFO":
      return {
        ...state,
        billing_info: action.payload,
      };
    case "SET_STRIPE_ID":
      return {
        ...state,
        stripe_id: action.payload,
      };
    case "SET_COINBASE_ID":
      return {
        ...state,
        coinbase_id: action.payload,
      };
    case "REMOVE_STRIPE_ID":
      return {
        ...state,
        stripe_id: "",
      };
    case "REMOVE_COINBASE_ID":
      return {
        ...state,
        coinbase_id: "",
      };
    case "SET_PRICING":
      return {
        ...state,
        pricing: { challenges: action.payload },
      };
    case "CLEAR_FUN_EVALUTION":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const setChallengeOptions = (payload) => ({
  type: "SET_CHALLENGE_OPTIONS",
  payload,
});

const setBillingInfo = (payload) => ({
  type: "SET_BILLING_INFO",
  payload,
});

export const setPaymentStripeId = (payload) => ({
  type: "SET_STRIPE_ID",
  payload,
});

export const setPaymentCoinbaseId = (payload) => ({
  type: "SET_COINBASE_ID",
  payload,
});

export const removePaymentStripeId = () => ({
  type: "REMOVE_STRIPE_ID",
});

export const removePaymentCoinbaseId = () => ({
  type: "REMOVE_COINBASE_ID",
});

const setPaymentHistory = (payload) => ({
  type: "SET_PAYMENT_HISTORY",
  payload,
});

const setPricing = (payload) => ({
  type: "SET_PRICING",
  payload,
});
export const clearFunding_evalution = (payload) => ({
  type: "CLEAR_FUN_EVALUTION",
});

export const getChallengePricing = () => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.get(
      "/accounts/v2/challenges/",
      tokenConfig(getState)
    );
    if (status > 399) throw data;
    dispatch(setPricing(data));
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    throw error.response.data;
  }
};

export const getChallengeOptions = () => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.get(
      "/payment/funding-evaluation/options/",
      tokenConfig(getState)
    );
    if (status > 399) throw data;
    dispatch(setChallengeOptions(data));
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    throw error.response.data;
  }
};

export const getBillingInfo = () => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.get(
      "/payment/billing-info/",
      tokenConfig(getState)
    );
    if (status > 399) throw data;
    dispatch(setBillingInfo(data));
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    throw error.response.data;
  }
};

export const updateBillingInfo = (payload) => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.post(
      "/payment/billing-info/",
      payload,
      tokenConfig(getState)
    );
    if (status > 399) throw data;
    if (data.message) {
      dispatch(returnMessages(data.message, "success"));
      return;
    }
    dispatch(returnMessages("changes-saved", "success"));
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    throw error.response.data;
  }
};

export const paymentGateway =
  (payload, paymentMethod = "") =>
  async (dispatch, getState) => {
    try {
      if (payload.user_path) {
        const { data, status } = await axiosInstance.post(
          "/payment/stripe/session/",
          payload
        );
        if (status > 399) throw data;
        if (data.data && data.data.payment_id) {
          if (paymentMethod) {
            dispatch(setPaymentCoinbaseId(data.data.payment_id));
            return data;
          } else {
            dispatch(setPaymentStripeId(data.data.payment_id));
            return data;
          }
        }
        if (data.message) {
          dispatch(returnMessages(data.message, "success"));
          return data;
        }
      } else {
        const { data, status } = await axiosInstance.post(
          "/payment/stripe/session/",
          payload,
          tokenConfig(getState)
        );
        if (status > 399) throw data;
        if (data.data && data.data.payment_id) {
          if (paymentMethod) {
            dispatch(setPaymentCoinbaseId(data.data.payment_id));
            return data;
          } else {
            dispatch(setPaymentStripeId(data.data.payment_id));
            return data;
          }
        }
        if (data.message) {
          dispatch(returnMessages(data.message, "success"));
          return data;
        }
      }
    } catch (error) {
      dispatch(
        returnErrors(error.response.data?.detail, error.response.status)
      );
      dispatch(
        returnErrors(error.response.data?.message, error.response.status)
      );
      throw error.response.data;
    }
  };

export const applyCouponCode = (payload,dispatch) => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.post(
      "/payment/apply/coupon/",
      payload,
      tokenConfig(getState)
    );
    if (status > 399) throw data;
    return data;
    // if(data.message){
    //     dispatch(returnMessages(data.message,"success"));
    // }
  } catch (error) {
    dispatch(returnErrors(error.response.data?.message, error.response.status))
    throw error.response.data;
  }
};

export const checkPaymentStatus =
  (paymentMethod = "") =>
  async (dispatch, getState) => {
    try {
      if (paymentMethod) {
        const { coinbase_id } = getState().payment;
        if (!coinbase_id) throw Error("invalid-coinbase-session");
        const { data, status } = await axiosInstance.post(
          "payment/api/coinbase-status/",
          { payment_id: coinbase_id },
          tokenConfig(getState)
        );
        if (status > 399) throw data;
        dispatch(removePaymentCoinbaseId());
        return true;
      } else {
        const { stripe_id } = getState().payment;
        if (!stripe_id) throw Error("invalid-stripe-session");
        const { data, status } = await axiosInstance.post(
          "payment/stripe/status/",
          { payment_id: stripe_id },
          tokenConfig(getState)
        );
        if (status > 399) throw data;
        dispatch(removePaymentStripeId());
        return true;
      }
    } catch (error) {
      if (paymentMethod) {
        dispatch(removePaymentCoinbaseId());
        // dispatch(returnErrors(error.response.data?.detail, error.response.status))
        throw error;
        // throw(error.response.data)
      } else {
        dispatch(removePaymentCoinbaseId());
        // dispatch(returnErrors(error.response.data?.detail, error.response.status))
        throw error;
        // throw(error.response.data)
      }
    }
  };

export const getPaymentHistory = () => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.get(
      "/payment/history/",
      tokenConfig(getState)
    );
    if (status > 399) throw data;
    dispatch(setPaymentHistory(data));
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    throw error.response.data;
  }
};

export default paymentReducer;
