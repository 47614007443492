import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'
import { getPaymentHistory } from '../../../store/reducers/payment';
import VectorImage from "../../../assets/images/Vector.svg"
import {dollarUS} from "../../../utilities/formatter"
import CopyToClipboard from '../../../components/Reusable/CopyToClipboard';
import './style.scss';
import ReactGA from "react-ga"

export const formatDate = (date) => {
  if (date === null) return "";
  const DateObj = new Date(date);
  const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
  ];
  return `${DateObj.getDate()} ${
      months[DateObj.getMonth()]
  }, ${DateObj.getFullYear()}`;
  };

const Status = ({params}) => {
  const {value} = params;
  const statusColor = value.toLowerCase() === 'succeeded'?'#37C976':'#FD504F';
  const StartDot = (<span
   style={{background: statusColor, width:'10px', height: '10px', borderRadius: '50%'}}
   ></span>)
  return (<Button variant="text" startIcon={StartDot} sx={{fontWeight: '500', fontSize: '16px', textTransform:'capitalize', color: statusColor}}>{value}</Button>)
}

const PaymentHistory = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const dispatch = useDispatch();
  const { history } = useSelector(state => state.payment);

  useEffect(()=>{
    dispatch(getPaymentHistory())
  },[dispatch])

  return (
    <Box
      id="transaction-history"
      sx={{
        height: 400, width: '100%', marginTop: '30px',
        marginRight: '5px',
        '& .super-app-theme--header': {
            '& .MuiDataGrid-columnHeaderTitle': {
                fontFamily: 'Urbanist',
                color: '#8B8E93',
                fontWeight: '400',
                fontSize: '14px'
            },
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
        },
        '& .super-app-theme--cell': {
            "& .MuiDataGrid-cellContent": {
                fontFamily: 'Urbanist',
                fontWeight: '500',
                fontSize: '16px'
            }

        }}}>
      <DataGrid
        columns={[
                  { field: 'funding_evaluation', headerName: 'Funding Evaluation', width: 130, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', renderCell: (params)=>{
                    const statusColor = params.row.payment_status==='unpaid'? 'error':'success';
                    return(
                      <>
                        <div className={`status ${statusColor}`}></div><span className='val'>{params.value}</span>
                      </>
                    )
                  } },
                  { field: 'date', headerName: 'Date', width: 130, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', renderCell: (params) => {
                    return (
                      <>
                        {params.value? formatDate(params.value):false}
                      </>
                    )
                  } },
                  { field: 'payment_id', headerName: 'Transaction ID', width: 300, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', renderCell: (params) => {
                    return (
                      <span><CopyToClipboard value={params.value}/>{params.value}</span>
                    )
                  } },
                  { field: 'account_balance', headerName: 'Account Balance', width: 130, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', renderCell: (params) => {
                    return (
                      <>
                        {params.value? dollarUS(params.value):false}
                      </>
                    )
                  } },
                  { field: 'amount', headerName: 'Amount', width: 130, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', renderCell: (params) => {
                    return (
                      <>
                        {params.value? dollarUS(params.value):false}
                      </>
                    )
                  } },
                  { field: 'invoice', headerName: 'Invoice', width: 80, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', renderCell: (params) => {
                          return (
                              <>
                                  {params.value && params.value!=='Not Created'?
                                      <a target="_blank" rel="noopener noreferrer" href={params.value}>
                                          <img src={VectorImage} style={{ marginLeft: '5px'}} alt='' />
                                      </a>
                                  :false}

                              </>
                          );
                      } },
                  {
                      field: 'payment_status',
                      headerName: 'Status', 
                      editable: false,
                      width: 130, sortable: false, headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell',
                      renderCell: (params) => (<Status params={params} />),
                  }
                ]}
                rows={history}
                sx={{
                    border: 'none',
                    "& .MuiDataGrid-columnHeaders": {
                        border: 'none',
                    },
                    "& .MuiDataGrid-cell": {
                        border: 'none',
                    },
                    "& .MuiDataGrid-footerContainer ": {
                        border: 'none',
                    },
                    "& .MuiDataGrid-row": {
                        border: "1px solid rgba(255, 255, 255, 0.02)",
                        borderRadius: "12px"
                    }
                }}
      />
    </Box>
  )
}

export default PaymentHistory
