import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as Person2OutlinedIcon } from "../../assets/images/Person2OutlinedIcon.svg";
import { ReactComponent as HttpsOutlinedIcon } from "../../assets/images/HttpsOutlinedIcon.svg";
import { ReactComponent as SettingsOutlinedIcon } from "../../assets/images/SettingsOutlinedIcon.svg";
import { ReactComponent as BillingIcon } from "../../assets/images/billingIcon.svg";
import { ReactComponent as RequestIcon } from "../../assets/images/requestcon.svg";
import { ReactComponent as EditProfileIcon } from "../../assets/images/EditProfileIcon.svg";
import { ReactComponent as SelectorIcon } from "../../assets/images/planSelectorIcon.svg";
import { TabPanel } from "../../components/Reusable/TabPanel";
import ReactGA from "react-ga";
import { useEffect } from "react";
import MyProfile from "./MyProfile";
import ChangePassword from "./ChangePassword";
import AccountSettings from "./AccountSettings";
import "./style.scss";
import UploadDocuments from "./UploadDocuments";
import AddAddress from "./AddAddress";
import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import { AddCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Payout from "./Payout";
import { baseUrl } from "../../utilities/apis/apis";
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    className: "vert-id",
  };
}

const TabLabel = ({ label }) => {
  return (
    <Typography className="ver-tab-label" component={"span"}>
      {label}
    </Typography>
  );
};

const Settings = () => {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [tab1VertValue, setTab1VertValue] = React.useState(0);
  const [tab2VertValue, setTab2VertValue] = React.useState(0);
  const [showAccType, setShowAccType] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0, 0);
  };

  const handleTab1VertValue = (event, newValue) => {
    setTab1VertValue(newValue);
  };

  const handleTab2VertValue = (event, newValue) => {
    setTab2VertValue(newValue);
  };

  const handleOpenModal = (ele) => {
    setOpen(true);
    if (ele) {
      navigate("/settings", { state: { data: ele } });
    }
  };

  const handleCloseModal = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
  };

  const handleScrollToDiv = (href) => {
    const targetDiv = document.getElementById(href.replace("#", ""));
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const plans = ["General Information", "Verification"];
  // const plans = ["General Information", "Verification"];

  const idToken = useAuth();
  const [address, setAddress] = useState([]);
  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };
    axios
      .get(`${baseUrl}/userprofile/address/`, config)
      .then((res) => setAddress(res.data))
      .catch((err) => console.log(err));
  }, [open]);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="tabination"
      >
        {plans &&
          plans.map((item, index) => {
            return <Tab label={item} key={item} />;
          })}
      </Tabs>

      {plans &&
        plans.map((item, idx) => {
          if (value === idx)
            return (
              <>
                <TabPanel value={item} index={item} component="div">
                  <div
                    id="settings-section"
                    className="mainBGcolor"
                    // sx={{flexGrow: 1, display: "flex", m: 3}}
                  >
                    <TabPanel
                      value={value}
                      index={0}
                      className="setting_container"
                    >
                      <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                        gap={2}
                        className=""
                      >
                        <Grid item lg={2.7} className="nopadding">
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tab1VertValue}
                            onChange={handleTab1VertValue}
                            aria-label="Vertical tabs example"
                            className="border-nopadding vert-tab"
                          >
                            <Tab
                              component="a"
                              href="#my-profile"
                              label={<TabLabel label={t("my-profile")} />}
                              icon={<Person2OutlinedIcon />}
                              {...a11yProps(0)}
                              onClick={() => handleScrollToDiv("#my-profile")}
                              style={{ width: "100% !important" }}
                            />
                            <Tab
                              component="a"
                              href="#pswd-change"
                              label={<TabLabel label={t("password")} />}
                              icon={<HttpsOutlinedIcon />}
                              {...a11yProps(1)}
                              onClick={() => handleScrollToDiv("#pswd-change")}
                              style={{ width: "100% !important" }}
                            />
                            <Tab
                              component="a"
                              href="#account-settings"
                              label={<TabLabel label={t("account-settings")} />}
                              icon={<SettingsOutlinedIcon />}
                              {...a11yProps(2)}
                              onClick={() =>
                                handleScrollToDiv("#account-settings")
                              }
                              style={{ width: "100% !important" }}
                            />
                          </Tabs>
                        </Grid>
                        <Grid item lg={9} className="border-box">
                          <MyProfile />
                          <div className="profilesepLine"></div>
                          <ChangePassword />
                          <div className="profilesepLine"></div>
                          <AccountSettings />
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                        gap={2}
                      >
                        <Grid
                          item
                          lg={2.7}
                          md={12}
                          sm={12}
                          className="nopadding"
                        >
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tab2VertValue}
                            onChange={handleTab2VertValue}
                            aria-label="Vertical tabs example"
                            className="border-nopadding vert-tab"
                            justifyContent={"space-between"}
                          >
                            <Tab
                              component="a"
                              href="#my-profile"
                              label={<TabLabel label={t("billing")} />}
                              icon={<BillingIcon />}
                              {...a11yProps(0)}
                              onClick={() => setShowAccType(false)}
                            />
                            <Tab
                              component="a"
                              href="#upload-docs"
                              label={
                                <TabLabel label={t("request-to-change")} />
                              }
                              icon={<RequestIcon />}
                              {...a11yProps(1)}
                              onClick={() => setShowAccType(true)}
                            />
                          </Tabs>
                        </Grid>
                        <Grid item lg={9} md={12} className="nopadding">
                          <div
                            className="border-box"
                            style={{ marginBottom: "32px" }}
                          >
                            <MyProfile />

                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="space-between"
                              padding="10px 0"
                              alignItems="center"
                            >
                              <Grid item lg={8} md={6} sm={6}>
                                <Box component={"h3"}>
                                  {t("Billing Information")}
                                </Box>
                              </Grid>

                              <Grid item lg={4} md={6} sm={6} textAlign={"end"}>
                                <Button
                                  variant="outlined"
                                  type="submit"
                                  endIcon={<AddCircle />}
                                  onClick={() => handleOpenModal("addaddress")}
                                  sx={{
                                    color: "#E7E8EA !important",
                                    opacity: "1 !important",
                                    border: "1px solid #5E6670 !important",
                                    padding: "7px 10px",
                                    borderRadius: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Add Address
                                </Button>
                                {open ? (
                                  <AddAddress
                                    open={open}
                                    onClose={handleCloseModal}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                            {showAccType ? (
                              address?.map((ele, ind) => (
                                <Grid
                                  container
                                  xs={12}
                                  justifyContent="space-between"
                                  alignItems="center"
                                  border="1px solid #262b3a"
                                  padding="10px"
                                  borderRadius="20px"
                                  marginBottom="10px"
                                >
                                  <Grid item lg={9} xs={10} md={10}>
                                    <Typography className="billing-address">
                                      {ele.apartment_no}&nbsp;
                                      {ele.street_address}&nbsp;
                                      {ele.city}&nbsp;
                                      {ele.country}&nbsp;
                                      {ele.state}&nbsp;
                                      {ele.zip_code}
                                    </Typography>
                                    <Typography>Billing Address</Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={3}
                                    xs={2}
                                    md={2}
                                    textAlign={"end"}
                                  >
                                    <p
                                      className="outlined-icon-btn"
                                      variant="outlined"
                                      type="submit"
                                      onClick={() => handleOpenModal(ele)}
                                      style={{
                                        color: "#E7E8EA !important",
                                        opacity: "1 !important",
                                        border: "1px solid #5E6670 !important",
                                        cursor: "pointer",
                                        padding: "5px 20px ",
                                      }}
                                    >
                                      <EditProfileIcon />
                                    </p>
                                    {open ? (
                                      <AddAddress
                                        value={ele}
                                        open={open}
                                        onClose={handleCloseModal}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                </Grid>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="border-box">
                            {showAccType ? (
                              <Grid item xs={12}>
                                <Box component={"h3"} margin={0}>
                                  Account Type
                                </Box>
                                <Typography className="acc-type">
                                  <SelectorIcon /> Saving
                                </Typography>
                                <Typography>
                                  <SelectorIcon /> Current Account
                                </Typography>
                              </Grid>
                            ) : (
                              <UploadDocuments />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={2} className="nopadding">
                      <Payout />
                    </TabPanel>
                  </div>
                </TabPanel>
              </>
            );
          return false;
        })}
    </>
  );
};

export default Settings;
