import React, { useRef, useState } from "react";
import "../ResetPassword/style.scss";

import LoginBackIMG from "../../../assets/images/mac/LoginBack.png";
import LogoIMG from "../../../assets/images/mac/Logo.png";
import bgIMG from "../../../assets/images/mac/bg.png";
import LoginLIMG from "../../../assets/images/mac/fp.png";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";

const EnterOtp = () => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(["", "", "", ""]);

  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (!isNaN(value) && value !== "") {
      setOtp([...otp.map((digit, idx) => (idx === index ? value : digit))]);
      if (value !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (index >= 0) {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = "";
          return newOtp;
        });
        inputRefs[index].current.focus();
      } else if (otp[0] === "" && index === 0) {
        setOtp((prevOtp) => {
          const newOtp = ["", ...prevOtp.slice(1)];
          return newOtp;
        });
        inputRefs[0].current.focus();
      }
    }
  };

  return (
    <div className="mainLogin">
      <img src={bgIMG} alt="" className="bgIMG" />

      <div className="mainLogin-Con">
        <div className="mainLogin-M">
          <img className="logoImg" src={LogoIMG} alt="" />
          <img className="loginLI fp" src={LoginLIMG} alt="" />
        </div>
        <div className="mainLogin-Overlay">
          <img className="LoginBackIMG" src={LoginBackIMG} alt="" />
          <div className="loginFormTop">
            <h1>Enter OTP</h1>
          </div>
          <form id="forgot-pswd-form" style={{ paddingTop: "20px" }}>
            <h2 style={{ position: "relative" }}>Enter 4 digit code</h2>
            <div
              style={{ display: "flex", gap: "1rem" }}
              className="otp-input-container"
            >
              {inputRefs.map((inputRef, index) => (
                <TextField
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={otp[index]}
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      width: "3rem",
                      textAlign: "center",
                      backgroundColor: "white",
                      color: "white",
                      borderRadius: "5px",
                      overflow: "hidden",
                    },
                  }}
                  inputRef={inputRef}
                  className="otp-input"
                />
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <WatchLaterOutlinedIcon />
                &nbsp;0.59
              </div>
              <div>Resend OTP</div>
            </div>

            <div onClick={""} type="submit" className="loginBtn">
              {t("submit")}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnterOtp;
