import React, { useEffect, useRef, useState } from "react";

import countryCodes from "country-codes-list";
import { ReactComponent as EditProfileIcon } from "../../assets/images/EditProfileIcon.svg";

import { Box, Button, Grid, Typography } from "@mui/material";
import SelectInput from "../../components/Form/SelectInput";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/Form/TextInput";
import { getProfile, saveProfile } from "../../store/reducers/settings";

const MyProfile = () => {
  const [editForm, setEditForm] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [city, setCity] = useState("");
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState("");

  const { t } = useTranslation();

  const fileref = useRef(null);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.settings.profile);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleImgUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
    var file = fileref.current.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setPreview(reader.result);
    };
  };

  useEffect(() => {
    setMyCountryNumberCodes(
      countryCodes.customList("countryNameEn", "+{countryCallingCode}")
    );
    setEditForm(false);
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    const {
      photo,
      email,
      first_name,
      last_name,
      country_code,
      country,
      contact,
      city,
    } = profile;

    setPhoto(photo);
    setEmail(email);
    setFirstName(first_name);
    setLastName(last_name);
    setCountryCode(country_code);
    setCountry(country);
    setContact(contact);
    setCity(city);
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();

    if (photo !== null && typeof photo === "object") {
      form_data.append("photo", photo, photo["name"]);
    }
    form_data.append("first_name", firstName);
    form_data.append("last_name", lastName);
    form_data.append("country", country);
    form_data.append("country_code", countryCode);
    form_data.append("contact", contact);
    form_data.append("city", city);

    dispatch(saveProfile(form_data));
    setEditForm(false);
  };

  /* START Country Codes */
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryNamesArray, setCountryNamesArray] = useState([]);
  const [myCountryNumberCodes, setMyCountryNumberCodes] = useState([]);

  useEffect(() => {
    const country_names = Object.keys(myCountryNumberCodes);
    setCountryNamesArray(country_names);
  }, [myCountryNumberCodes]);
  /* END Country Codes */

  return (
    <>
      <Grid item id="my-profile" spacing={2}>
        <Grid item xs={12}>
          <Typography component={"h3"} className="profile-heading">
            {t("my-profile")}
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent="space-between"
          xl={12}
        >
          <Grid
            container
            item
            lg={9}
            md={6}
            sm={6}
            alignItems={"center"}
            justifyContent={"start"}
            spacing={2}
          >
            <Grid lg={2} md={3} sm={12} item className="profile-img-ctn">
              {preview ? (
                <img src={preview} width="70" height="70" alt="" />
              ) : photo ? (
                <img src={photo} width="70" height="70" alt="" />
              ) : (
                <Button title="Profile Image">
                  <AddPhotoAlternateIcon className="profile-img" />
                </Button>
              )}
              {editForm ? (
                <input
                  type="file"
                  name="photo"
                  ref={fileref}
                  onChange={handleImgUpload}
                />
              ) : (
                false
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Box
                component={"h3"}
                m={0}
                mb={0.5}
              >{`${firstName} ${lastName}`}</Box>
              <Box component={"p"} m={0}>
                {email}
              </Box>
            </Grid>
          </Grid>
          <Grid item lg={3} sm={6} md={6} textAlign={"end"}>
            {editForm ? (
              <Button variant="outlined" type="submit" onClick={handleSubmit}>
                {t("save-changes")}
              </Button>
            ) : (
              <Button
                className="outlined-icon-btn"
                variant="outlined"
                onClick={() => setEditForm(true)}
                endIcon={<EditProfileIcon />}
              >
                {t("edit-profile")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2} className="profile-form">
          <Grid item xs={12} md={6} lg={4}>
            <TextInput
              label={t("first-name")}
              placeholder={t("first-name")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={!editForm}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextInput
              label={t("last-name")}
              placeholder={t("last-name")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={!editForm}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextInput
              label="Email"
              disabled
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid className="op-contact-ctn" item container xs={12} md={6} lg={4}>
            <Grid xs={12} md={6} lg={4}>
              <label htmlFor="contact-input">{t("contact")}</label>
            </Grid>
            <Grid xs={12}>
              <div className="ccCodeBox">
                <div className="ccBox-L">
                  <TextInput
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    disabled
                  />
                </div>
                <div className="ccBox-R">
                  <Grid item xs={12} md={8}>
                    <TextInput
                      id="contact-input"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      placeholder={t("contact")}
                      disabled={!editForm}
                    />
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SelectInput
              label={t("country")}
              placeholder={t("select-country")}
              options={countryNamesArray}
              value={country}
              disabled={!editForm}
              onChange={(e) => {
                setCountry(e.target.value);
                setCountryCode(myCountryNumberCodes[e.target.value]);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextInput
              label={t("city")}
              placeholder={t("city")}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              disabled={!editForm}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyProfile;
