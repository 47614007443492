import { axiosInstance } from "../../Axios";
import { tokenConfig } from "./auth";
import { returnErrors } from "./error";
import { returnMessages } from "./message";

const initialState = {
    profile:{},
    account_settings:{
        time_zone:"UTC",
        language:"en"
    }
};

const settingsReducer = (state=initialState, action) => {
    switch(action.type){
        case "SET_PROFILE":
            return {
                ...state,
                profile: action.payload
            }
        case "SET_ACCOUNT_SETTINGS":
            return {
                ...state,
                account_settings: {
                    ...state.account_settings,
                    ...action.payload
                }
            }
        case "CLEAR_UNSAVED_FLAG":
            delete state.account_settings.save_lng_to_server
            return state
        case "CLEAR_SETTINGS":
            return initialState;
        default:
            return state;
    }
}

export const setProfile = (payload)=>({
    type: "SET_PROFILE",
    payload
});

export const setAccountSettings = (payload)=>({
    type: "SET_ACCOUNT_SETTINGS",
    payload
})

export const clearSettings = ()=>({
    type: "CLEAR_SETTINGS"
});

export const clearUnsaved = () => ({
    type: "CLEAR_UNSAVED_FLAG"
})

export const getProfile = () => async (dispatch, getState) => {

    try {
        const {data, status} = await axiosInstance.get('/authentication/settings/my-profile/', tokenConfig(getState));
        if(status>399) throw(data);
        dispatch({
          type: "SET_PROFILE",
          payload: data,
        });
    } catch (error) {
       
        dispatch(returnErrors(error.response.data?.detail,error.response.status))
        throw(error.response.data)
    }

};

export const saveProfile = (payload) => async (dispatch, getState) =>{

    try {
        const {data, status} = await axiosInstance.post('/authentication/settings/my-profile/', payload, tokenConfig(getState));
        if(status>399) throw(data);
        dispatch({
          type: "SET_PROFILE",
          payload: data,
        });
        const msg = data.message?data.message:"profile-updated";
        dispatch(returnMessages(msg, status));
    } catch (error) {
       
        dispatch(returnErrors(error.response.data?.detail,error.response.status))
        throw(error.response.data)
    }

}

export const getAccountSettings = (save_unsaved_chg=false) => async (dispatch, getState) => {
    try {
        const {data, status} = await axiosInstance.get('/authentication/settings/account/', tokenConfig(getState));
        if(status>399) throw(data);
        dispatch(setAccountSettings(data));
        if(save_unsaved_chg){
            dispatch(clearUnsaved());
        }
    } catch (error) {
       
        dispatch(returnErrors(error.response.data?.detail,error.response.status))
        throw(error.response.data)
    }

};

export const saveAccountSettings = (payload) => async (dispatch, getState) => {
    const isAuthenticated = getState().auth.isAuthenticated;
    if(!isAuthenticated){
        dispatch(setAccountSettings({
            language:payload.language,
            save_lng_to_server: true
        }));
        return
    }
    try {
        const {data, status} = await axiosInstance.post('/authentication/settings/account/', payload, tokenConfig(getState));
        if(status>399) throw(data);
        dispatch(setAccountSettings(data));
        const msg = data.message?data.message:"changes-saved";
        dispatch(returnMessages(msg, status));
        return true;
    } catch (error) {
       
        dispatch(returnErrors(error.response.data?.detail,error.response.status))
        throw(error.response.data)
    }

};

export const changePassword = (payload) => async (dispatch, getState) => {

    try {
        const { data, status } = await axiosInstance.post('authentication/settings/change-password/', payload, tokenConfig(getState));
        if(status>399) throw(data);
        const msg = data.message?data.message:"pwd-changed";
        dispatch(returnMessages(msg, status));
    } catch (error) {
       
        dispatch(returnErrors(error.response.data?.detail,error.response.status))
        throw(error.response.data)
    }

};

export default settingsReducer;