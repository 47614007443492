import React, { useEffect } from 'react'
import './AdminLogin.scss'
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { setNewToken, updateIdToken } from '../../store/reducers/auth';
// import { setAuthenticationSuccess } from '../../store/reducers/authSlice';
const AdminLogin = () => {

    const { id } = useParams();
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setNewToken(id));
        navigate('/')
    }, [id])
    return (
        <div className='adminLOGIN-con'>Loading....</div>
    )
}

export default AdminLogin