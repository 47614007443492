import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchAffiliateRequest,
  fetchAffiliateSuccess,
  fetchAffiliateFailure,
  fetchAffiliateUserStatsRequest,
  fetchAffiliateUserStatsSuccess,
  fetchAffiliateUserStatsFailure,
  fetchAffiliateCodeListRequest,
  fetchAffiliateCodeListFailure,
  fetchAffiliateCodeListSuccess,
  // fetchSearchAffiliateListRequest,
  // fetchSearchAffiliateListSuccess,
  // fetchSearchAffiliateListFailure,
} from "../reducers/affiliateSlice";
import { baseUrl } from "../../utilities/apis/apis";

function* fetchAffiliateList(action) {
  try {
    const idToken = action.payload;
    const headers = {
      Authorization: `${idToken}`,
    };

    const response = yield call(fetch, `${baseUrl}affiliate/code-list/`, {
      headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = yield response.json();
    yield put(fetchAffiliateSuccess(data));
  } catch (error) {
    console.error("Error fetching affiliate list:", error);
    yield put(fetchAffiliateFailure(error.message));
  }
}

function* fetchAffiliateUserState(action) {
  try {
    const { idToken, userId } = action.payload;

    const headers = {
      Authorization: `${idToken}`,
    };
    const response = yield call(
      fetch,
      `${baseUrl}affiliate/affiliate-stats/?affiliate_id=${userId}`,
      {
        headers,
      }
    );
    const data = yield response.json();

    yield put(fetchAffiliateUserStatsSuccess(data));
  } catch (error) {
    console.error("Error fetching affiliate list:", error);
    yield put(fetchAffiliateUserStatsFailure(error.message));
  }
}

function* fetchAffiliateCodeListState(action) {
  try {
    const idToken = action.payload;

    const headers = {
      Authorization: `${idToken}`,
    };
    const response = yield call(
      fetch,
      `${baseUrl}affiliate/admin/affiliate-code-list/`,
      {
        headers,
      }
    );
    const data = yield response.json();

    yield put(fetchAffiliateCodeListSuccess(data));
  } catch (error) {
    console.error("Error fetching affiliate list:", error);
    yield put(fetchAffiliateCodeListFailure(error.message));
  }
}
// function* searchUser(action) {
//     try {
//       const response = yield call(
//         fetch,
//         `${baseUrl}authentication/admin/search-user/?search=Swarnab`
//       );

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = yield response.json();
//       yield put(fetchSearchAffiliateListSuccess(data));
//     } catch (error) {
//       yield put(fetchSearchAffiliateListFailure(error.message));
//     }
//   }

// export function* userSaga() {
//   yield takeLatest(fetchSearchAffiliateListRequest.type, searchUser);
// }
export function* affiliateSaga() {
  yield takeLatest(fetchAffiliateRequest.type, fetchAffiliateList);
}

export function* affiliateUserStatsSaga() {
  yield takeLatest(
    fetchAffiliateUserStatsRequest.type,
    fetchAffiliateUserState
  );
}
export function* fetchAffiliateCodeList() {
  yield takeLatest(
    fetchAffiliateCodeListRequest.type,
    fetchAffiliateCodeListState
  );
}
