import React, { useEffect, useMemo, useState } from "react";

import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/system";
import ReactGA from "react-ga";

import { AddCircle } from "@mui/icons-material";
import { Box, Button, CardContent, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsOverview,
  getUserAccounts,
} from "../../store/reducers/dashboard";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { dollarUS } from "../../utilities/formatter";
import DonutChart from "./DonutChart";
import Loader from "../../components/Reusable/Loader";
import { login } from "../../store/reducers/auth";
import "./Style.scss";
import moment from "moment";

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #ffffff80;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
    // outline: 1px solid #dbac2e;
  }

  &.${tabUnstyledClasses.selected} {
    background: #0cce6b;
    border-radius: 20px;
    box-shadow: 8px 8px 20px rgb(46 44 52 / 20%);
    color: #ffffffe6;
    text-shadow: 4px 4px 12px rgb(0 0 0 / 12%);
    font-weight: 700;
    font-size: 16px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  width: 100%;
  `
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  margin: 0;
  min-width: 262px;
  background: #1A1A1A;
  border-radius: 25px;
  box-shadow: none;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);
const getStatusColor = (status) => {
  switch (status) {
    case 'in_progress':
      return 'yellow';
    case 'failed':
      return 'red';
    case 'passed':
      return 'green';
    default:
      return '';
  }
};
const CardContentStyled = styled(CardContent)`
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.02);
  border-radius: 24px;
  background: none;

  &:hover {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.12);
    background: #2b2b2b;
    cursor: pointer;
  }
  .row-top {
    display: flex;
    justify-content: space-between;
  }
  .row-top * {
    color: #8f8f8f;
    font-weight: 500;
    font-size: 16px;
    b {
      font-weight: 700;
    }
    span {
      color: #8f8f8fab;
    }
  }
  .row-mid {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.02); */
    /* padding-bottom: 16px; */
    .val {
      font-weight: 700;
      font-size: 24px;
      word-wrap: break-word;
    }
    .label {
      color: #8f8f8f;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .row-bottom * {
    font-size: 16px;
    color: #8f8f8f;
    font-weight: 500;
    b {
      font-weight: 700;
      font-size: 16px;
      color: #ffffff;
    }
  }
`;

const Card = ({ data }) => {
  const navigate = useNavigate();
  const redirect_to_metrics = () =>
    navigate(`/accountmetrics/${data.login_id}`);
  const [loading, setLoading] = useState(false);
  // const data = useSelector((state) => state.dashboard.user_accounts);
  // console.log(login, "mac&gray");
  console.log("login", data);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (login) {
  //     data || setLoading(true);
  //     dispatch(getAccountsOverview(login))
  //       .then((res) => {
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  // }, [dispatch, login]);

  // const daily_loss_prg = useMemo(() => {
  //   if (data) {
  //     const remaining_max_loss = data?.account_overview?.remaining_daily_loss;
  //     const max_loss_target = data?.account_overview?.daily_loss_target;
  //     if (remaining_max_loss >= max_loss_target) return 0;
  //     const prg =
  //       (Math.abs(data.account_overview?.remaining_daily_loss) /
  //         data?.account_overview?.daily_loss_target) *
  //       100;
  //     if (prg > 100) return 100;
  //     return prg;
  //   }
  //   return 0;
  // }, [data]);

  // const max_loss_prg = useMemo(() => {
  //   if (data) {
  //     const remaining_max_loss = data?.account_overview?.remaining_max_loss;
  //     const max_loss_target = data?.account_overview?.max_loss_target;

  //     if (remaining_max_loss >= max_loss_target) return 0;
  //     const prg =
  //       (Math.abs(data.account_overview?.remaining_max_loss) /
  //         data?.account_overview?.max_loss_target) *
  //       100;

  //     if (prg > 100) return 100;
  //     return prg;
  //   }
  //   return 0;
  // }, [data]);
  console.log(
    data.program_objectives?.max_loss,
    "data.program_objectives?.max_loss"
  );

  const { t } = useTranslation();
  return (
    <CardContentStyled
      className="cardOverview"
      onClick={data && redirect_to_metrics}
    >
      <Grid container rowSpacing={1.5}>
        <Grid container item className="row-top">
          <Grid item lg={4}>
            <span>{t("Login Id :")}</span> {data?.login_id}
          </Grid>
          {/* <Grid item lg={8} sx={{ textAlign: "end" }}>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="text"
                sx={{ fontSize: "1rem" }}
              />
            ) : (
              <>
                <b>{data && `${data.account_metrics?.win_ratio} `}</b>
                <span>{t("win-ratio")}</span> |{" "}
                <b>{data && data?.account_metrics?.days_traded} </b>
                <span>{t("days-traded")}</span>
              </>
            )}
          </Grid> */}
        </Grid>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={65}
          />
        ) : (
          <div className="row-mid" spacing={1}>
            <div>
              <Typography component={"div"} className="val">
                {data && `${data?.challenge}`}
              </Typography>
              <Typography component={"div"} className="label">
                {t("challenge")}
              </Typography>
            </div>
            <div>
              <Typography component={"div"} className="val">
                {data && `${data.server}`}
              </Typography>
              <Typography component={"div"} className="label">
                {t("server")}
              </Typography>
            </div>
            <div>
              <Typography component={"div"} className="val">
                {data && `${data.stage?.replace("_", " ")}`}
              </Typography>
              <Typography component={"div"} className="label">
                {t("Phase")}
              </Typography>
            </div>
            <div>
              <Typography component={"div"} className="val">
                {data && `${data.trading_platform}`}
              </Typography>
              <Typography component={"div"} className="label">
                {t("Trading  Platform")}
              </Typography>
            </div>
            <div>
              <Typography
                component={"div"}
                className="val"
                style={{
                  textTransform: "capitalize",
                  color: getStatusColor(data.status),
                }}
              >
                {data && `${data.status.replace("_", " ")}`}
              </Typography>
              <Typography component={"div"} className="label">
                {t("status")}
              </Typography>
            </div>
            <div>
              <Typography component={"div"} className="val">
                {data && `${moment(data.start_date).format("DD-MM-YYYY")}`}
              </Typography>
              <Typography component={"div"} className="label">
                {t("Start Date")}
              </Typography>
            </div>
          </div>
        )}
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={92}
          />
        ) : (
          <></>
          // <Grid container item className="row-bottom">
          //   <Grid container item spacing={1}>
          //     <Grid
          //       item
          //       container
          //       xs={12}
          //       md={5}
          //       spacing={1}
          //       alignItems="center"
          //     >
          //       <Grid item xs={3}>
          //         <Box width={40} height={40}>
          //           {data && (
          //             <DonutChart
          //               data={
          //                 (data &&
          //                   Math.abs(data?.account_metrics?.rrr) * 100) ||
          //                 0
          //               }
          //               color="#9747FF"
          //               chartId={`rrr-chart-${data.id}`}
          //             />
          //           )}
          //         </Box>
          //       </Grid>
          //       <Grid item xs={9}>
          //         <Typography component={"div"}>
          //           {t("rrr")}:{" "}
          //           <b>
          //             {data && Math.abs(data?.account_metrics?.rrr).toFixed(3)}
          //           </b>
          //         </Typography>
          //       </Grid>
          //     </Grid>
          //     <Grid
          //       item
          //       container
          //       xs={12}
          //       md={7}
          //       spacing={1}
          //       alignItems="center"
          //     >
          //       <Grid item xs={3}>
          //         <Box width={40} height={40}>
          //           {data && (
          //             <DonutChart
          //               data={"daily_loss_prg" || 0}
          //               color="#66C8FF"
          //               chartId={`mdl-chart-${data.id}`}
          //             />
          //           )}
          //         </Box>
          //       </Grid>
          //       <Grid item xs={9}>
          //         <Typography component={"div"}>
          //           {t("max-daily-loss")}:{" "}
          //           <b>
          //             {data &&
          //               `US ${dollarUS(
          //                 data?.program_objectives?.max_daily_loss
          //               )}`}
          //           </b>
          //         </Typography>
          //       </Grid>
          //     </Grid>
          //   </Grid>
          //   <Grid container item spacing={1}>
          //     <Grid
          //       item
          //       container
          //       xs={12}
          //       md={5}
          //       spacing={1}
          //       alignItems="center"
          //     >
          //       <Grid item xs={3}>
          //         <Box width={40} height={40}>
          //           {data && (
          //             <DonutChart
          //               data={
          //                 (data &&
          //                   (data.program_objectives?.profit /
          //                     data?.program_objectives?.profit_target) *
          //                     100) ||
          //                 0
          //               }
          //               color="#86DFAC"
          //               chartId={`profit-chart-${data.id}`}
          //             />
          //           )}
          //         </Box>
          //       </Grid>
          //       <Grid item xs={9}>
          //         <Typography component={"div"}>
          //           {t("profit")}:{" "}
          //           <b>
          //             {data &&
          //               `US ${dollarUS(data.program_objectives?.profit)}`}
          //           </b>
          //         </Typography>
          //       </Grid>
          //     </Grid>
          //     <Grid
          //       item
          //       container
          //       xs={12}
          //       md={7}
          //       spacing={1}
          //       alignItems="center"
          //     >
          //       <Grid item xs={3}>
          //         <Box width={40} height={40}>
          //           {data && (
          //             <DonutChart
          //               data={"max_loss_prg" || 0}
          //               color="#F7B36E"
          //               chartId={`ml-chart-${data.id}`}
          //             />
          //           )}
          //         </Box>
          //       </Grid>
          //       <Grid item xs={9}>
          //         <Typography component={"div"}>
          //           {t("max-ini-dep-loss")}:{" "}
          //           <b>
          //             {data &&
          //               `US ${dollarUS(data.program_objectives?.max_loss)}`}
          //           </b>
          //         </Typography>
          //       </Grid>
          //     </Grid>
          //   </Grid>
          // </Grid>
        )}
      </Grid>
    </CardContentStyled>
  );
};

const Overview = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user_accounts } = useSelector((state) => state.dashboard);
  useEffect(() => {
    // dispatch(getAccountsOverview());
    setLoading(true);
    dispatch(getUserAccounts()).then(() => setLoading(false));
  }, [dispatch]);

  return (
    <Box p={3} className="mainBGcolor">
      <Grid container>
        <TabsUnstyled defaultValue={0} style={{ width: "100%" }}>
          <Grid container item xs={12} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Typography sx={{ fontWeight: 500, fontSize: "24px" }}>
                {t("all-accounts")}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              spacing={2}
              justifyContent="end"
            >
              <Grid item className="tab-button">
                <TabsList>
                  <Tab>
                    {t("active")}
                    {user_accounts &&
                      ` (${
                        user_accounts.filter((item) => {
                          if (!item.is_Active) return false;
                          return true;
                        }).length
                      })`}
                  </Tab>
                  <Tab>
                    {t("inactive")}
                    {user_accounts &&
                      ` (${
                        user_accounts.filter((item) => {
                          if (item.is_Active) return false;
                          return true;
                        }).length
                      })`}
                  </Tab>
                </TabsList>
              </Grid>
              <Grid item className="add_addcount_btn">
                <Button
                  variant="outlined"
                  color={"secondary"}
                  startIcon={<AddCircle sx={{ color: "red" }} />}
                  onClick={() => navigate("/payments/funding-evaluation")}
                  sx={{
                    textTransform: "unset",
                    borderRadius: "40px",
                    marginLeft: "auto",
                    minHeight: "50px",
                    "& svg path": {
                      color: "#9292AB",
                    },
                  }}
                >
                  {t("add-account")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loading ? (
            <Loader />
          ) : (
            <Grid item container>
              <TabPanel value={0}>
                <Grid container spacing={3} style={{ marginBottom: "80px" }}>
                  {user_accounts &&
                    user_accounts.map((item) => {
                      if (!item.is_Active) return false;
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
                          <Card className="cardOverview" data={item} />
                        </Grid>
                      );
                    })}
                </Grid>
              </TabPanel>
              <TabPanel value={1}>
                <Grid container spacing={3} style={{ marginBottom: "80px" }}>
                  {user_accounts &&
                    user_accounts.map((item) => {
                      if (item.is_Active) return false;
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
                          <Card data={item} />
                        </Grid>
                      );
                    })}
                </Grid>
              </TabPanel>
            </Grid>
          )}
        </TabsUnstyled>
      </Grid>
    </Box>
  );
};

export default Overview;
