import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./style.scss";
import { InputAdornment, ListSubheader, TextField } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectSearchInput(props) {
  const { label, value, options, onChange, placeholder, disabled } = props;

  const [searchText, setSearchText] = React.useState("");
  var inputRef = React.useRef(undefined);

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = React.useMemo(
    () => options.filter((option) => containsText(option, searchText)),
    [searchText, options]
  );

  return (
    <FormControl className="op-select-input" sx={{ width: 300 }}>
      {label ? <label>{label}</label> : false}
      <Select
        displayEmpty
        value={value}
        disabled={disabled}
        onChange={onChange}
        input={<OutlinedInput />}
        onClose={() => setSearchText("")}
        renderValue={() => {
          return value;
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "country" }}
        onAnimationEnd={() => {
          if (inputRef.current) inputRef.current.focus();
        }}
      >
        <ListSubheader>
          <TextField
            ref={inputRef}
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {displayedOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
            // style={getStyles(name, value, theme)}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
