import {
    Box,
    Button,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Tab,
    Tabs,
    Typography,
  } from '@mui/material'
  import ReactGA from "react-ga"
  import { TabPanel } from '../../../components/Reusable/TabPanel'
  import React, { useEffect } from 'react'
  import './style.scss'
  import { getChallengePricing } from '../../../store/reducers/payment'
  import { useDispatch, useSelector } from 'react-redux'
  import { dollarUS } from '../../../utilities/formatter'
  import { useNavigate } from 'react-router-dom'
  import { useTranslation } from 'react-i18next'
  import logo from '../../../assets/images/mac/Logo.png'
  
  const Card = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
  
    const Items = ({ data }) => (
      <Grid container spacing={2} component={'ul'}>
        <Grid item xs={6} component={'li'}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            {data && data.leverage}
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', color: '#636363' }}
          >
            {t('Leverage')}
          </Typography>
        </Grid>
        <Grid item xs={6} component={'li'}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            {data &&
              `${dollarUS(data.evaluation_phase_1)} (${
                data.evaluation_phase_1_percent
              })`}
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', color: '#636363' }}
          >
            {t('Evaluation Phase 1', { days: data && data.minimum_days_phase_1 })}
          </Typography>
        </Grid>

        {/* <Grid item xs={6} component={'li'}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            {data &&
              `${dollarUS(data.evaluation_phase_2)}(${
                data.evaluation_phase_2_percent
              })`}
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', color: '#636363' }}
          >
            {t('Evaluation Phase 2', { days: data && data.minimum_days_phase_2 })}
          </Typography>
        </Grid> */}
        <Grid item xs={6} component={'li'}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            {data && `${dollarUS(data.max_loss)}(${data.max_loss_percent})`}
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', color: '#636363' }}
          >
            {t('max-loss')}
          </Typography>
        </Grid>
        <Grid item xs={6} component={'li'}>
          <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
            {data &&
              `${dollarUS(data.max_daily_loss)}(${data.max_daily_loss_percent})`}
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', color: '#636363' }}
          >
            {t('max-daily-loss')}
          </Typography>
        </Grid>
        {/* <Grid item xs={6} component={'li'}>
          <Typography sx={{fontWeight: 700, fontSize: '16px'}}>{data && `${data.profit_split}%`}</Typography>
          <Typography sx={{fontWeight: 500, fontSize: '16px', color: '#636363'}}>Profit Split</Typography>
        </Grid> */}
        {/* <Grid item xs={6} component={'li'}>
          <Typography sx={{fontWeight: 700, fontSize: '16px'}}>{data && data.bi_weekly_profit_split ? t('yes'): t('no')}</Typography>
          <Typography sx={{fontWeight: 500, fontSize: '16px', color: '#636363'}}>Bi Weekly Profit Split</Typography>
        </Grid>
        <Grid item xs={6} component={'li'}>
          <Typography sx={{fontWeight: 700, fontSize: '16px'}}>{data && data.max_capital_growth ? t('yes'): t('no')}</Typography>
          <Typography sx={{fontWeight: 500, fontSize: '16px', color: '#636363'}}>Max Capital growth</Typography>
        </Grid> */}
      </Grid>
    )
    const displayText = props.data.name.split(' ')
  
    return (
      <CardContent className="pricing-card">
        <Typography fontSize="36px">
          {displayText[0] && displayText[0]}
        </Typography>
        <Typography mb={1}>{displayText.slice(1).join(' ')}</Typography>
        <Divider sx={{ borderColor: '#636363', marginBottom: '24px' }}></Divider>
        <Items data={props.data} />
        <CardActions className="fevalBtn">
          <Button
            onClick={() =>
              navigate(`/funding-evaluation/checkout/${props.data.id}`)
            }
          >
            {t('pay')} {dollarUS(props.data.price)}
          </Button>
        </CardActions>
      </CardContent>
    )
  }
  
  const PublicFundingEvaluation = () => {
    useEffect(() => {
      ReactGA.pageview(window.location.pathname);
    }, []);
    const [value, setValue] = React.useState(0)
    const dispatch = useDispatch()
    const { plans, challenges } = useSelector((state) => state.payment.pricing)
    const { t } = useTranslation()
  
    const handleChange = (event, newValue) => {
      setValue(newValue)
    }
  
    useEffect(() => {
      dispatch(getChallengePricing())
    }, [dispatch])
  
    return (

      <Box sx={{ padding: '1rem' , paddingX:10 }} component="div">
        <img style={{paddingBottom:'20px'}} src={logo} alt='logo' />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} component="div">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {plans &&
              plans.map((item, index) => {
                if (index === 0) {
                  return <Tab label={item} key={item} />
                }
              })}
          </Tabs>
        </Box>
        {plans &&
          plans.map((item, idx) => {
            if (value === idx)
              return (
                <>
                  <TabPanel
                    value={item}
                    index={item}
                    component="div"
                    className="opt-tabpanel"
                  >
                    <Grid container spacing={2}>
                      {challenges &&
                        challenges.map((challenge, idx) => {
                          if (challenge.plan_type === item)
                            return (
                              <Grid item xs={12} md={6} lg={4} key={idx}>
                                <Card data={challenge} />
                              </Grid>
                            )
  
                          return false
                        })}
                    </Grid>
                  </TabPanel>
                  {plans &&
                    plans.map((item, idx) => {
                      if (value === idx) {
                        let data = challenges.filter(
                          (challenge) => challenge.plan_type === item,
                        )
                        if (data.length) {
                          data = data[0]
                          return (
                            <Grid
                              container
                              item
                              spacing={3}
                              component="ul"
                              sx={{ listStyle: 'none' }}
                              key={idx}
                            >
                              <Grid item component="li">
                                <Typography
                                  sx={{ fontWeight: 700, fontSize: '16px' }}
                                >
                                  {data.trading_requirements}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#636363',
                                  }}
                                >
                                  {t('trading-req')}
                                </Typography>
                              </Grid>
                              <Grid item component="li">
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {data.tradable_asset}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#636363',
                                  }}
                                >
                                  {t('trade-assets')}
                                </Typography>
                              </Grid>
                              <Grid item component="li">
                                <Typography
                                  sx={{ fontWeight: 700, fontSize: '16px' }}
                                >
                                  {t('yes')}
                                  {/* {data.trade_through_news ? t('yes') : t('no')} */}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#636363',
                                  }}
                                >
                              
                                  {t('trading-news')}
                                </Typography>
                              </Grid>
                              <Grid item component="li">
                                <Typography
                                  sx={{ fontWeight: 700, fontSize: '16px' }}
                                >
                                  {t('yes')}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#636363',
                                  }}
                                >
                                  Trading on Weekend
                                  {/* {t('hold-over-wkend')} */}
                                </Typography>
                              </Grid>
                              <Grid item component="li">
                                <Typography
                                  sx={{ fontWeight: 700, fontSize: '16px' }}
                                >
                                  {data.eas ? t('yes') : t('no')}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#636363',
                                  }}
                                >
                                  EAs
                                </Typography>
                              </Grid>
                              <Grid item component="li">
                                <Typography
                                  sx={{ fontWeight: 700, fontSize: '16px' }}
                                >
                                  {data.Hedging_and_Stacking ? t('yes') : t('no')}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#636363',
                                  }}
                                >
                                  {t('hedging-stacking')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        }
                      }
                      return false
                    })}
                </>
              )
            return false
          })}
      </Box>
    )
  }
  
  export default PublicFundingEvaluation
  