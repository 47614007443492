import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useAuth } from '../../../hooks/useAuth'
import {
  fetchAffiliateUserStatsRequest
} from '../../../store/reducers/affiliateSlice'
import './style.scss'

const Chart = () => {
  const [click_stats, setClickStats] = useState([])
  const dispatch = useDispatch()
  const idToken = useAuth()
  const params = useParams()
 
  const userId = params.id
 
  const metrics = useSelector((state) => state.dashboard.metrics)
  const statsData = useSelector((state) => state.affiliateSlice.userStats)
 

  const [data, setData] = useState()
  const [months, setMonths] = useState()
  useEffect(() => {
    // dispatch(fetchAffiliateUserStatsRequest({ idToken, userId }))
    // dispatch(fetchAffiliateRequest(idToken));
  }, [])

  useEffect(() => {
    try {
      if (statsData.click_stats.lenght <= 0) {
        return
      }
    } catch (e) {
     
      return
    }
    const mdata = statsData.click_stats.map((item) => {
      return item.clicks
    })
    setData(mdata)
    const months = statsData.click_stats.map((date) => {
      return date.created_day
    })
    setMonths(months)
  }, [statsData])

  const chartData = {
    options: {
      chart: {
        type: 'line',
        height: 200,
        width: 654,
        background: 'transparent',
      },
      xaxis: {
        categories: months,
        grid: {
          show: false,
        },
      },
      yaxis: {
        // labels: {
        //   formatter: function (value) {
        //     // Convert the value to "k" format
        //     if (value >= 1000) {
        //       return (value / 1000).toFixed(0) + "k";
        //     }
        //     return value;
        //   },
        // },,

        // tickAmount: 5,
        grid: {
          show: false,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      toolbar: {
        show: true,
        background: '#111142',
      },
      grid: {
        show: false,
      },
    },
    series: [
      {
        name: 'Clicks',
        data: data,
        type: 'line',
        color: '#0cce6b',
      },
    ],
  }

  return (
    <>
      <div className="border-box" style={{ width: '100%', maxWidth: "100% !important"}}>
        <div className="accountBalance">
          <b className="accountBalance1">Clicks</b>
          <div className="graph">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
              height={250}
              data={data}
           
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Chart
