import { clearPersistedData } from "..";
import { axiosInstance } from "../../Axios";
import { clearCompetition } from "./competition";
import { clearAccountMetrics, resetDashboard } from "./dashboard";
import { returnErrors } from "./error";
import { returnMessages } from "./message";
import { clearFunding_evalution } from "./payment";
import { clearSettings } from "./settings";
import { clearSupport } from "./support";

const initialState = {
  isAuthenticated: null,
  isLoading: false,
  user: null,
  remember_me: false,
  next: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "USER_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case "NEW_TOKEN":
      return {
        ...state.user,
        idToken: action.payload
      }
    case "SET_NEXT_PATH":
      return {
        ...state,
        next: action.payload,
      };
    case "REMOVE_NEXT_PATH":
      return {
        ...state,
        next: "",
      };
    case "LOGIN_SUCCESS":
    case "REFRESH_SUCCESS":
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case "SET_REMEMBER_ME":
      return {
        ...state,
        remember_me: action.payload,
      };
    case "AUTH_ERROR":
    case "LOGIN_FAIL":
    case "LOGOUT_SUCCESS":
    case "REGISTER_FAIL":
    case "SESSION_TIMEOUT":
      return {
        ...initialState,
        next: state.next,
      };
    default:
      return state;
  }
};

export const setRememberMe = (payload) => ({
  type: "SET_REMEMBER_ME",
  payload,
});

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  const is_userloading = getState().auth.isLoading;
  if (is_userloading) return;
  dispatch({ type: "USER_LOADING" });

  axiosInstance
    .get("/authentication/user/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: "USER_LOADED",
        payload: res.data,
      });
    })
    .catch((err) => {
      if (getState().auth.isAuthenticated || getState().auth.idToken) {
        dispatch({
          type: "AUTH_ERROR",
        });
      }
    });
};

// LOGIN USER
export const login = (payload) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data, status } = await axiosInstance.post(
      "/authentication/login/",
      payload,
      config
    );
    if (status > 399) throw data;

    const expiresInSeconds = Number(data.expiresIn) - 20;
    const expiresAtDate = new Date();
    expiresAtDate.setSeconds(expiresInSeconds);
    data["expiresAtDate"] = expiresAtDate;

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });
    return true;
  } catch (error) {
    if (error.response.data?.detail === "INVALID_PASSWORD") {
      dispatch(
        returnErrors(
          "incorrect-username-pwd",
          error.response.status,
          "login-failed"
        )
      );
    } else {
      dispatch(
        returnErrors(
          error.response.data?.detail,
          error.response.status,
          "login-failed"
        )
      );
    }
    dispatch({
      type: "LOGIN_FAIL",
    });
    throw error.response.data;
  }
};

const refreshToken = () => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const payload = {
    refresh_token: getState().auth.refreshToken,
  };

  try {
    const { data, status } = await axiosInstance.post(
      "/authentication/refresh/",
      payload,
      config
    );
    if (status > 399) throw data;
    const expiresInSeconds = Number(data.expiresIn) - 20;
    const expiresAtDate = new Date();
    expiresAtDate.setSeconds(expiresInSeconds);
    data["expiresAtDate"] = expiresAtDate;

    dispatch({
      type: "REFRESH_SUCCESS",
      payload: data,
    });
    return true;
  } catch (error) {
    dispatch(returnErrors("pls-login-again", 200, "session-timeout"));
    throw error.response.data;
  }
};

// REGISTER USER
export const register = (payload) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data, status } = await axiosInstance.post(
      "/authentication/register/",
      payload,
      config
    );
    if (status > 399) throw data;
    dispatch(returnMessages("registered", "success"));
    dispatch({
      type: "REGISTER_SUCCESS",
    });
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });
    return true;
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    dispatch({
      type: "REGISTER_FAIL",
    });
    throw error.response.data;
  }
};

// RESET PASSWORD
export const resetPassword = (email) => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.post(
      "/authentication/reset-password/",
      { email }
    );
    if (status > 399) throw data;
    if (data.message) {
      dispatch(returnMessages(data.message, "success"));
      return;
    }
    dispatch(returnMessages("reset-pwd-link-sent", "success"));
  } catch (error) {
    dispatch(returnErrors(error.response.data?.detail, error.response.status));
    throw error.response.data;
  }
};

// LOGOUT USER
export const logout = () => (dispatch) => {
  dispatch(clearAccountMetrics());
  dispatch(clearSupport());
  dispatch(clearSettings());
  dispatch(resetDashboard());
  dispatch(clearCompetition());
  dispatch(clearFunding_evalution());

  clearPersistedData();
  dispatch({
    type: "LOGOUT_SUCCESS",
  });
};

export const setNextPath = (path) => ({
  type: "SET_NEXT_PATH",
  payload: path,
});
export const setNewToken = (newToken) => ({
  type:'NEW_TOKEN',
  payload:newToken
})

export const sessionTimeout = () => (dispatch, getState) => {
  if (getState().auth.remember_me) {
    dispatch(refreshToken())
      .then((res) => {
        return true;
      })
      .catch((err) => {
        dispatch({ type: "SESSION_TIMEOUT" });
        dispatch(returnErrors("pls-login-again", 200, "session-timeout"));
        return;
      });
  } else {
    dispatch({ type: "SESSION_TIMEOUT" });
    dispatch(returnErrors("pls-login-again", 200, "session-timeout"));
  }
};

export const removeNextPath = () => ({
  type: "REMOVE_NEXT_PATH",
});

export const tokenConfig = (getState) => {
  const token = getState().auth.idToken;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token) {
    config.headers["AUTHORIZATION"] = `${token}`;
  }

  return config;
};

export default authReducer;
