import React, { useEffect, useRef, useState } from "react";
import { usePagination, useTable } from "react-table";
import formatISOtoDDMMYY from "../../utilities/formatISOdate";
// import {Button, Grid, Tab, Tabs, Typography} from "@mui/material";
// import {Box} from "@mui/system";
import "./affiliateStyle.scss";
import "./affiliateTable.scss";
// import "./global.css";

import { useDispatch, useSelector } from "react-redux";
import caretNext from "../../assets/images/Caret down.png";
import caretPrev from "../../assets/images/Caret down.svg";
import { useAuth } from "../../hooks/useAuth";
import { fetchAffiliateCodeListRequest } from "../../store/reducers/affiliateSlice";

const AffiliateCode = () => {
  const is_loading = useSelector((state) => state.loader.is_loading);

  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dispatch = useDispatch();
  const truncateString = (inputString, maxLength) => {
    const formattedString = inputString.toString();
    if (formattedString.length > maxLength) {
      return inputString.toString().substring(0, maxLength) + "...";
    }
    return inputString;
  };

  const dropdownRef = useRef(null);
  const idToken = useAuth();
  const affiliateCodeData = useSelector(
    (state) => state.affiliateSlice.AffiliateCodeList
  );

  useEffect(() => {
    dispatch(fetchAffiliateCodeListRequest(idToken));
  }, [dispatch]);

  const tableData = affiliateCodeData;

  // const tableData = [
  //   {
  //     status: "active",
  //     date: "12th july 2023",
  //     commission: "7.5",
  //     repeat: "5.1",
  //     code: "TGSUE34",
  //     URL: "/www.ghthds.co",
  //     pushleads: "16383",
  //     conversion: "1235",
  //   },
  //   {
  //     status: "deactive",
  //     date: "12th july 2023",
  //     commission: "7.5",
  //     repeat: "5.1",
  //     code: "TGSUE34",
  //     URL: "/www.ghthds.co",
  //     pushleads: "16383",
  //     conversion: "1235",
  //   },
  //   {
  //     status: "active",
  //     date: "12th july 2023",
  //     commission: "7.5",
  //     repeat: "5.1",
  //     code: "TGSUE34",
  //     URL: "/www.ghthds.co",
  //     pushleads: "16383",
  //     conversion: "1235",
  //   },
  //   {
  //     status: "deactive",
  //     date: "12th july 2023",
  //     commission: "7.5",
  //     repeat: "5.1",
  //     code: "TGSUE34",
  //     URL: "/www.ghthds.co",
  //     pushleads: "16383",
  //     conversion: "1235",
  //   },
  //   {
  //     status: "active",
  //     date: "12th july 2023",
  //     commission: "7.5",
  //     repeat: "5.1",
  //     code: "TGSUE34",
  //     URL: "/www.ghthds.co",
  //     pushleads: "16383",
  //     conversion: "1235",
  //   },
  //   {
  //     status: "active",
  //     date: "12th july 2023",
  //     commission: "7.5",
  //     repeat: "5.1",
  //     code: "TGSUE34",
  //     URL: "/www.ghthds.co",
  //     pushleads: "16383",
  //     conversion: "1235",
  //   },
  // ];
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const data = React.useMemo(() => tableData, []);
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Status",
      //   Cell: ({ row }) => (
      //     <div className="side-line-flex">

      //       {row.original.status === "deactive" && (
      //         <>
      //           <div className="side-line"></div>
      //           <div className="error ">
      //             <div className="passed">Deactive</div>
      //           </div>
      //         </>
      //         // </div>
      //       )}
      //       {row.original.status === "active" && (
      //         <>
      //           <div className="side-line-active"></div>
      //           <div className="done">
      //             <div className="passed">Active</div>
      //           </div>
      //         </>
      //       )}
      //     </div>
      //   ),
      // }

      {
        Header: <>Name </>,
        accessor: "name",
      },
      {
        Header: <>Percentage % </>,
        accessor: "percentage",
      },
      {
        Header: (
          <>
            {/* <div className="column_header"> */}% (repeat){" "}
            {/* <img
              src={vectorIcon}
              alt="arrow-down"
              className="vector-icon"

            />{" "} */}
            {/* </div> */}
          </>
        ),
        accessor: "percentage_repeat",
      },
      {
        Header: (
          <>
            {/* <div className="column_header"> */}
            code{" "}
            {/* <img
              src={vectorIcon}
              alt="arrow-down"
              className="vector-icon"

            />{" "} */}
            {/* </div> */}
          </>
        ),
        accessor: "code",
      },
      {
        Header: "URL",
        accessor: "url",
        Cell: ({ row }) => (
          <div className="url">
            <b className="url-content">
              {truncateString(row.original.url, 20)}
            </b>
          </div>
        ),
      },
      {
        Header: <>Referred Count </>,
        accessor: "referred_count",
      },
      {
        Header: <>Created At </>,
        accessor: "created",
        Cell: ({ row }) => (
          <div className="created">
            <b className="created-content">
              {formatISOtoDDMMYY(row.original.created)}
            </b>
          </div>
        ),
      },
      {
        Header: "Reffered List",
        accessor: "viewDetails",
        Cell: ({ row }) => (
          <button
            className="view_details_btn"
            // onClick={() => handleSeeStats(row.original.id)}
          >
            View Details
          </button>
        ),
      },
    ],
    [isChallengeDropdown]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
    //if it comment out it will show infinite loop error
  );
  const totalPages = Math.ceil(rows.length / pageSize);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <>
      <table {...getTableProps()} className="leaderboard-table ">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="row-slide">
                {row.cells.map((cell, index) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <div
          className="pagination-direction"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <img src={caretNext} alt="Caret Next" />
          <div>Previous</div>
        </div>
        <span style={{ display: "flex", gap: "15px", fontWeight: "100" }}>
          {pageNumbers.map((pageNumber) => (
            <strong key={pageNumber}>
              {pageIndex + 1 === pageNumber ? (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "900",
                  }}
                >
                  {pageNumber}
                </span>
              ) : (
                <div
                  onClick={() => gotoPage(pageNumber - 1)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "10",
                  }}
                >
                  {pageNumber}
                </div>
              )}
            </strong>
          ))}
        </span>
        <div
          className="pagination-direction"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <div>Next</div>
          <img src={caretPrev} alt="Caret Next" />
        </div>
      </div>
    </>
  );
};
export default AffiliateCode;
