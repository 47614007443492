import React, { useState } from 'react'
import TextInput from '../../../components/Form/TextInput'
import './style.scss'

import DefaultPage from '../../../components/Reusable/DefaultPage'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { resetPassword } from '../../../store/reducers/auth'
import { useTranslation } from 'react-i18next'
import LoginBackIMG from '../../../assets/images/mac/LoginBack.png'
import LoginLIMG from '../../../assets/images/mac/fp.png'
import LogoIMG from '../../../assets/images/mac/Logo.png'
import bgIMG from '../../../assets/images/mac/bg.png'
import ReactGA from "react-ga"
import { useEffect } from 'react'
const Login = () => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(resetPassword(email))
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="mainLogin">
      <img src={bgIMG} alt="" className="bgIMG" />

      <div className="mainLogin-Con">
        <div className="mainLogin-M">
          <img className="logoImg" src={LogoIMG} alt="" />
          <img className="loginLI fp" src={LoginLIMG} alt="" />
        </div>
        <div className="mainLogin-Overlay">
          <img className="LoginBackIMG" src={LoginBackIMG} alt="" />
          <div className="loginFormTop">
            <h1>Forgot Password ?</h1>
            <h2>
            Already have an account?
            <a href="/login">Log In</a>
          </h2>
          </div>
          <form id="forgot-pswd-form">
            <TextInput
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="johndoe@email.com"
            />
            <div onClick={handleSubmit} type="submit" className="loginBtn">
              {t('submit')}
            </div>
          </form>
        </div>
      </div>
    </div>
    // <DefaultPage message={t("don't-worry-it-happens")}>
    //   <h1>{t('forgot-password')}?</h1>
    //   <form id="forgot-pswd-form">
    //     <TextInput label="Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="johndoe@email.com"/>
    //     <Button onClick={handleSubmit} color="secondary" variant="contained" type='submit'>{t("submit")}</Button>
    //   </form>
    // </DefaultPage>
  )
}

export default Login
