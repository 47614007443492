import {
  Grid,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  createSvgIcon,
  Button,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./style.scss";
import { styled } from "@mui/system";
import ReactGA from "react-ga";
import BalanceChart from "./BalanceChart";
import RadialChart from "./RadialChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import {
  getAccountMetrics,
  getBalanceEquityChart,
  getUserAccounts,
  setLoginId,
} from "../../store/reducers/dashboard";
import { dollarUS } from "../../utilities/formatter";
import { useTranslation } from "react-i18next";
import { loadUser } from "../../store/reducers/auth";
import { setLoading } from "../../store/reducers/loader";
import moment from "moment";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 0,
  height: "24px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#4644FF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#3E8BFF",
  },
}));

const CalenderIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 4.4H16.3C16.4857 4.4 16.6637 4.47375 16.795 4.60503C16.9263 4.7363 17 4.91435 17 5.1V16.3C17 16.4857 16.9263 16.6637 16.795 16.795C16.6637 16.9263 16.4857 17 16.3 17H3.7C3.51435 17 3.3363 16.9263 3.20503 16.795C3.07375 16.6637 3 16.4857 3 16.3V5.1C3 4.91435 3.07375 4.7363 3.20503 4.60503C3.3363 4.47375 3.51435 4.4 3.7 4.4H6.5V3H7.9V4.4H12.1V3H13.5V4.4ZM12.1 5.8H7.9V7.2H6.5V5.8H4.4V8.6H15.6V5.8H13.5V7.2H12.1V5.8ZM15.6 10H4.4V15.6H15.6V10Z"
      fill="#9292AB"
    />
  </svg>
);

const CardContentStyled = styled(CardContent)`
  &.top-row {
    padding: 24px;
  }
  height: 100%;
  padding: 20px;
  border: 1px solid #262b3a;

  border-radius: 24px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.12);

  .card-heading {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #8f8f8f;
  }
  .card-heading-sec {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.004em;
    color: #636363;
  }
  .val {
    font-weight: 800;
    font-size: 32px;
  }
  .label {
    color: #8f8f8f;
    font-weight: 500;
    font-size: 18px;
  }
  .txt-white {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.004em;
    color: #ffffff;
    text-align: center;
  }
  .txt-primary {
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.004em;
    color: #ffed53;
    text-align: center;
  }
  .txt-gray {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.004em;
    color: #8f8f8f;
    text-align: center;
  }
  #ini-dep-chart {
    border-right: 0.45px solid #8f8f8f;
  }
`;

const BoxStyled = styled(Box)`
  .mid-row {
    min-height: 275px;
    .MuiList-root.MuiList-padding {
      padding-bottom: 0;
      ${
        "" /* height: 230px;
            overflow-y: scroll; */
      }
    }
    .card {
      min-height: 295px;
    }
    .account-data-ctn {
      .MuiListItem-padding {
        padding: 10px 0 0 0;
        justify-content: space-between;
      }
      .label {
        display: flex;
        gap: 6px;
        align-items: center;
        .MuiListItemText-primary {
          background: rgba(255, 255, 255, 0.02);
          border-radius: 8px;
          font-weight: 600;
          font-size: 13px;
          padding: 6px;
        }
        svg {
          width: 20px;
          height: 20p;
          path {
            color: #636363;
          }
        }
      }
      .value {
        span {
          text-align: right;
        }
        .MuiListItemText-primary {
          font-weight: 700;
          font-size: 14px;
        }
        div.MuiTypography-body1 {
          font-weight: 400;
          font-size: 10px;
          color: #8f8f8f;
          text-align: right;
        }
      }
    }
  }
  .bottom-row {
    .goals-overview-ctn {
      .itm {
        border-radius: 16px;
        padding: 12px;
        border: 1px solid #616184;
        .result-label {
          background: rgba(255, 255, 255, 0.08);
          border-radius: 6px;
          font-weight: 600;
          font-size: 14px;
          padding: 4px 12px;
        }
        .prg-des {
          display: flex;
        }
        .prg-mark {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: auto;
        }
        .current-prg {
          background: #3e8bff;
        }
        .remaining-prg {
          background: #4644ff;
        }
      }
    }
  }
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#111142",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Card = (props) => (
  <CardContentStyled className="card">{props.children}</CardContentStyled>
);

// const buttonSx = {
//     ...(true && {
//       bgcolor: 'light-green',
//       '&:hover': {
//         bgcolor: 'green',
//       },
//     }),
// };

const AccountMetrics = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { login } = useParams();
  const dispatch = useDispatch();
  const metrics = useSelector((state) => state.dashboard.metrics);
  const balanceEquityChart = useSelector(
    (state) => state.dashboard.balanceEquityChart
  );
  console.log(metrics.account_metrics?.deals, "metrics.account_metrics?.deals");

  console.log("metrics", metrics);

  const is_loading = useSelector((state) => state.loader.is_loading);
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [transactionData, setTransactionData] = useState([]);
  console.log(transactionData, "transactionData");
  const days_traded_prg = useMemo(() => {
    if (metrics) {
      const prg =
        (metrics?.account_metrics?.days_traded /
          metrics?.account_metrics?.minimum_days_target) *
        100;
      if (prg > 100) return 100;
      return prg;
    }
    return 0;
  }, [metrics]);

  useEffect(() => {
    if (metrics?.mt5_account_deals) {
      setTransactionData(metrics?.mt5_account_deals);
    }
  }, [metrics]);

  const profit_prg = useMemo(() => {
    if (metrics) {
      if (metrics?.program_objectives?.profit <= 0) return 0;
      const prg =
        (metrics?.program_objectives?.profit /
          metrics?.program_objectives?.profit_target) *
        100;
      if (prg > 100) return 100;
      return prg;
    }
    return 0;
  }, [metrics]);

  const daily_loss_prg = useMemo(() => {
    if (metrics) {
      const remaining_max_loss =
        metrics?.program_objectives?.remaining_daily_max_loss;
      const max_loss_target =
        metrics?.program_objectives?.max_daily_loss_target;
      if (remaining_max_loss >= max_loss_target) return 0;
      const prg =
        (Math.abs(metrics?.program_objectives?.max_daily_loss) /
          metrics?.program_objectives?.max_daily_loss_target) *
        100;
      if (prg > 100) return 100;
      return prg;
    }
    return 0;
  }, [metrics]);

  const max_loss_prg = useMemo(() => {
    if (metrics) {
      const remaining_max_loss =
        metrics?.program_objectives?.remaining_max_loss;
      const max_loss_target = metrics?.program_objectives?.max_loss_target;
      if (remaining_max_loss >= max_loss_target) return 0;
      const prg =
        (Math.abs(metrics?.program_objectives?.max_loss) /
          metrics?.program_objectives?.max_loss_target) *
        100;
      if (prg > 100) return 100;
      return prg;
    }
    return 0;
  }, [metrics]);

  useEffect(() => {
    // Fetch account metrics for the id 'login'
    if (login || is_loading) {
      dispatch(setLoginId(login));
      dispatch(loadUser());
      dispatch(getAccountMetrics(login));
      dispatch(getBalanceEquityChart(login));
    }

    return () => {
      dispatch(setLoading(false));
    };
  }, [dispatch, login]);

  // useEffect(() => {
  //   dispatch(getUserAccounts());
  // }, [dispatch]);

  const handleLoader = () => {
    if (!is_loading) {
      dispatch(loadUser());
      dispatch(getAccountMetrics(login));
    }
  };

  return (
    <BoxStyled p={3} px={0} sx={{}}>
      <Grid container mb={1} sx={{ mt: 3 }}>
        <Button
          title={"Show Credentials"}
          onClick={handleOpen}
          sx={{ color: "#FFED53" }}
        >
          {
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0151 10.6556C11.8093 11.3587 13.9279 10.9853 15.3777 9.5355C17.3304 7.5829 17.3304 4.41709 15.3777 2.46447C13.4251 0.511842 10.2593 0.511842 8.3067 2.46447C6.85687 3.91426 6.48353 6.03288 7.1866 7.8271M9.9964 10.6742L3.82843 16.8422L1.2357 16.6065L1 14.0138L7.168 7.8458M12.5493 5.31568V5.29289"
                stroke="#9292AB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        </Button>
        <Button
          variant="contained"
          disabled={is_loading}
          onClick={handleLoader}
        >
          Refresh Data
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("acc-cred")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Login Id:{" "}
              <b style={{ marginLeft: "10px" }}>
                {metrics && metrics?.login_id}
              </b>
              <br />
              Password:
              <b style={{ marginLeft: "10px" }}>
                {metrics && metrics?._password}
              </b>
              <br />
              Investor Password:
              <b style={{ marginLeft: "10px" }}>
                {metrics && metrics?._investor_password}
              </b>
              <br />
              {/* Server:<b style={{ marginLeft: "10px" }}>{metrics && metrics?.server}</b> */}
              Server:<b style={{ marginLeft: "10px" }}>CBT LIMITED</b>
              <br />
              {/* {t("platform")}:<b style={{ marginLeft: "10px" }}>{metrics && metrics?.platform}</b> */}
              {t("platform")}:<b style={{ marginLeft: "10px" }}>mt5</b>
              <br />
              {/* Broker:<b style={{ marginLeft: "10px" }}>{data.broker}</b><br/>
                        server:<b style={{ marginLeft: "10px" }}>{data.server}</b> <CopyToClipBoard text={data.server}/> */}
            </Typography>
          </Box>
        </Modal>
      </Grid>
      <Grid container spacing={2}>
        {/* START: ROW 1 */}
        <Grid container item spacing={2} xs={12} className="top-row">
          <Grid item xs={12} md={3} lg={3}>
            <Card>
              <Typography className="val" component={"div"}>
                {metrics && dollarUS(metrics?.account_balance)}
              </Typography>
              <Typography className="label" component={"div"}>
                {t("balance")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <Card>
              <Typography className="val" component={"div"}>
                {metrics && dollarUS(metrics?.account_metrics?.profit)}
              </Typography>
              <Typography className="label" component={"div"}>
                {t("profit")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <Card>
              <Typography className="val" component={"div"}>
                {metrics &&
                  dollarUS(metrics?.account_metrics?.avg_win.toFixed(2))}
              </Typography>
              <Typography className="label" component={"div"}>
                {t("avg-win")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <Card>
              <Typography className="val" component={"div"}>
                {metrics &&
                  dollarUS(metrics?.account_metrics?.avg_loss.toFixed(2))}
              </Typography>
              <Typography className="label" component={"div"}>
                {t("avg-loss")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={1.5}>
            <Card>
              <Typography className="val" component={"div"}>
                {metrics && `${metrics?.account_metrics?.win_ratio}`}
              </Typography>
              <Typography className="label" component={"div"}>
                {t("win-ratio")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={1.5}>
            <Card>
              <Typography className="val" component={"div"}>
                {metrics && `${metrics?.account_metrics?.rrr.toFixed(2)}`}
              </Typography>
              <Typography className="label" component={"div"}>
                {t("rrr")}
              </Typography>
            </Card>
          </Grid>
        </Grid>
        {/* END: ROW 1 */}

        {/* START: ROW 2 */}
        <Grid container item spacing={2} className="mid-row">
          <Grid item xs={12} md={12} lg={4}>
            <Card>
              <Typography className="card-heading" component={"div"}>
                {t("account-balance")}
              </Typography>
              <BalanceChart className="balance" data={balanceEquityChart} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Card>
              <Grid container justifyContent={"space-between"} mb={3}>
                <Typography className="card-heading" component={"div"}>
                  {t("acc-loss-analysis")}
                </Typography>
                <Typography className="card-heading-sec" component={"div"}>
                  {t("updated-on")} :{" "}
                  {`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}
                </Typography>
              </Grid>
              <Grid container className="ala-ctn" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="txt-white" component="div">
                    {t("initial-deposit-limit-level")}
                  </Typography>
                  <RadialChart
                    // data={
                    //   metrics &&
                    //   (Math.abs(metrics?.program_objectives?.max_loss) /
                    //     (metrics?.program_objectives?.max_loss_target * 2)) *
                    //     100
                    // }
                    data={metrics && metrics?.program_objectives?.max_loss}
                    chartId={"ini-dep-chart"}
                  />
                  <Typography className="txt-primary" component="div">
                    {t("loss-level")}:{" "}
                    {metrics &&
                      dollarUS(Math.abs(metrics?.program_objectives?.max_loss))}
                  </Typography>
                  <Typography className="txt-gray" component="div">
                    {t("Max Loss")} :{" "}
                    {metrics &&
                      dollarUS(metrics?.program_objectives?.max_loss_target)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="txt-white" component="div">
                    {t("daily-loss-limit-level")}
                  </Typography>
                  <RadialChart
                    data={
                      metrics && metrics?.program_objectives?.max_daily_loss
                    }
                    chartId={"daily-loss-chart"}
                  />
                  <Typography className="txt-primary" component="div">
                    {t("loss-level")}:{" "}
                    {metrics &&
                      dollarUS(
                        Math.abs(metrics?.program_objectives?.max_daily_loss)
                      )}
                  </Typography>
                  <Typography className="txt-gray" component="div">
                    {t("Daily Max Loss")} :{" "}
                    {metrics &&
                      dollarUS(
                        metrics?.program_objectives?.max_daily_loss_target
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3} className="account-data-ctn">
            <Card>
              <Typography className="card-heading" component={"div"}>
                {t("acc-data")}
              </Typography>
              <List>
                <ListItem>
                  <div className="label">
                    <ListItemText>{t("start")} </ListItemText>
                    <ListItemIcon>
                      <Tooltip title={t("start-date")}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </div>
                  <div className="value">
                    <ListItemText>
                      {metrics && metrics?.start_date?.split(" ")[0]}
                    </ListItemText>
                    <Typography component={"div"}>
                      {metrics && metrics?.start_date?.split(" ")[1]}
                    </Typography>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="label">
                    <ListItemText>{t("end")} </ListItemText>
                    <ListItemIcon>
                      <Tooltip title={t("end-date")}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </div>
                  <div className="value">
                    <ListItemText>
                      {metrics?.expiry_date &&
                        moment(metrics?.expiry_date).format("DD/MM/YYYY")}
                    </ListItemText>
                    <Typography component={"div"}>
                      {metrics?.expiry_date &&
                        moment(metrics?.expiry_date).format("hh:mm:ss")}
                    </Typography>
                  </div>
                </ListItem>
                {/* <ListItem>
                  <div className="label">
                    <ListItemText>{t("close")} </ListItemText>
                    <ListItemIcon>
                      <Tooltip title={t("close")}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </div>
                  <div className="value">
                    <ListItemText></ListItemText>
                    <Typography component={"div"}></Typography>
                  </div>
                </ListItem> */}
                <ListItem>
                  <div className="label">
                    <ListItemText>{t("initial-balance")} </ListItemText>
                    <ListItemIcon>
                      <Tooltip title={t("initial-balance")}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </div>
                  <div className="value">
                    <ListItemText>
                      {metrics && dollarUS(metrics?.account_balance)}
                    </ListItemText>
                    <Typography component={"div"}></Typography>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="label">
                    <ListItemText>{t("platform-type")}</ListItemText>
                    <ListItemIcon>
                      <Tooltip title={t("platform-type")}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </div>
                  <div className="value">
                    <ListItemText>{metrics && metrics?.platform}</ListItemText>
                  </div>
                </ListItem>
              </List>
            </Card>
          </Grid>
        </Grid>
        {/* END: ROW 2 */}

        {/* START: ROW 3 */}
        <Grid container item spacing={2} className="bottom-row">
          <Grid item xs={12} md={12} lg={3}>
            <Card>
              <Grid container>
                <Grid item xs={12} pb={1}>
                  <Typography className="card-heading" component={"div"}>
                    {t("goals-overview")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="goals-overview-ctn" spacing={2}>
                {/* <Grid item sm={12} md={6}>
                                    <Box className='itm' component={'div'}>
                                        <Grid container>
                                            <Grid container item xs={12} justifyContent='space-between' alignItems={'center'} mb={1.25}>
                                                <Grid item xs={6} display='flex' alignItems={'center'}>
                                                    <CalenderIcon/>
                                                    <Typography component={'div'}>{t('min-trading-days')}</Typography>
                                                </Grid>
                                                <Grid item xs={6} textAlign='right'>
                                                    <Box component={'span'} className="result-label">{metrics && t(metrics?.goals_overview?.trading_days_status)}</Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container item mb={1}>
                                                <Grid item xs={12}>
                                                    <BorderLinearProgress variant="determinate" value={days_traded_prg} />
                                                </Grid>
                                            </Grid>
                                            <Grid container item>
                                                <Grid container item xs={6}>
                                                    <Grid item xs={2} className='prg-des'>
                                                        <span className='prg-mark current-prg'></span>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <span className='prg-label'>{metrics && metrics?.goals_overview && `${metrics?.goals_overview?.days_traded} ${t('days-completed')}`}</span>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={6}  >
                                                    <Grid item xs={2} className='prg-des'>
                                                        <span className='prg-mark remaining-prg'></span>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <span className='prg-label'>{metrics && `${metrics?.goals_overview?.remaining_days} ${t('days-remaining')}`}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid> */}
                <Grid item sm={12} md={12}>
                  <Box className="itm" component={"div"}>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                        alignItems={"center"}
                        mb={1.25}
                      >
                        <Grid item xs={6} display="flex" alignItems={"center"}>
                          <CalenderIcon />
                          <Typography
                            style={{ whiteSpace: "nowrap" }}
                            component={"div"}
                          >
                            {t("profit-target")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Box
                            component={"span"}
                            className="result-label"
                            style={{ textWrap: "nowrap" }}
                          >
                            {metrics &&
                              t(
                                metrics?.program_objectives?.profit_status?.replace(
                                  "_",
                                  " "
                                )
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item mb={1}>
                        <Grid item xs={12}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={profit_prg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid container item xs={6} alignItems={"baseline"}>
                          <Grid item xs={2} className="prg-des">
                            <span className="prg-mark current-prg"></span>
                          </Grid>
                          <Grid item xs={10}>
                            <span className="prg-label">
                              {metrics &&
                                `${dollarUS(
                                  metrics?.program_objectives?.profit
                                )} ${t("profit")}`}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems={"baseline"}>
                          <Grid item xs={2} className="prg-des">
                            <span className="prg-mark remaining-prg"></span>
                          </Grid>
                          <Grid item xs={10}>
                            <span className="prg-label">
                              {metrics &&
                                `${dollarUS(
                                  metrics?.program_objectives?.remaining_profit
                                )} ${t("pft-remaining")}`}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item sm={12} md={12}>
                  <Box className="itm" component={"div"}>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                        alignItems={"center"}
                        mb={1.25}
                      >
                        <Grid item xs={6} display="flex" alignItems={"center"}>
                          <CalenderIcon />
                          <Typography component={"div"}>
                            {t("daily-loss")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Box
                            component={"span"}
                            className="result-label"
                            style={{ textWrap: "nowrap" }}
                          >
                            {metrics &&
                              t(
                                metrics?.program_objectives?.max_daily_loss_status?.replace(
                                  "_",
                                  " "
                                )
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item mb={1}>
                        <Grid item xs={12}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={daily_loss_prg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid container item xs={6} alignItems={"baseline"}>
                          <Grid item xs={2} className="prg-des">
                            <span className="prg-mark current-prg"></span>
                          </Grid>
                          <Grid item xs={10}>
                            <span className="prg-label">
                              {metrics &&
                                `${dollarUS(
                                  Math.abs(
                                    metrics?.program_objectives?.max_daily_loss
                                  )
                                )} ${t("daily-loss")}`}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid container item xs={6} alignItems={"baseline"}>
                          <Grid item xs={2} className="prg-des">
                            <span className="prg-mark remaining-prg"></span>
                          </Grid>
                          <Grid item xs={10}>
                            <span className="prg-label">
                              {metrics &&
                                `${dollarUS(
                                  metrics?.program_objectives
                                    ?.remaining_daily_max_loss
                                )} ${t("dl-left")}`}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item sm={12} md={12}>
                  <Box className="itm" component={"div"}>
                    <Grid container>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                        alignItems={"center"}
                        mb={1.25}
                      >
                        <Grid item xs={6} display="flex" alignItems={"center"}>
                          <CalenderIcon />
                          <Typography component={"div"}>
                            {t("Max Loss")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <Box component={"span"} className="result-label">
                            {metrics &&
                              t(metrics?.program_objectives?.max_loss_status)}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item mb={1}>
                        <Grid item xs={12}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={max_loss_prg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid container item xs={6}>
                          <Grid item xs={2} className="prg-des">
                            <span className="prg-mark current-prg"></span>
                          </Grid>
                          <Grid item xs={10}>
                            <span className="prg-label">
                              {metrics &&
                                `${dollarUS(
                                  Math.abs(
                                    metrics?.program_objectives?.max_loss
                                  )
                                )} ${t("max-loss")}`}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid container item xs={6}>
                          <Grid item xs={2} className="prg-des">
                            <span className="prg-mark remaining-prg"></span>
                          </Grid>
                          <Grid item xs={10}>
                            <span className="prg-label">
                              {metrics &&
                                `${dollarUS(
                                  metrics?.program_objectives
                                    ?.remaining_max_loss
                                )} ${t("ml-remaining")}`}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={9} className="Trading_account-history">
            <Card>
              <Typography className="card-heading" component={"div"}>
                {t("trade-acc-history")}
              </Typography>
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  disableColumnMenu
                  disableColumnFilter
                  pageSize={pageSize}
                  getRowId={(row) => row.dealId}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  columns={[
                    {
                      field: "direction",
                      headerName: t("type"),
                      width: 130,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                      renderCell: (params) => {
                        if (
                          params.value === "BUY" ||
                          params.value === "BALANCE"
                        )
                          return (
                            <Button
                              sx={{
                                color: "#1AFF15",
                                background: "rgba(26, 255, 21, 0.1)",
                                borderRadius: "8px",
                              }}
                            >
                              {params.value}
                            </Button>
                          );
                        if (params.value === "SELL")
                          return (
                            <Button
                              sx={{
                                color: "#FF3C3C",
                                background: "rgba(255, 60, 60, 0.1)",
                                borderRadius: "8px",
                              }}
                            >
                              {params.value}
                            </Button>
                          );
                        return params.value;
                      },
                    },
                    {
                      field: "positionId",
                      headerName: "ID",
                      width: 130,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                    },
                    {
                      field: "openTimestamp",
                      headerName: t("open-date"),
                      width: 180,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                      renderCell: (params) => {
                        return (
                          <Box
                            component={"div"}
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>{new Date(params.value).toDateString()}</div>
                            <div style={{ color: "gray" }}>
                              {new Date(params.value).toLocaleTimeString()}
                            </div>
                          </Box>
                        );
                      },
                    },
                    {
                      field: "symbol",
                      headerName: t("symbol"),
                      width: 180,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                    },
                    {
                      field: "volume",
                      headerName: t("Volume"),
                      width: 180,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                    },
                    {
                      field: "pnl",
                      headerName: t("profit"),
                      width: 180,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                      renderCell: (params) => {
                        if (params.value > 0)
                          return (
                            <Box component="span" sx={{ color: "#1AFF15" }}>
                              {dollarUS(params.value)}
                            </Box>
                          );
                        if (params.value < 0)
                          return (
                            <Box component="span" sx={{ color: "#FF3C3C" }}>
                              {dollarUS(params.value)}
                            </Box>
                          );
                        return dollarUS(params.value);
                      },
                    },
                    {
                      field: "closePrice",
                      headerName: t("Price"),
                      width: 180,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                    },
                    {
                      field: "dealId",
                      headerName: t("Deal"),
                      width: 100,
                      sortable: false,
                      headerClassName: "super-app-theme--header",
                      cellClassName: "super-app-theme--cell",
                    },
                  ]}
                  rows={metrics.account_metrics?.deals || []}
                  sx={{
                    border: "none",
                    "& .MuiDataGrid-columnHeaders": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      border: "none",
                    },
                    "& .MuiDataGrid-footerContainer ": {
                      border: "none",
                    },
                    "& .MuiDataGrid-row": {
                      border: "1px solid rgba(255, 255, 255, 0.02)",
                      borderRadius: "12px",
                    },
                  }}
                  columnVisibilityModel={{
                    id: false,
                  }}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
        {/* END: ROW 3 */}
      </Grid>
    </BoxStyled>
  );
};

export default AccountMetrics;
