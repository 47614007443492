import { Button, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextareaAutosize, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useRef, useState } from 'react'
import TextInput from '../../components/Form/TextInput';
import VectorImage from "../../assets/images/Vector.svg"
import { useFormik } from 'formik';
import * as yup from "yup";
import { createSupportCase } from '../../store/reducers/support';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../hooks/useLoader';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga"
import { useEffect } from 'react';

const validationSchema = yup.object({
  caseType: yup
    .string("Select a case type")
    .required("Case type is required"),
  subject: yup
    .string("Enter subject")
    .required("Subject is required")
    .max(155, "Max length 155"),
  // category: yup
  //   .string('Select category')
  //   .required("Category required"),
  severity: yup
    .string("Select severity")
    .required("Severity required"),
  attachment: yup
    .string('Upload an image'),
  description: yup
    .string("Enter description")
    .max(1500, "Max length 1500"),
});

const CreateCase = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const {t} = useTranslation();
  const [attachment, setAttachment] = useState('');
  const [attachmentFileName, setAttachmentFileName] = useState('');
  const setLoader = useLoader();

  const fileref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      caseType: "",
      subject: "",
      // category: "",
      severity: "",
      attachment: "",
      description: ""

    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoader(true)
      let form_data = new FormData();
      form_data.append("case_type", values.caseType);
      form_data.append("type", '');
      form_data.append("subject", values.subject);
      form_data.append("severity", values.severity);
      form_data.append("comment", '');
      form_data.append("description", values.description);
      if(attachment){
        form_data.append("attachment", attachment || null);
      }

      dispatch(createSupportCase(form_data))
          .then(res=>{
            setLoader(false)
            resetForm();
            navigate('/support')
          })
          .catch(error=>{
            setLoader(false)
          });
    },
  })

  const handleAttachmentUpload = (e) => {
    
    if (e.target.files && e.target.files[0]) {
      setAttachment(e.target.files[0]);
    }
    var file = fileref.current.files[0];
    var reader = new FileReader();
    setAttachmentFileName(file.name);
    reader.readAsDataURL(file);
};

  // CONSTANTS
  const caseTypes = [
    {
      originalText: "Account & Billing Support",
      primaryText:t('account-n-billing-spt'),
      secondaryText: t('account-n-billing-spt-sm')
    },
    {
      originalText: "Feedback",
      primaryText: t('feedback'),
      secondaryText: t('feedback-sm')
    },
    {
      originalText: "Technical Support",
      primaryText: t('tech-spt'),
      secondaryText: t('tech-spt-sm')
    }];
  // const catagories = ['1','2', '3'];
  const severities = [
    {
      originalText: 'Low',
      primaryText:  t('low')
    }, 
    {
      originalText: 'High',
      primaryText:  t('high')
    }, 
    {
      originalText: 'Critical',
      primaryText:  t('critical')
    }];

  return (
    <Grid container p={3}>
      <Grid item>
        <Typography component={'h3'} 
          sx={{
            fontWeight: '700',
            fontSize: '18px',
            paddingBottom: '24px'
            }}>{t('create-case')}
        </Typography>
      </Grid>
      <Grid container item spacing={2} component={'form'} onSubmit={formik.handleSubmit}>
        <Grid item xs={12}>
            <FormControl error={formik.touched.caseType && Boolean(formik.errors.caseType)}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                display="flex"
                value={formik.values.caseType}
              >{
                caseTypes.map((item, idx)=>{
                  return (
                    <FormControlLabel 
                      key={idx}
                      onClick={formik.handleChange} 
                      value={item.originalText} 
                      name="caseType"
                      control={<Radio color="secondary" />} 
                      error={(formik.touched.caseType && formik.errors.caseType) || ''}
                      helpertext={formik.touched.caseType && formik.errors.caseType}
                      label={
                        <Grid container alignItems={'center'} gap={1}>
                          <Typography className='primary-text'>{item.primaryText}</Typography>
                          <Typography className='secondary-text'>{item.secondaryText}</Typography>
                        </Grid>
                      } 
                      sx={{
                        'span.MuiFormControlLabel-label':{
                          '& .primary-text':{
                            color: '#8f8f8f',
                            fontWeight: '600',
                            fontSize: '20px'
                          },
                          '& .secondary-text':{
                            color: '#636363',
                            fontWeight: 500,
                            fontSize: '16px'
                          },
                        },
                        '.Mui-checked + span.MuiFormControlLabel-label':{
                          '& .primary-text':{
                            color: '#ff8049',
                          },
                          '& .secondary-text':{
                            color: '#bcbcbc',
                          }
                        },
                        "& .Mui-checked": {
                          '.PrivateSwitchBase-input + span svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium':{
                            fill: '#ff8049'
                          }
                        }
                      }}
                    />
                  )
                })
              }
              </RadioGroup>
              <FormHelperText>{formik.touched.caseType && formik.errors.caseType}</FormHelperText>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label={t('subject')}
            placeholder={t('subject-plchdr')}
            value={formik.values.subject} 
            name="subject"
            onChange={formik.handleChange}
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={formik.touched.subject && formik.errors.subject}
          />
        </Grid>
        <Grid container item spacing={2}>
          {/* <Grid item xs={12} md={4} >
            <FormControl
              error={formik.touched.category && Boolean(formik.errors.category)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary':{
                  background: 'rgba(255, 255, 255, 0.02)',
                  borderRadius: '12px',
                  color: '#fff'
                }
              }}
            >
                <label>{t('category')}</label>
                <Select
                    displayEmpty
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    input={<OutlinedInput />}
                    name="category"
                    // MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Category' }}
                    >
                    {catagories.map((category, idx) => (
                        <MenuItem
                        key={idx}
                        value={category}
                        >
                        {category}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{formik.touched.category && formik.errors.category}</FormHelperText>
            </FormControl>
          </Grid> */}
          <Grid item 
            xs={12} md={6} 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary':{
                background: 'rgba(255, 255, 255, 0.02)',
                borderRadius: '12px',
                color: '#fff'
              }
            }}
          >
            <FormControl error={formik.touched.severity && Boolean(formik.errors.severity)}>
              <label>{t('severity')}</label>
              <Select
                  displayEmpty
                  name="severity"
                  value={formik.values.severity}
                  onChange={formik.handleChange}
                  input={<OutlinedInput />}
                  // MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Severity' }}
                  >
                  {severities.map((severity, idx) => (
                      <MenuItem
                      key={idx}
                      value={severity.originalText}
                      >
                      {severity.primaryText}
                      </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{formik.touched.severity && formik.errors.severity}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} 
            sx={{
                  display: 'flex',
                  flexDirection: 'column'}}
          >
            <label>{t('attachment')}</label>
            <Box sx={{
              padding:'14px',
              position:'relative',
              background: 'rgba(255, 255, 255, 0.02)',
              borderRadius: '12px',
              height: '100%',
              maxHeight: '60px',
              '& input':{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'aqua',
                opacity: 0,
                '& input':{
                  height: '100%'
                },
              },
              '& div':{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    justifyContent: 'start',
                    img: {
                      width:'24px',
                      height: '24px'
                    }
                }
            }}>
              <input 
                name="attachment"
                onChange={handleAttachmentUpload}
                type='file'
                ref={fileref}
              />
              <div >
                <img src={VectorImage} alt=''/>
                <Typography sx={{color:'#636363'}}>{attachmentFileName ? attachmentFileName:t('choose-file')}</Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '& textarea':{
                background: 'rgba(255, 255, 255, 0.02)',
                borderRadius: '12px',
                color: '#fff',
                padding: '14px',
                border:'none',
                '& :focus':{
                  outline: 'none !important',
                  border: '1px solid #8F8F8F'
                }
              },
            }}
          >
            <label>{t('description')}</label>
            <TextareaAutosize
              minRows={3}
              name="description"
              aria-label="Description"
              placeholder={t('desc-plchdr')}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              value={formik.values.description}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Button 
              fullWidth 
              className="pay-btn"
              color="secondary" 
              variant="contained" 
              type="submit"
              onClick={formik.handleSubmit}
              sx={{
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '140%',
                letterSpacing: '0.004em',
                color: 'rgba(255, 255, 255, 0.02)',
                borderRadius: '12px',
                textTransform: 'capitalize',
                padding: '14px'
              }}
            >
                {t('create-case')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateCase
