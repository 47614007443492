import Axios from "axios";

// Axios.defaults.baseURL = "http://localhost:8000/";
// Axios.defaults.baseURL = "https://api.macandgray.com/";
// Axios.defaults.baseURL = "http://18.132.64.204:8000/";
// Axios.defaults.baseURL = "http://18.171.235.177/";
// Axios.defaults.baseURL = "https://api.macandgray.com/";
Axios.defaults.baseURL = "https://backend.macandgray.com/";


export const axiosInstance = Axios.create({});
