import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { returnErrors } from "../../../store/reducers/error";
import { returnMessages } from "../../../store/reducers/message";
import { checkPaymentStatus } from "../../../store/reducers/payment";
import ReactGA from "react-ga";
import axios from "axios";
import { baseUrl } from "../../../utilities/apis/apis";
const Success = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { billing_info } = useSelector((state) => state.payment);

  const [data, setdata] = useState({
    email: billing_info.email,
    full_name: billing_info.full_name,
    contact: billing_info.contact,
  })
  const formdata = new FormData()
  formdata.append("email", data.email);
  formdata.append("full_name", data.full_name);
  formdata.append("contact", data.contact);

  useEffect(() => {
    const paymentMethod = localStorage.getItem("payMethod");
    if (paymentMethod) {
      dispatch(checkPaymentStatus(paymentMethod))
        .then((res) => {
          dispatch(
            returnMessages(
              "coinbase transactions are crypto transactions, it will take some time to reflect in the blockchain, your account will be funded soon",
              200,
              "Congratulations"
            )
          );
          navigate("/payments/history");
          localStorage.removeItem("payMethod");
        })
        .catch((error) => {
          dispatch(returnErrors(error, 400));
          navigate("/payments/history");
        });
    } else {
      dispatch(checkPaymentStatus())
        .then((res) => {
          dispatch(
            returnMessages("Payment successfull", 200, "Congratulations")
          ).then(
            axios.post(`${baseUrl}/payment/create/hyros/order/`, formdata)
              .then((res) => console.log(res))
              .catch((error) => console.log(error))

          )
          navigate("/payments/history");
        })
        .catch((error) => {
          dispatch(returnErrors(error, 400));
          navigate("/payments/history");
        });
    }
  }, [dispatch, navigate]);

  return (
    <Box>
      <Typography component={"h1"} textAlign="center" fontSize={"38px"}>
        Please wait.....
      </Typography>
      <Typography textAlign="center" color={"gray"}>
        Don't reload this page
      </Typography>
    </Box>
  );
};

export default Success;
