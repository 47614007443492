import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
const initialState = {
  AffiliateUserList: [],
  loading: false,
  error: null,
  userStats: [],
  AffiliateCodeList: [],
};
const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: initialState,
  reducers: {
    fetchAffiliateRequest: (state, action) => {
      // state.loading = false;
      state.error = action.payload;
    },
    fetchAffiliateSuccess: (state, action) => {
      // state.loading = false;
      state.AffiliateUserList = action.payload;
    },
    fetchAffiliateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchAffiliateUserStatsRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    fetchAffiliateUserStatsSuccess: (state, action) => {
      state.userStats = action.payload;
    },
    fetchAffiliateUserStatsFailure: (state, action) => {
      state.error = action.payload;
    },
    fetchAffiliateCodeListRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAffiliateCodeListSuccess: (state, action) => {
      state.loading = false;
      state.AffiliateCodeList = action.payload;
    },
    fetchAffiliateCodeListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //     fetchSearchAffiliateListRequest: (state, action) => {
    //       state.loading = true;
    //     },
    //     fetchSearchAffiliateListSuccess: (state, action) => {
    //       state.AffiliateList = action.payload;
    //     },
    //     fetchSearchAffiliateListFailure:(state,action)=>{
    //    state.error= action.payload
    //     }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  fetchAffiliateRequest,
  fetchAffiliateSuccess,
  fetchAffiliateFailure,
  fetchAffiliateUserStatsRequest,
  fetchAffiliateUserStatsSuccess,
  fetchAffiliateUserStatsFailure,
  fetchAffiliateCodeListRequest,
  fetchAffiliateCodeListSuccess,
  fetchAffiliateCodeListFailure,
  //   fetchSearchAffiliateListRequest,
  //   fetchSearchAffiliateListSuccess,
  //   fetchSearchAffiliateListFailure
} = affiliateSlice.actions;
export default affiliateSlice.reducer;
