import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BodyText from "../ReusableComponents/BodyText";

import "./style.scss";
import SideMenuItems from "../../../constants/SideMenuitem";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMobile from "../../../hooks/useMobile";
import SimpleBottomNavigation from "../BottomNavigation";
import Header from "./Header";
import LogoImg from "./../../../assets/images/mac/Logo.png";

const drawerWidth = 240;

export default function SideMenu(props) {
  let location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const handleDiscord = () => {
    window.location.href = "https://discord.com/invite/3bugxZwETn";
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (e) => {
    if (e === "https://discord.com/invite/3bugxZwETn") {
      handleDiscord();
    } else if (isMobile) {
      handleDrawerToggle();
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Drawer
        id="sidebar-dsk"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            justifyContent: "space-around",
          },
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
      >
        <div className="newHeader">
          <div className="nh-T">
            <img className="LogoImgHeader" src={LogoImg} alt="" />
          </div>
          <div className="nh-M">
            <ul>
              {isAuthenticated &&
                SideMenuItems.map((item, index) => {
                  if (item.hide) return false;
                  return (
                    <Link
                      to={item.link ? null : item.path}
                      onClick={() => handleClick(item.link)}
                      key={index}
                      // onClick={item[7].link}
                    >
                      <ListItem
                        className={
                          location.pathname.search(item.path) !== -1
                            ? "nav-active"
                            : ""
                        }
                        key={item.label}
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText
                            style={{ textWrap: "nowrap" }}
                            primary={t(item.label)}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  );
                })}
            </ul>
          </div>
          <div className="nh-B">
            <div className="upGradeToPro">
              <div className="upGradeToPro-t">
                <svg
                  width="67"
                  height="67"
                  viewBox="0 0 67 67"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_209_4991)">
                    <path
                      d="M14.4657 49.6392C18.427 50.1508 21.8829 51.7595 24.3298 53.9757C24.8423 54.4399 24.6514 55.9175 24.0379 56.2362C21.1083 57.7582 17.3571 58.4362 13.3958 57.9246C9.43453 57.4131 5.97864 55.8044 3.5317 53.5882C3.01921 53.124 3.2101 51.6464 3.82361 51.3276C6.75321 49.8057 10.5044 49.1277 14.4657 49.6392Z"
                      fill="#FCD27C"
                    />
                    <path
                      d="M14.9304 44.0921C19.1668 45.9287 22.4904 48.8225 24.4988 52.0676C24.9193 52.7472 24.2342 54.3275 23.4507 54.4852C19.7092 55.2377 15.3252 54.7896 11.089 52.953C6.85258 51.1164 3.52899 48.2228 1.52056 44.9775C1.10004 44.2979 1.78515 42.7176 2.56865 42.5599C6.31014 41.8076 10.694 42.2556 14.9304 44.0921Z"
                      fill="#FBC963"
                    />
                    <path
                      d="M22.5572 35.1873C24.254 39.4815 24.5581 43.8779 23.6835 47.5927C23.5003 48.3707 21.8984 49.0037 21.233 48.5611C18.0552 46.4474 15.2721 43.0307 13.5753 38.7365C11.8785 34.4423 11.5743 30.0459 12.449 26.3311C12.6321 25.5531 14.2341 24.9202 14.8995 25.3628C18.0773 27.4763 20.8604 30.893 22.5572 35.1873Z"
                      fill="#FABC3C"
                    />
                    <path
                      d="M16.3744 35.3489C20.6939 39.2323 23.5025 44.012 24.6016 48.6856C24.8317 49.6643 23.3831 51.2757 22.3855 51.1507C17.6216 50.5533 12.5711 48.2674 8.25161 44.3839C3.93208 40.5006 1.12346 35.7208 0.0244018 31.0473C-0.205763 30.0685 1.24288 28.4571 2.24053 28.5822C7.00423 29.1795 12.0548 31.4656 16.3744 35.3489Z"
                      fill="#FABC3C"
                    />
                    <path
                      d="M52.5325 49.6392C48.5712 50.1508 45.1154 51.7595 42.6684 53.9757C42.1559 54.4399 42.3468 55.9175 42.9603 56.2362C45.8899 57.7582 49.6411 58.4362 53.6024 57.9246C57.5637 57.4131 61.0196 55.8044 63.4665 53.5882C63.979 53.124 63.7881 51.6464 63.1746 51.3276C60.245 49.8057 56.4938 49.1277 52.5325 49.6392Z"
                      fill="#FBC963"
                    />
                    <path
                      d="M52.0694 44.0921C47.833 45.9287 44.5095 48.8225 42.501 52.0676C42.0805 52.7472 42.7656 54.3275 43.5491 54.4852C47.2906 55.2377 51.6746 54.7896 55.9109 52.953C60.1473 51.1164 63.4708 48.2228 65.4793 44.9775C65.8998 44.2979 65.2147 42.7176 64.4312 42.5599C60.6897 41.8076 56.3058 42.2556 52.0694 44.0921Z"
                      fill="#FBC963"
                    />
                    <path
                      d="M44.4405 35.1873C42.7437 39.4815 42.4396 43.8779 43.3142 47.5927C43.4974 48.3707 45.0993 49.0037 45.7647 48.5611C48.9425 46.4474 51.7256 43.0307 53.4224 38.7365C55.1193 34.4423 55.4234 30.0459 54.5487 26.3311C54.3656 25.5531 52.7636 24.9202 52.0982 25.3628C48.9204 27.4763 46.1373 30.893 44.4405 35.1873Z"
                      fill="#FBC963"
                    />
                    <path
                      d="M50.6247 35.3489C46.3051 39.2323 43.4965 44.012 42.3974 48.6856C42.1673 49.6643 43.6159 51.2757 44.6136 51.1507C49.3774 50.5533 54.4279 48.2674 58.7474 44.3839C63.0669 40.5006 65.8756 35.7208 66.9746 31.0473C67.2048 30.0685 65.7561 28.4571 64.7585 28.5822C59.9948 29.1795 54.9442 31.4656 50.6247 35.3489Z"
                      fill="#FABC3C"
                    />
                    <path
                      d="M33.4991 67C45.0372 67 54.3907 57.6465 54.3907 46.1083C54.3907 34.5702 45.0372 25.2167 33.4991 25.2167C21.9609 25.2167 12.6074 34.5702 12.6074 46.1083C12.6074 57.6465 21.9609 67 33.4991 67Z"
                      fill="#F26122"
                    />
                    <path
                      d="M33.4998 62.7604C42.6965 62.7604 50.1519 55.305 50.1519 46.1083C50.1519 36.9116 42.6965 29.4562 33.4998 29.4562C24.3031 29.4562 16.8477 36.9116 16.8477 46.1083C16.8477 55.305 24.3031 62.7604 33.4998 62.7604Z"
                      fill="#FFF8EC"
                    />
                    <path
                      d="M42.2115 42.0099L38.1143 41.02C37.7332 40.9279 37.4002 40.6968 37.1804 40.372L34.8186 36.8808C34.5329 36.4586 34.0953 36.232 33.6449 36.1954C33.1946 36.232 32.7568 36.4586 32.4712 36.8808L30.1094 40.372C29.8898 40.6968 29.5567 40.9279 29.1755 41.02L25.0784 42.0099C23.967 42.2784 23.5039 43.6048 24.2065 44.5069L26.797 47.8319C27.0379 48.1413 27.1548 48.5293 27.1246 48.9203L26.8 53.1228C26.712 54.2627 27.8304 55.1132 28.9054 54.7236L32.8682 53.2874C33.1186 53.1966 33.3857 53.1732 33.6449 53.2134C33.9042 53.1734 34.1712 53.1968 34.4216 53.2874L38.3844 54.7236C39.4593 55.1131 40.5778 54.2627 40.4898 53.1228L40.1652 48.9203C40.135 48.5293 40.2519 48.1413 40.4928 47.8319L43.0834 44.5069C43.786 43.6048 43.3229 42.2784 42.2115 42.0099Z"
                      fill="#F26122"
                    />
                    <path
                      d="M20.8887 0.437866V11.4732L33.5004 24.0849L46.1122 11.4732V0.437866H20.8887Z"
                      fill="#F26122"
                    />
                    <path
                      d="M33.5004 18.4289C32.9805 18.4289 32.4606 18.2311 32.0649 17.8353L20.8887 6.659V11.4733L33.5004 24.085L46.1122 11.4733V6.659L34.9359 17.8353C34.5403 18.2309 34.0204 18.4289 33.5004 18.4289Z"
                      fill="#F26122"
                    />
                    <path
                      d="M32.1176 21.2202L21.3853 10.4879C20.5805 9.68308 19.2756 9.68308 18.4708 10.4879C17.666 11.2927 17.666 12.5976 18.4708 13.4024L33.5006 28.4324L48.5305 13.4025C49.3353 12.5977 49.3353 11.2928 48.5305 10.488C47.7257 9.68321 46.4208 9.68321 45.616 10.488L34.8837 21.2203C34.1197 21.9841 32.8814 21.9841 32.1176 21.2202Z"
                      fill="#FCD27C"
                    />
                    <path
                      d="M18.4699 10.5123L31.9173 23.9597C32.7913 24.8338 34.2085 24.8338 35.0825 23.9597L48.5298 10.5123C48.5337 10.5084 48.5369 10.504 48.5408 10.5001C48.537 10.4961 48.5337 10.4918 48.5298 10.4879C47.725 9.68308 46.4201 9.68308 45.6153 10.4879L34.8829 21.2202C34.1191 21.984 32.8807 21.984 32.1169 21.2202L21.3845 10.4879C20.5797 9.68308 19.2748 9.68308 18.47 10.4879C18.4661 10.4918 18.4629 10.4961 18.459 10.5001C18.4628 10.504 18.4659 10.5084 18.4699 10.5123Z"
                      fill="#FCD27C"
                    />
                    <path
                      d="M33.5004 29.887C36.2211 29.887 38.4266 27.6814 38.4266 24.9608C38.4266 22.2401 36.2211 20.0345 33.5004 20.0345C30.7798 20.0345 28.5742 22.2401 28.5742 24.9608C28.5742 27.6814 30.7798 29.887 33.5004 29.887Z"
                      fill="#F26122"
                    />
                    <path
                      d="M33.5 27.785C35.0598 27.785 36.3243 26.5206 36.3243 24.9608C36.3243 23.401 35.0598 22.1365 33.5 22.1365C31.9402 22.1365 30.6758 23.401 30.6758 24.9608C30.6758 26.5206 31.9402 27.785 33.5 27.785Z"
                      fill="#AC4518"
                    />
                    <path
                      d="M48.017 4.90453H18.9832C18.149 4.90453 17.4727 4.22823 17.4727 3.39401V1.51052C17.4727 0.676306 18.149 0 18.9832 0H48.0172C48.8512 0 49.5276 0.676306 49.5276 1.51052V3.39414C49.5276 4.22836 48.8512 4.90453 48.017 4.90453Z"
                      fill="#FBC963"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_209_4991">
                      <rect width="67" height="67" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Upgrade to Pro</span>
              </div>
              <div className="upGradeToPro-B">140$ off on annual pack</div>
            </div>
            <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
              {user && user.photo ? (
                <img
                  src={user.photo}
                  width="70"
                  height="70"
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                false
              )}
              <BodyText textStyle={{ fontWeight: 800 }}>
                {user?.first_name} {user?.last_name}
              </BodyText>
              <BodyText
                two
                textStyle={{ color: "#FFFFFF", opacity: 0.5, fontWeight: 500 }}
              >
                {user?.username}
              </BodyText>
            </Box>
          </div>
        </div>
      </Drawer>

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "#0A0A28", p: 3, pb: 7, width: "73%" }}
      >
        <Toolbar />
        {props.children}
      </Box>
      <SimpleBottomNavigation />
    </Box>
  );
}

// <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       <Header drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
//       <Drawer
//         id="sidebar-dsk"
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//             justifyContent: 'space-around'
//           },
//         }}
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//         variant={isMobile ? "temporary" : "permanent"}
//         anchor="left"
//       >
//         <Toolbar id="logo-toolbar">
//         </Toolbar>
//         <List>
//           {isAuthenticated && SideMenuItems.map((item, index) => {
//             if(item.hide) return false;
//             return (
//               <Link to={item.path} onClick={isMobile && handleDrawerToggle} key={index}>
//                 <ListItem className={location.pathname.search(item.path)!==-1 ? "nav-active" : ""} key={item.label} disablePadding>
//                   <ListItemButton>
//                     <ListItemIcon>
//                       {item.icon}
//                     </ListItemIcon>
//                     <ListItemText primary={t(item.label)} />
//                   </ListItemButton>
//                 </ListItem>
//               </Link>
//             )
//           })}
//         </List>
//         <Box sx={{ textAlign: 'center' }}>
//           <div style={{margin: '1rem 0'}}><a href="https://macandgray.com" target='_blank' rel="noreferrer" style={{color: "#9292AB"}}>macandgray.com</a></div>
//           {user && user.photo ? <img src={user.photo} width="70" height="70" alt='' style={{borderRadius:'50%'}}/>:false}
//           <BodyText textStyle={{ fontWeight: 800 }}>
//             {user?.first_name} {user?.last_name}
//           </BodyText>
//           <BodyText two textStyle={{ color: '#FFFFFF', opacity: 0.5, fontWeight: 500 }}>
//             {user?.username}
//           </BodyText>
//         </Box>
//       </Drawer>
//       <Box
//         component="main"
//         sx={{ flexGrow: 1, bgcolor: '#111142', p: 3, pb: 7 }}
//       >
//         <Toolbar />
//         {props.children}
//       </Box>
//         <SimpleBottomNavigation/>
//     </Box>
