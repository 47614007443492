import { Box, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { traderAffiliateRefList } from "../../../utilities/apis/apis";

const Popup = ({
  open,
  onClose,
  id,
  isLoadingComponents,
  setisLoadingComponents,
}) => {
  const [referredList, setReferredList] = useState([]);
  const idToken = useAuth();

  const fetchRefferalList = async () => {
    if (open === true) {
      const data = await traderAffiliateRefList(idToken, id);
      setReferredList(data);
    }
  };
  useEffect(() => {
    fetchRefferalList().then(() => {
      setisLoadingComponents(false);
    });
  }, [open]);

  const handleClose = () => {
    onClose();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="address-modal"
          style={{
            border: "1px solid #f26122",
            Width: "30px",
            maxHeight: "500px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Referred List</p>
            <p onClick={handleClose} style={{ cursor: "pointer" }}>
              X
            </p>
          </div>
          <div className="reffered_list_loaders">
            {isLoadingComponents ? <CircularProgress /> : ""}
          </div>
          <div>
            {!isLoadingComponents ? (
              <ul style={{ padding: "0" }}>
                {referredList.length > 0 ? (
                  referredList.map((user, idx) => {
                    return (
                      <li
                        style={{
                          listStyle: "none",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div style={{ fontSize: "18px" }}>{idx + 1}</div>
                        <div style={{ fontSize: "18px" }}>{user.email}</div>
                      </li>
                    );
                  })
                ) : (
                  <p>No referred users</p>
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Popup;
