import React, { useEffect, useRef, useState } from "react";
import { usePagination, useTable } from "react-table";
import { TabPanel } from "../../../components/Reusable/TabPanel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./style.scss";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import addCircle from "../../../assets/images/Add Circle.svg";
import { useAuth } from "../../../hooks/useAuth";
import {
  fetchAffiliateRequest,
  fetchAffiliateUserStatsRequest,
} from "../../../store/reducers/affiliateSlice";
import {
  baseUrl,
  getAllTradersRequest,
  traderAffiliateRefList,
} from "../../../utilities/apis/apis";
import Index from "../AffiliateOverview";
import "../affiliateStyle.scss";
import "../affiliateTable.scss";
import Popup from "./Popup";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip, Typography } from "@mui/material";
const AffiliateList = () => {
  const [referredList, setReferredList] = useState(["kk"]);

  const { email } = useParams();

  const searchRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const idToken = useAuth();
  const [value, setValue] = React.useState(0);
  const [tab1VertValue, setTab1VertValue] = React.useState(0);
  const [tab2VertValue, setTab2VertValue] = React.useState(0);
  const [showAccType, setShowAccType] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingComponent, setIsLoadingComponent] = useState(false);
  const [page, setPage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [pageSize, setPageSize] = useState();
  const [isChallengeDropdown, setChallengeDropdown] = useState(false);

  const dispatch = useDispatch();
  const totalPages = 5;

  const addPadding = (tableData, pageNumber) => {
    const padding = [];
    for (let index = 0; index < (pageNumber - 1) * 25; index++) {
      padding.push({
        id: `padding-${index}`,
        name: "",
        accountNumber: "",
        startDate: "",
        endDate: "",
        percentageOfGoodTrades: "",
        percentageOfBadTrades: "",
      });
    }
    return [...padding, ...tableData];
  };
  const [search, setSearch] = useState();
  const [allTraders, setAllTraders] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedUser, setSearchUser] = useState([]);
  const [searchDropDown, setSearchDropDown] = useState(false);

  const handlePagination = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      try {
        setIsLoadingComponent(true);
        setAllTraders([]);
        const data = await getAllTradersRequest(idToken, pageNumber);
        setAllTraders(addPadding(data.results, pageNumber));
        setCurrentPage(pageNumber);
        setIsLoadingComponent(false);
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    }
  };

  //
  const [totalTraders, setTotalTraders] = useState(0);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getAllTradersRequest(idToken, 1);
  //       setTotalTraders(data.count);
  //       setAllTraders(data.results);

  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching traders:", error);
  //     }
  //   };
  //   fetchData();
  // }, [idToken]);

  //   useEffect(() => {
  //     dispatch(fetchAffiliateRequest(idToken));
  // }, [dispatch, idToken]);

  const [isPopup, setIsPopup] = useState(false);
  const popupRef = useRef(null);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //
  //     if (popupRef.current && !popupRef.current.querySelector('referred_popup').classList.contains(event.target)) {
  //       setIsPopup(false);
  //     }
  //   }

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  const dummyData = [
    {
      id: 1,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 2,
      name: "Carry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 3,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 4,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 5,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 6,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 7,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 8,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
    {
      id: 9,
      name: "Harry Potter",
      accountNumber: "20230801",
      startDate: "2023-08-01",
      endDate: "2023-08-22",
      percentageOfGoodTrades: "25K",
      percentageOfBadTrades: "27k",
    },
  ];
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    navigate(`/overview/${id}`);
  };
  const handleTab1VertValue = (event, newValue) => {
    setTab1VertValue(newValue);
  };

  const handleTab2VertValue = (event, newValue) => {
    setTab2VertValue(newValue);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
  };

  const handleScrollToDiv = (href) => {
    const targetDiv = document.getElementById(href.replace("#", ""));
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const handleNavigateToCreateAffiliateCoupan = () => {
    navigate(`/affiliate-list/create-coupon` + "?email=" + email);
  };

  const payoutTabs = ["Trading Account", "Affiliate"];

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const plans = ["All"];
  const dropdownRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !searchRef.current.contains(event.target)
    ) {
      setSearchDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const affiliateListRedux = useSelector(
    (state) => state.affiliateSlice.AffiliateUserList.data
  );

  useEffect(() => {
    affiliateListRedux?.length > 0 ? setLoading(false) : setLoading(true);
  }, [affiliateListRedux]);

  useEffect(() => {
    dispatch(fetchAffiliateRequest(idToken));

    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const handleChangePageNumber = (number) => {
    setPageSize(number);
    setIsOpen(false);
  };

  const handleSearch = async (e) => {
    const searchValue = email || e.target.value;

    await axios
      .get(
        `${baseUrl}authentication/admin/search-user/?search=${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        setSearchDropDown(true);
        setSearchUser(response.data);
      })
      .catch((error) => {});
  };

  const handleNavigateToOverView = (id) => {
    navigate(`/affiliate-list/${id}`);
  };
  return (
    <div style={{ position: "relative" }}>
      {referredList && (
        <div
          className={`referred_popup ${
            isPopup ? "popupvisible" : "popupinvisible"
          }`}
        >
          <div className="popup_card">
            <div>
              <div className="popup_action">
                <h2>Referred List</h2>
                <button
                  onClick={() => {
                    setIsPopup(!isPopup);
                  }}
                >
                  X
                </button>
              </div>
              <ul>
                {referredList?.map((user, idx) => {
                  return (
                    <li>
                      <div>{idx + 1}</div>
                      <div>{user.email}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="tabination"
        >
          {plans &&
            plans.map((item, index) => {
              return <Tab label={item} key={item} />;
            })}
        </Tabs> */}
      {plans &&
        plans?.map((item, idx) => {
          if (value === idx)
            return (
              <>
                <TabPanel
                  value={item}
                  index={item}
                  component="div"
                  className="tab-panel"
                >
                  <div
                    id="settings-section"
                    className="mainBGcolor"
                    // sx={{flexGrow: 1, display: "flex", m: 3}}
                  >
                    {loading ? (
                      ""
                    ) : (
                      <Index
                        loading={loading}
                        setLoading={setLoading}
                        userId={affiliateListRedux[0].id}
                      />
                    )}
                    <TabPanel value={value} index={0}>
                      <div className="leaderboard_table_overview">
                        {/* {isLoading ? (
                            <div className="circular_loader_wrapper">
                              {" "}
                              {<CircularProgress color="inherit" />}{" "}
                            </div>
                          ) : (
                            ""
                          )} */}

                        <div className=" table_wrapper">
                          <div className="table_header">
                            <div className="tab-panel-content">
                              <input
                                class="nosubmit"
                                id="search-input"
                                ref={searchRef}
                                type="search"
                                placeholder="Search..."
                                style={{
                                  margin: "10x 0px",
                                  width: "25%",
                                  height: "40px",
                                  backgroundColor: "#111142",
                                  borderRadius: "63px",
                                  visibility: "hidden",
                                }}
                                name="search"
                                value={search}
                                onChange={(e) => handleSearch(e)}
                              />
                              {searchDropDown && (
                                <div
                                  className="challenge_dropdown"
                                  ref={dropdownRef}
                                  style={{ top: "50px !important" }}
                                >
                                  <ul>
                                    {searchedUser.map((user) => (
                                      <li
                                        key={user.email}
                                        style={{
                                          position: "relative",
                                          zIndex: "100",
                                          backgroundColor: "#111142",
                                        }}
                                        // onClick={() =>
                                        //   handleNavigateToOverView(user.id)
                                        // }
                                      >
                                        {user.email}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                              <div className="wrapper">
                                <div
                                  className="affiliate-header"
                                  style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    position: "relative",

                                    gap: "15px",
                                  }}
                                  onClick={
                                    handleNavigateToCreateAffiliateCoupan
                                  }
                                >
                                  <img
                                    src={addCircle}
                                    style={{ height: "20px" }}
                                    alt="add circle"
                                  />{" "}
                                  <div className="createAffiliateCode1">
                                    Create Affiliate
                                  </div>
                                </div>
                                {/* <div
                                className="calender"
                                onClick={toggleDropdown}
                              >
                                <img src={vector8} alt="Vector 8" />
                                <h4>Total size: {pageSize}</h4>

                                {isOpen && (
                                  <div
                                    style={{
                                      position: 'relative',
                                      top: '48px',
                                      backgroundColor: '#111142',
                                      borderRadius: '15px',
                                      cursor: 'pointer',
                                    }}
                                    className="challenge_dropdown_for_page"
                                    ref={dropdownRef}
                                  >
                                    <ul
                                      style={{ margin: '0px', padding: '20px' }}
                                    >
                                      {[10, 20, 30, 40].map((number) => (
                                        <li
                                          key={number}
                                          onClick={() =>
                                            handleChangePageNumber(number)
                                          }
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            fontSize: '14px',
                                            color: '#e7e8ea',
                                          }}
                                        >
                                          {number}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}

                                <img
                                  src={vectorIcon}
                                  alt="Vector Icon"
                                  onClick={toggleDropdown}
                                />
                              </div> */}
                              </div>
                            </div>
                            {/* <p>Competition Leaderboard</p> */}
                            {/* <div className="component70">
  <img className="hicon1" alt="" src={Hicon} />
  <div className="search">Search....</div>
  </div> */}

                            {/* <div className="select_wrapper">
            <img
              className="select_icon"
              src={CalenderIcon}
              alt="calender icon"
            />
            <Select
              options={options}
              styles={customStyles}
              value={options[0]}
              components={{IndicatorSeparator: null}}
            />
          </div> */}
                          </div>
                          {loading ? (
                            ""
                          ) : (
                            <div id="settings-section" className="mainBGcolor">
                              <Table
                                popupRef={popupRef}
                                // tableData={affiliateList}
                                referredList={referredList}
                                setReferredList={setReferredList}
                                isPopup={isPopup}
                                setIsPopup={setIsPopup}
                                email={email}
                                pageSizePerPage={pageSize}
                                handleRowClick={handleRowClick}
                                isLoading={isLoading}
                                handlePagination={handlePagination}
                                totalTraders={totalTraders}
                                currentPage={currentPage - 1}
                                isLoadingComponent={isLoadingComponent}
                              />
                            </div>
                          )}
                          {/* <div
                            className="pagination"
                             
                            >
                              <div
                              className="pagination-direction"
                               
                              >
                                <img
                                  src={caretNext}
                                  alt="Caret Next"
                                
                                />
                                <div>Prev</div>
                              </div>
                              {[...Array(totalPages)].map((_, index) => (
                                <div key={index}>{index + 1}</div>
                              ))}
                              <div
                                className="pagination-direction"
                              >
                                <div>Next</div>
                                <img
                                  src={caretPrev}
                                  alt="Caret Next"
                                
                                />
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </TabPanel>
                    {/* 
                      <TabPanel value={value} index={1}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent={"space-between"}
                        >
                          <Grid item lg={2.7} className="nopadding"></Grid>
                          <Grid item lg={9} className="nopadding">
                            <div
                              className="border-box"
                              style={{ marginBottom: "32px" }}
                            >
                              <h1>coming Soon</h1>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel> */}
                    {/* 
                      <TabPanel value={value} index={2} className="nopadding">
                        <h1>Coming Soon</h1>
                      </TabPanel> */}
                  </div>
                </TabPanel>
              </>
            );
          return false;
        })}
    </div>
  );
};

export default AffiliateList;

const Table = ({ email, isLoadingComponent, totalTraders, currentPage }) => {
  const truncateString = (inputString, maxLength) => {
    const formattedString = inputString.toString();
    if (formattedString.length > maxLength) {
      return inputString.toString().substring(0, maxLength) + "...";
    }
    return inputString;
  };
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Text copied to clipboard !", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "info",
          theme: "dark",
          className: "custom-toast-container",
        });
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const dataAffiliate = useSelector(
    (state) => state.affiliateSlice.AffiliateUserList.data
  );
  const [affiliateList, setAffiliateList] = useState(dataAffiliate);
  useEffect(() => {
    setAffiliateList(dataAffiliate);
  }, [dataAffiliate]);

  //for modal
  const [isLoadingComponents, setIsLoadingComponents] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState();

  const handleCloseModal = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
    setIsLoadingComponents(true);
  };

  const handleOpenModal = async (ele) => {
    setOpen(true);
    setValue(ele);
    setIsLoadingComponents(true);
  };
  //for modal

  useEffect(() => {
    if (email) {
      // Filter the list based on the email
      const filteredAffiliateList = affiliateList.filter(
        (user) => user.email === email
      );
      setAffiliateList(filteredAffiliateList);
    }
  }, [email]);

  useEffect(() => {
    // dispatch(fetchAffiliateRequest(idToken))
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();
  // const data = React.useMemo(() => affiliateList, [affiliateList]);
  const data = affiliateList;
  const handleCheckStatus = (id) => {
    navigate(`/affiliate-list/${id}`);
  };

  const columns = React.useMemo(
    () =>
      affiliateList.length > 0
        ? [
            {
              Header: "Code",
              accessor: "code",
              Cell: ({ row }) => (
                <div
                  onClick={() => handleCheckStatus(row.original.id)}
                  className="column_one_wrapper_test"
                >
                  <div className="side_lines">
                    <div className="side-line_icon"></div>
                    <div>{row.original.code}</div>
                  </div>
                </div>
              ),
            },

            {
              Header: <>Discount %</>,
              accessor: "percentage",
              Cell: ({ row }) => (
                <div
                  onClick={() => handleCheckStatus(row.original.id)}
                  style={{ width: "120px" }}
                >
                  {row.original.percentage}%
                </div>
              ),

              //   sortType: "login_id",
            },
            { Header: "Push leads", accessor: "pushed_leads" },
            {
              Header: "Url",
              accessor: "url",
              Cell: ({ row }) => {
                return (
                  <div className="url" style={{ display: "flex" }}>
                    <b className="url-content">
                      {truncateString(row.original.url, 20)}
                    </b>
                    <p
                      style={{
                        marginRight: "20px",
                        backgroundColor: "#F26122",
                        padding: "5px 7px 3px 5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopyToClipboard(row.original?.url)}
                    >
                      {/* <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      /> */}
                      {/* Same as */}
                      <ToastContainer
                        progressStyle={{ background: "#F26122" }}
                      />
                      <ContentCopyIcon sx={{ fontSize: "20px" }} />
                    </p>
                  </div>
                );
              },
              className: "url_address",
            },
            { Header: "Percentage", accessor: "percentage_repeat" },
            { Header: "Used by", accessor: "consumed_by_count" },
            {
              Header: "Referred List",
              Cell: ({ row }) => (
                <div style={{ width: "120px" }}>
                  <button
                    className="view_details_btn"
                    onClick={() => handleOpenModal(row.original.id)}
                  >
                    View Detail
                  </button>
                </div>
              ),
            },
          ]
        : [],
    [isChallengeDropdown]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, pageSize: 10 },
    },
    usePagination
  );

  const totalPages = Math.ceil(data.length / pageSize);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table {...getTableProps()} className="leaderboard-table">
          {isLoadingComponent ? (
            <div className="circular_component_loader_wrapper">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            ""
          )}
          <thead>
            <Popup
              open={open}
              id={value}
              onClose={handleCloseModal}
              isLoadingComponents={isLoadingComponents}
              setisLoadingComponents={setIsLoadingComponents}
            />
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="table_header"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  key={row.original.id}
                  {...row.getRowProps()}
                  className="row-slide"
                  // onClick={() => handleCheckStatus(row.original.id)}
                >
                  {row.cells.map((cell, index) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      <div className="pagination">
        <button
          style={{ cursor: "pointer" }}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        {pageNumbers.map((pageNumber, index) => {
          const showEllipsis =
            (pageIndex > 3 && index === 1) ||
            (pageIndex < totalPages - 3 && index === totalPages - 2);
          const showPageNumber =
            index === 0 ||
            index === totalPages - 1 ||
            (index >= pageIndex - 1 && index <= pageIndex + 1);

          return (
            <React.Fragment key={index}>
              {showPageNumber && (
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoPage(pageNumber - 1)}
                  className={pageIndex === pageNumber - 1 ? "active" : ""}
                >
                  {pageNumber}
                </button>
              )}
              {showEllipsis && <span>...</span>}
            </React.Fragment>
          );
        })}
        <button
          style={{ cursor: "pointer" }}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => gotoPage(pageNumbers.length - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
      </div>
    </>
  );
};
